import { Injectable } from "@angular/core";
import { ConnectionFactoryService } from "../../core/connection-factory.service";
import { Observable, throwError } from "rxjs";
import { TutorRating } from "../types/tutor-rating";
import { StorageCache } from "../../core/storage-cache";
import { catchError } from "rxjs/operators";
import { assign } from "lodash-es";


@Injectable()
export class TutorRatingModelService {
    private tutorRatingListCache = new StorageCache<TutorRating[]>("TutorRatingListModelService");

    constructor(private connection: ConnectionFactoryService) {
    }

    getTutorRatings(params?: any): Observable<TutorRating[]> {
        return this.connection
            .service("tutor")
            .setPath("/ratings")
            .get(params);
    }

    getRawTutorRatings(additionalOptions: object = {},
                       accountId: number = 0,
                       refresh: boolean = false,
                       expiration: number = ConnectionFactoryService.CACHE_LIFETIME.tutor): Observable<TutorRating[]> {
        let cacheKey = assign({}, {accountId: accountId}, additionalOptions);
        return this.tutorRatingListCache.getCache(cacheKey, () => {
            return this.getTutorRatings(additionalOptions)
                .pipe(
                    catchError((e) => {
                        return throwError(e);
                    })
                );

        }, expiration, refresh);
    }


    deleteAllTutorRatingsListCache(): void {
        this.tutorRatingListCache.destroy();
    }

    getTutorRatingV2(params: object = {}): Observable<any[]> {
        return this.connection
            .service("tutor")
            //.setPath("/rating/v2") //uncomment me when v2 is ready
            .setPath("/tutor/rating")
            .get({...params, isServer: true});
    }

    getTutorRatingV2Csv(params: object = {}): Observable<string> {
        return this.connection
            .service("tutor")
            .setPath("/rating/v2/csv") //fixme repoint to CSV endpoint
            .get({...params, isServer: true}, undefined, undefined, {responseType: "text"});
    }
}
