import { RouterModule, Routes } from "@angular/router";
import { NgModule } from "@angular/core";
import { SiteIdentityModelService } from "../../model/site/site-identity-model.service";
import { ROUTES_PARTNERS } from "./routes.partners";
import { ROUTES_CORE } from "./routes.core";
import { CustomPreloadingStrategyService } from "../../shared/custom-preloading-strategy.service";
import { ROUTES_OLD } from "./routes.old";

declare var PRODUCTION: boolean;

const appRoutes: Routes = [
    // CORE => PWA Routes
    ...ROUTES_CORE,
    // PARTNERS => Partner Routes
    ...ROUTES_PARTNERS,
    ...ROUTES_OLD,
    {
        path: "**",
        loadChildren: () => import("../views/not-found-view/not-found-view.module").then(m => m.NotFoundViewModule),
        pathMatch: "full"
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(appRoutes, {
            initialNavigation : "disabled",
            scrollPositionRestoration: "disabled",
            preloadingStrategy: CustomPreloadingStrategyService,
            enableTracing: !PRODUCTION
        })
    ],
    providers: [
        SiteIdentityModelService,
        CustomPreloadingStrategyService
    ],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule {
}


