import { from, Observable, of } from "rxjs";
import { MemoryCache } from "./memory-cache";
import { LocalForageGeneric } from "./local-forage-generic";
import { CacheAbstract } from "./cache-abstract";
import { map, mergeMap } from "rxjs/operators";

export class ETaggedData<T> {
    data: T;
    eTag?: string;
    expires?: number;
    revalidate?: boolean;
}

export class ETagCache<T> extends CacheAbstract<T> {
    private observableCache: MemoryCache<Observable<T | undefined>>;
    private cache: LocalForageGeneric<ETaggedData<T>>;
    private useMemoryCache: boolean = false;

    constructor(nameSpace: string) {
        super();
        this.cache = new LocalForageGeneric<ETaggedData<T>>(nameSpace);
        this.useMemoryCache = !this.cache.isSupported();
    }

    getCache(params: object,
             generateFn?: () => Observable<T>,
             expirationMs?: number,
             refresh: boolean = false): Observable<ETaggedData<T> | undefined> {
        if (refresh) {
            this.deleteCache(params);
        }

        if (this.useMemoryCache) {
            return of(undefined);
        }

        let hash = this.generateHash(params);
        return from(this.cache.getItem(hash)).pipe(
            mergeMap((cache: ETaggedData<T> | undefined) => {
                const isExpired = this.isExpired(cache);
                if (cache && !isExpired) {
                    return of(cache);
                }

                if (!generateFn) {
                    return of({...cache, revalidate: true});
                }

                return generateFn()
                    .pipe(
                        map((value) => {
                            return this.setValue(params, value, expirationMs);
                        })
                    );
            })
        );
    }

    isExpired(cache: ETaggedData<T>): boolean {
        if (!cache?.expires) {
            return false;
        }
        return cache.expires < new Date().getTime();
    }

    setValue(params: object, value: T, expirationMs?: number, eTag?: string): ETaggedData<T> | undefined {
        if (this.useMemoryCache) {
            return undefined;
        }
        let hash = this.generateHash(params);
        const storageData = {
            data: value,
            expires: this.generateExpiration(expirationMs),
            eTag: eTag
        };
        this.cache.setItem(hash, storageData);

        return storageData;
    }

    deleteCache(params: object): void {
        if (this.useMemoryCache) {
            return;
        }
        let hash = this.generateHash(params);
        this.observableCache.deleteCache(params);
        this.cache.removeItem(hash);
    }

    destroy(): void {
        this.cache.clear();
    }
}
