import { ROUTE_PARTNER_CATHO } from "../../../routes/routes.partners";
import { PartnerConfig } from "./partner-config";

export const CATHO_CONFIG: PartnerConfig = {
    route: ROUTE_PARTNER_CATHO,
    styleClassName: "partner-catho",
    accountRegistrationTagId: 62,
    accessCodeLinkOnSkip: "browse",
    accessCodeLinkOnSuccess: "browse",
    accessCodeRedirectionDisabled: false
};
