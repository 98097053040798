import { ROUTE_PARTNER_BOMJESUS } from "../../../routes/routes.partners";
import { PartnerConfig } from "./partner-config";

export const BOMJESUS_CONFIG: PartnerConfig = {
    route: ROUTE_PARTNER_BOMJESUS,
    styleClassName: "partner-bomjesus",
    disableHeaderRightNavs: true,
    disableBrowseVideosLink: true,
    disableBrowseCoursesLink: true,
    disableAnonCourseAccess: true,
    authenticationImageLink: "https://cdns.englishcentral.com/images/ec3.4/partner/bomjesus/bg.jpg",
    authenticationLogoImageLink: "https://cdns.englishcentral.com/images/ec3.4/partner/bomjesus/logo.png",
    accessCodeImageLink: "https://cdns.englishcentral.com/images/ec3.4/partner/bomjesus/bg.jpg",
    posterLink: "https://cdna.englishcentral.com/posters/englishcentral-poster.png",
    videoLink: "https://cdna.englishcentral.com/posters/englishcentral-pwa.mp4",
    courseIds: [1504, 1505, 1506, 1507, 1508, 1556, 1557],
    courseLinks: {
        business: {
            beginner: 4532,
            intermediate: 4533,
            advanced: 4534
        },
        education: {
            intermediate: 4536
        },
        law: {
            advanced: 4539
        },
        environmentalSciences: {
            advanced: 4541
        },
        foodsAndDrinks: {
            advanced: 4543
        },
        healthSciences: {
            intermediate: 4597
        },
        socialSciences: {
            intermediate: 4549
        },
        innovationAndCreativity: {
            intermediate: 4553,
            advanced: 4552
        }
    }
};

