declare var window: any;

export class GlobalObservableCache {

    static getCache<T>(nameSpace: string,
                       generateFn?: () => T): T {
        if (!window.observableCache) {
            window.observableCache = {};
        }

        if (!window.observableCache[nameSpace]) {
            window.observableCache[nameSpace] = generateFn ? generateFn() : undefined;
        }

        return window.observableCache[nameSpace];
    }

    static removeCache<T>(nameSpace: string): void {
        if (!window.observableCache) {
            return;
        }

        if (!window.observableCache[nameSpace]) {
            return;
        }

        window.observableCache[nameSpace] = undefined;
    }
}
