import { AnalyticsGoogle } from "./google.analytics";
import { sanitizeCustomDimensionsList, sanitizeTrackerParams } from "../../helpers";
import { Tracker, TrackerCustomDimensions, TrackerName } from "../tracker";
import { EventAction } from "../../events/actions";
import { TrackerLogger } from "../tracker.logger";
import { EventLabel } from "../../events/labels";
import { isEmpty, isUndefined, keys, map } from "lodash-es";

declare const window: any;

export class GoogleTracker extends TrackerLogger implements Tracker {
    constructor(private clientId: string,
                private isProductionMode: boolean = false,
                private customDimensions?: TrackerCustomDimensions,
                private namespace?: string) {
        super(TrackerName.GA, isProductionMode);
    }

    init(isProductionMode: boolean = false): void {
        if (!isProductionMode) {
            this.loggingEnabled = true;
        }
        this.setTrackingScript();
        this.setCustomDimensions();
        this.setTransportToBeacon();
    }

    private getCommand(command: AnalyticsGoogle.Command) {
        if (isEmpty(this.namespace)) {
            return command;
        }
        return `${this.namespace}.${command}`;
    }

    private getTracker(): (...args) => void {
        return (<any>window).gtag ?? function() {};
    }

    private getCustomDimensionsList(): {[key: string]: TrackerCustomDimensions[keyof TrackerCustomDimensions]} {
        return sanitizeCustomDimensionsList({
            dimension1: this.customDimensions?.accountId,
            dimension2: this.customDimensions?.userType,
            dimension3: this.customDimensions?.subscriberType,
            dimension4: this.customDimensions?.partnerId,
            dimension5: this.customDimensions?.arrivalId,
            dimension9: this.customDimensions?.planName
        });
    }

    /* eslint-disable */
    private setTrackingScript() {
        "use strict";
        if (typeof (<any>window).gtag == "function") {
            return;
        }
        this.insertScript(window, document, "script", `https://www.googletagmanager.com/gtag/js?id=${this.clientId}`);
        (<any>window).dataLayer = window.dataLayer || [];
        window.gtag = function () { window.dataLayer.push(arguments); };
        window.gtag("js", new Date());
        window.gtag("config", this.clientId);
    }

    private insertScript(i, s, o, g): void {
        var a, m;
        a = s.createElement(o);
        m = s.getElementsByTagName(o)[0];
        a.async = 1;
        a.src = g;
        m.parentNode.insertBefore(a, m);
    }
    /* eslint-enable */

    private setCustomDimensions(): void {
        if (isEmpty(this.customDimensions)) {
            return;
        }
        this.getTracker()(this.getCommand(AnalyticsGoogle.Command.SET), this.getCustomDimensionsList());
    }

    private setTransportToBeacon(): void {
        // Doc: https://developers.google.com/analytics/devguides/collection/analyticsjs/sending-hits;
        this.getTracker()(
            this.getCommand(AnalyticsGoogle.Command.CONFIG),
            this.clientId,
            {transport_type: AnalyticsGoogle.TransportMechanism.BEACON}
        );
    }

    updateCustomDimensions(dimensionsUpdate: TrackerCustomDimensions = {}): TrackerCustomDimensions {
        this.customDimensions = { ...this.customDimensions, ...dimensionsUpdate };
        this.setCustomDimensions();
        return this.customDimensions;
    }

    trackPageView(page?: string): void {
        const params = sanitizeTrackerParams({send_to: page});
        this.getTracker()(
            this.getCommand(AnalyticsGoogle.Command.EVENT),
            AnalyticsGoogle.CommandMethod.PAGE_VIEW,
            params
        );
        if (this.loggingEnabled) {
            this.log(`${AnalyticsGoogle.Command.SEND}, ${AnalyticsGoogle.CommandMethod.PAGE_VIEW}`);
        }
    }

    trackEvent(trackParams: AnalyticsGoogle.TrackParams): void {
        const params = sanitizeTrackerParams<AnalyticsGoogle.TrackParams>({...trackParams});
        if (isEmpty(trackParams.eventAction)) {
            params.eventAction = EventAction.DEFAULT;
        }
        const labelsConcatenated = [];
        if (!isEmpty(trackParams.eventLabel)) {
            const label = !trackParams.eventValue
                ? trackParams.eventLabel
                : `${trackParams.eventLabel}: ${trackParams.eventValue}`;
            labelsConcatenated.push(label);
        }
        map(keys(trackParams.eventValuesOther), (key) => {
            const val = trackParams.eventValuesOther[key];
            if (isUndefined(key) || isUndefined(val)) {
                return;
            }
            labelsConcatenated.push(`${key}: ${val}`);
        });
        const preparedParams = {
            event_category: params.eventCategory,
            event_label: isEmpty(labelsConcatenated) ? EventLabel.DEFAULT : labelsConcatenated.join(" | "),
            value: 1
        };
        const cmd = this.getCommand(AnalyticsGoogle.Command.EVENT);
        this.getTracker()(cmd, params.eventAction, preparedParams);
        if (this.loggingEnabled) {
            this.log(`${cmd}, ${JSON.stringify(preparedParams)}`);
        }
    }

    trackTiming({timingCategory, timingVar, timingValue, timingLabel}: AnalyticsGoogle.TimingCommandMethodFields): void {
        const cmd = this.getCommand(AnalyticsGoogle.Command.EVENT);
        const preparedParams = {
            name: timingVar,
            value: timingValue,
            event_category: timingCategory,
            event_label: timingLabel || AnalyticsGoogle.TimingLabel.DEFAULT
        };
        this.getTracker()(cmd, AnalyticsGoogle.CommandMethod.TIMING_COMPLETE, preparedParams);
        if (this.loggingEnabled) {
            this.log(`${cmd}, ${JSON.stringify(preparedParams)}`);
        }
    }
}
