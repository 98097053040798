export class AccountLessonEligibility {
    static SUBSCRIPTION_BASIC = "BASIC";
    static SUBSCRIPTION_ACADEMIC = "ACADEMICPREMIUM";
    static SUBSCRIPTION_PREMIUM = "PREMIUM";
    static SUBSCRIPTION_ONEPERDAY = "1/DAY";
    static SUBSCRIPTION_TWOPERDAY = "2/DAY";
    static SUBSCRIPTION_PLATINUM = "PLATINUM";
    static SUBSCRIPTION_COURSEONLY = "COURSEONLY";

    accountID: number;
    subscription: string;
    totalLessonsAvailable: number;
    goLiveAvailable: number;
    lessonEligibility: {
        [sessionType: string]: LessonEligibility
    };
}

export class LessonEligibility {
    // @TODO: Fix this. Reasons should be stored as an enum.
    static REASON_INSUFFICIENT_EXP: string = "NOTENOUGHACTIVITYPOINTS";
    static REASON_MAXLESSONSREACHED: string = "MAXLESSONSREACHED";
    static REASON_NO_COMPLETED_DIALOGS: string = "NOUNSCHEDULEDANDCOMPLETEDDIALOGS";
    static REASON_ACCOUNT_IS_BASIC: string = "ACCOUNTISBASIC";
    static REASON_ONLYONELT: string = "ONLYONELT";
    static REASON_ONLYONEGLATATIME: string = "ONLYONEGLATATIME";
    static REASON_NODAILYLESSONSAVAILABLE: string = "NODAILYLESSONSAVAILABLE";
    static REASON_LOCKEDOUT: string = "LOCKEDOUT";
    static STATUS_SCHEDULED: string = "Scheduled";
    static STATUS_COMPLETED: string = "Completed";


    isEligible: boolean;
    status: string;
    eligibilityReasons: string;
    credits: number;
    dailyLimitLeft: number = 0;
    requiredExperiencePoints: number;
    thresholdExperiencePoints: number;
    xtraCredits: number = 0;
    useXtraCredit: boolean = false;
    givenLimitLeft: number = 0;
    renewalLimitLeft?: number = 0;
    nativeLimitLeft?: number;
    dateLimitRefresh?: number;
    weeklyLimitRefresh?: number;
    lessonsThisWeek?: number;
    lessonsAvailable?: number;
    eligibleGroup?: boolean;
}
