import { Injectable } from "@angular/core";
import { interval, Observable, of, timer } from "rxjs";
import { Logger } from "../../core/logger/logger";
import { ReportCardEvent } from "./event-factory.service";
import { EventModelService } from "../../model/reportcard/event-model.service";
import { EventTypes } from "./event-factory/event-types";
import { Instrumentation } from "../../core/instrumentation/instrumentation";
import { Emitter } from "../../core/emitters/emitter";
import { StorageCache } from "../../core/storage-cache";
import { catchError, finalize, map as rxJsMap, mergeMap, retryWhen, share, switchMap } from "rxjs/operators";
import { HttpErrorResponse } from "@angular/common/http";
import {
    parseServiceResponse,
    reportCardEventsRetryStrategy,
    UNRECOVERABLE_STATUS_CODES
} from "../../core/retry-helper";
import { extractErrorString } from "../../core/instrumentation/instrumentation-utility";
import {
    compact,
    concat,
    difference,
    filter,
    get,
    includes,
    isArray,
    isEmpty,
    isUndefined,
    map,
    size,
    slice,
    some,
    uniqBy
} from "lodash-es";

export class ReportCardEventUpdate {
    success: boolean;
    data: ReportCardEvent;
}

const LOCK_RETRY_TIME = 1000;
const QUEUE_INTERVAL_TIME = 1000;
const GARBAGE_COLLECTION_INTERVAL_TIME = 5000;
const FAILED_EVENTS_CACHE_KEY = {key: "failed"};
const EVENT_BATCH_SIZE = 10;

declare var ec__ProgressLock: boolean;
declare var ec__StorageWriteLock: boolean;
declare var ec__SentEvents: string[];

/* eslint-disable */
if (isUndefined((<any>window).ec__ProgressLock)) {
    (<any>window).ec__ProgressLock = false;
}
if (isUndefined((<any>window).ec__StorageWriteLock)) {
    (<any>window).ec__StorageWriteLock = false;
}
if (isUndefined((<any>window).ec__SentEvents)) {
    (<any>window).ec__SentEvents = [];
}

/* eslint-enable */


@Injectable({providedIn: "root"})
export class ProgressQueueService {
    static readonly EVENT_ADDED = "event_added";
    static readonly EVENT_SENT = "events";
    static readonly EVENT_ERROR = "eventsError";
    static readonly EVENT_COMPLETION_SENT = "completion";
    static readonly EVENT_SUCCESS_SENT = "events_success";
    static readonly WORD_EVENT_SENT = "word";
    static readonly EVENT_START_SENT = "start";

    private failedEventsCache = new StorageCache<ReportCardEvent[]>("failedEvents");
    private emitter = new Emitter();
    private logger = new Logger();

    constructor(private eventModelService: EventModelService) {
        this.processFailedEventsOnce();
        // this.initializeBackgroundProcesses();
    }

    private initializeBackgroundProcesses(): void {
        this.processFailedEvents();
        this.runSentEventsGarbageCollector();
    }

    private runSentEventsGarbageCollector(): void {
        interval(GARBAGE_COLLECTION_INTERVAL_TIME)
            .pipe(
                share()
            )
            .subscribe(() => {
                if (ec__ProgressLock) {
                    return;
                }
                ec__SentEvents = [];
            });
    }

    private processFailedEvents(): void {
        interval(QUEUE_INTERVAL_TIME)
            .pipe(
                share(),
                switchMap(() => ec__StorageWriteLock ? of([]) : this.failedEventsCache.getCache(FAILED_EVENTS_CACHE_KEY)),
                rxJsMap((cachedEvents) => slice(cachedEvents || [], 0, EVENT_BATCH_SIZE))
            )
            .subscribe((failedEvents) => {
                if (ec__ProgressLock || isEmpty(failedEvents)) {
                    return;
                }
                ec__ProgressLock = true;

                this.sendEvent$(failedEvents)
                    .pipe(
                        finalize(() => ec__ProgressLock = false),
                        retryWhen(reportCardEventsRetryStrategy())
                    )
                    .subscribe(() => {
                        this.publishEventProcessing(failedEvents, true);
                        this.clearSuccessEvents(failedEvents);
                        this.logger.log(size(failedEvents) + " failed events resent");
                    }, (errorResponse) => {
                        this.storeFailedEvents(failedEvents, errorResponse);
                    });
            });
    }

    private processFailedEventsOnce(): void {
        this.failedEventsCache.getCache(FAILED_EVENTS_CACHE_KEY)
            .pipe(
                rxJsMap((cachedEvents) => slice(cachedEvents || [], 0, EVENT_BATCH_SIZE))
            )
            .subscribe((failedEvents) => {
                if (ec__ProgressLock || isEmpty(failedEvents)) {
                    return;
                }
                ec__ProgressLock = true;

                this.sendEvent$(failedEvents)
                    .pipe(
                        finalize(() => ec__ProgressLock = false),
                        retryWhen(reportCardEventsRetryStrategy())
                    )
                    .subscribe(() => {
                        this.publishEventProcessing(failedEvents, true);
                        this.clearSuccessEvents(failedEvents);
                        this.logger.log(size(failedEvents) + " failed events resent");
                    }, (errorResponse) => {
                        this.storeFailedEvents(failedEvents, errorResponse);
                    });
            });
    }

    private sendEvent$(rawReportCardEvents: ReportCardEvent[]): Observable<string> {
        if (isEmpty(rawReportCardEvents)) {
            return of(undefined);
        }

        let reportCardEvents = isArray(rawReportCardEvents) ? compact(rawReportCardEvents) : [rawReportCardEvents];
        return this.eventModelService
            .postEvent(reportCardEvents);
    }

    sendEvent(rawReportCardEvents: ReportCardEvent | ReportCardEvent[]): void {
        if (isEmpty(rawReportCardEvents)) {
            return;
        }
        let eventList: ReportCardEvent[] = isArray(rawReportCardEvents) ? compact(rawReportCardEvents) : [rawReportCardEvents];
        let uniqueEvents = uniqBy(eventList, event => this.generateEventHash(event));
        let reportCardEvents = filter(uniqueEvents, reportCardEvent => {
            return !includes(ec__SentEvents, this.generateEventHash(reportCardEvent));
        });

        const diff = difference(eventList, reportCardEvents);
        if (!isEmpty(diff)) {
            this.logger.error("%cDuplicate events found:", "color: #707", diff);
        }

        ec__SentEvents = concat(ec__SentEvents, map(reportCardEvents, (reportCardEvent) => this.generateEventHash(reportCardEvent)));

        this.publish(ProgressQueueService.EVENT_ADDED, true);
        this.sendEvent$(reportCardEvents)
            .subscribe(() => {
                this.publish(ProgressQueueService.EVENT_SUCCESS_SENT, reportCardEvents);
                this.publishEventProcessing(reportCardEvents, true);
                this.clearSuccessEvents(reportCardEvents);
            }, (errorResponse) => {
                this.storeFailedEvents(reportCardEvents, errorResponse);
                this.publishEventError(errorResponse);
            });
    }

    private publishEventError(errorResponse: any): void {
        if (!errorResponse || !errorResponse.status) {
            return;
        }

        const filteredEventError = {
            status: errorResponse.status,
            error: parseServiceResponse(errorResponse)
        };
        this.publish(ProgressQueueService.EVENT_ERROR, filteredEventError);
    }
    
    private generateEventHash(event: ReportCardEvent): string {
        if (!event) {
            return;
        }
        if (EventTypes.isCompletionEvent(event.type)) {
            return `${event.accountID}:${event.activityID || 0}:${event.activitySessionID || 0}:${event.type}:${event.dialogId || 0}:${event.dialogLineID || 0}`;
        }
        return `${event.accountID}:${event.activityID || 0}:${event.eventTime || 0}:${event.type}:${event.dialogId || 0}:${event.dialogLineID || 0}`;
    }

    private clearSuccessEvents(validEvents: ReportCardEvent[]): void {
        // mutex lock detected, retry after 1s
        if (ec__StorageWriteLock) {
            timer(LOCK_RETRY_TIME).subscribe(() => this.clearSuccessEvents(validEvents));
        }

        ec__StorageWriteLock = true;

        this.failedEventsCache
            .getCache(FAILED_EVENTS_CACHE_KEY)
            .pipe(
                catchError((error) => {
                    Instrumentation.sendEvent("progress-queue-error", {
                        message: "Storage error",
                        errorMessage: extractErrorString(error)
                    });

                    return of(undefined);
                }),
                mergeMap((cachedEvents?: ReportCardEvent[]) => {
                    const uniqueEvents = uniqBy(cachedEvents || [], event => this.generateEventHash(event));
                    const filteredEventsStack = this.getFilteredEvents(uniqueEvents, validEvents);

                    return of(this.failedEventsCache
                        .setValue(
                            FAILED_EVENTS_CACHE_KEY,
                            filteredEventsStack
                        ));
                })
            )
            .subscribe(() => {
                ec__StorageWriteLock = false;
            });
    }

    private storeFailedEvents(reportCardEvents: ReportCardEvent[],
                              errorResponse: HttpErrorResponse): void {
        if (includes(UNRECOVERABLE_STATUS_CODES, errorResponse.status)) {
            return;
        }

        // mutex lock detected, retry after 1s
        if (ec__StorageWriteLock) {
            timer(LOCK_RETRY_TIME).subscribe(() => this.storeFailedEvents(reportCardEvents, errorResponse));
        }

        ec__StorageWriteLock = true;

        this.logger.error("postEvent", errorResponse.message, errorResponse.status);

        let [validEvents, failedEvents, invalidEvents] = this.extractFailedEvents(reportCardEvents, errorResponse);

        this.publishEventProcessing(validEvents, true);
        this.failedEventsCache
            .getCache(FAILED_EVENTS_CACHE_KEY)
            .pipe(
                catchError((error) => {
                    Instrumentation.sendEvent("progress-queue-error", {
                        message: "Storage error",
                        errorMessage: extractErrorString(error)
                    });

                    return of(undefined);
                }),
                mergeMap((cachedEvents) => {
                    const uniqueEvents = uniqBy(concat(cachedEvents || [], failedEvents), event => this.generateEventHash(event));
                    const eventsStack = this.getFilteredEvents(uniqueEvents, validEvents);
                    const filteredEventsStack = this.getFilteredEvents(eventsStack, invalidEvents);

                    return this.failedEventsCache
                        .setValue(
                            FAILED_EVENTS_CACHE_KEY,
                            filteredEventsStack
                        );
                })
            )
            .subscribe(() => {
                ec__StorageWriteLock = false;
            });
    }

    private getFilteredEvents(validEvents: ReportCardEvent[], eventsForFiltering: ReportCardEvent[]): ReportCardEvent[] {
        return filter(validEvents, reportCardEvent => !some(eventsForFiltering, validEvent => {
            // remove valid events from queue
            return this.generateEventHash(validEvent) == this.generateEventHash(reportCardEvent);
        }));
    }

    private extractFailedEvents(reportCardEvents: ReportCardEvent[],
                                errorResponse: HttpErrorResponse): [ReportCardEvent[], ReportCardEvent[], ReportCardEvent[]] {
        if (!errorResponse || !errorResponse.status) {
            return [[], reportCardEvents, []];
        }

        let responseObject = parseServiceResponse(errorResponse.error);
        let responseKey = responseObject?.key ?? responseObject?.exceptionKey;

        if (isEmpty(responseKey)) {
            if (includes(UNRECOVERABLE_STATUS_CODES, errorResponse.status)) {
                return [[], [], reportCardEvents];
            }
            return [[], reportCardEvents, []];
        }

        const EVENT_BAD = "EVENT_BAD";
        if (responseKey == EVENT_BAD) {
            return [[], [], reportCardEvents];
        }

        return [[], reportCardEvents, []];
    }

    subscribe(eventName: string, callback: (data?) => void, error?: (data?) => void): void {
        this.emitter.subscribe(eventName, callback, error);
    }

    getObservable(eventName: string): Observable<any> {
        return this.emitter.getObservable(eventName);
    }

    private publishEventProcessing(reportCardEvents: ReportCardEvent[],
                                   success: boolean): void {

        map(reportCardEvents, reportCardEvent => {
            let eventUpdate = {
                success: success,
                data: reportCardEvent
            };

            this.publish(ProgressQueueService.EVENT_SENT, eventUpdate);

            if (!success) {
                return;
            }

            if (EventTypes.isStartEvent(reportCardEvent.type)) {
                return this.publish(ProgressQueueService.EVENT_START_SENT, reportCardEvent);
            }
            if (EventTypes.isCompletionEvent(reportCardEvent.type)) {
                return this.publish(ProgressQueueService.EVENT_COMPLETION_SENT, reportCardEvent);
            }
        });
    }

    private publish(eventName: string, data?: any): void {
        this.emitter.publish(eventName, data);
    }
}
