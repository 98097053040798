import { Injectable } from "@angular/core";
import { ConnectionFactoryService } from "../../core/connection-factory.service";
import { Observable, of } from "rxjs";
import {
    AccountAffiliation,
    AccountAffiliationClasses,
    AccountAffiliationGroups,
    AffiliationAccountContent,
    AffiliationClass,
    AffiliationOrganizationClass
} from "../types/account-affiliation";
import { StorageCache } from "../../core/storage-cache";
import { AccountInvitation } from "../types/account-invitation";
import { catchError, map as rxJsMap } from "rxjs/operators";
import { get, some } from "lodash-es";

export enum AffiliationContentType {
    AFFILIATION_CONTENT_TYPE_COURSE = "course",
    AFFILIATION_CONTENT_TYPE_DIALOG = "dialog",
    AFFILIATION_CONTENT_TYPE_ACTIVITY = "activity"
}

@Injectable({
    providedIn: "root"
})
export class AffiliationModelService {
    static readonly MODE_CLASS = "class";
    static readonly MODE_GROUP = "group";

    private affiliationCache = new StorageCache<AccountAffiliation>("Affiliations");
    private affiliationOrganizationClassesCache = new StorageCache<AffiliationOrganizationClass[]>("AffiliationOrganizationClasses");

    constructor(private connection: ConnectionFactoryService) {
    }

    clearAffiliationCache(): void {
        this.affiliationCache.destroy();
    }

    getCustomAffix(type, mode?: string): string {
        if (!mode || !mode.length || !type.length) {
            return "";
        }
        return type === "organization" ? ("/" + mode) : "";
    }

    getRawAccountAffiliation(): Observable<AccountAffiliation> {
        return this.connection
            .service("bridge")
            .setPath("/identity/membership/account")
            .get()
            .pipe(catchError(() => of([])));
    }

    getAccountAffiliation(accountId: number,
                          expiration: number = ConnectionFactoryService.CACHE_LIFETIME.classdata): Observable<AccountAffiliation> {
        return this.affiliationCache
            .getCache({accountId: accountId}, () => {
                return this.getRawAccountAffiliation();
            }, expiration);
    }

    isAccountComprehensionQuizEnabled(accountId: number): Observable<boolean> {
        if (!accountId) {
            return of(false);
        }

        return this.getAccountAffiliation(accountId)
            .pipe(
                rxJsMap(accountAffiliation => {
                    let classes = get(accountAffiliation, "classes", []);

                    return some(classes, affiliationClass => get(affiliationClass, "comprehensionQuizEnabled", false));
                })
            );
    }

    getAffiliationByType(id: number, params, type: string = "class", mode?: string): Observable<AffiliationClass[]> {
        return this.connection
            .service("bridge")
            .setPath(`/identity/membership/${type}/${id}${this.getCustomAffix(type, mode)}`)
            .get(params);
    }

    getAffiliationInvitationByType(id: number, type: string = "class"): Observable<AccountInvitation[]> {
        return this.connection
            .service("bridge")
            .setPath(`/identity/invitation/${type}/${id}`)
            .get();
    }

    getAffiliationCount(id: number, params, type: string, mode: string = "student"): Observable<number> {
        return this.connection
            .service("bridge")
            .setPath(`/identity/membership/${type}/${id}${this.getCustomAffix(type, mode)}/count`)
            .get(params);
    }

    getAffiliationClassGroups(classId: number, params): Observable<AccountAffiliationGroups[]> {
        return this.connection
            .service("bridge")
            .setPath(`/identity/membership/class/${classId}/groups`)
            .get(params);
    }

    getAffiliationOrganizationClasses(organizationId: number): Observable<AffiliationOrganizationClass[]> {
        return this.connection
            .service("bridge")
            .setPath(`/identity/membership/organization/${organizationId}/classes`)
            .get();
    }

    getAffiliationOrganizationClassesListCache(organizationId: number, expiration: number = ConnectionFactoryService.CACHE_LIFETIME.classdata): Observable<AffiliationOrganizationClass[]> {
        return this.affiliationOrganizationClassesCache
            .getCache({organizationId: organizationId}, () => {
                return this.getAffiliationOrganizationClasses(organizationId);
            }, expiration);
    }

    getAffiliationOrganizationStudent(organizationId: number, params): Observable<AccountAffiliationClasses[]> {
        return this.connection
            .service("bridge")
            .setPath(`/identity/membership/organization/${organizationId}/classes/student`)
            .get(params);
    }

    getAffiliationContent(accountId: number,
                          contentTypeId: number,
                          contentId: number): Observable<AffiliationAccountContent> {
        return this.connection
            .service("bridge")
            .setPath(`/identity/membership/teacher/${accountId}/type/${contentTypeId}/content/${contentId}`)
            .get();
    }

    getAffiliationContents(
        accountId: number,
        contentTypeId: number,
        contentIds: number[] | string
    ): Observable<AffiliationAccountContent[]> {
        return this.connection
            .service("bridge")
            .setPath(`/identity/membership/teacher/${accountId}/type/${contentTypeId}/content`)
            .get({contentIds});
    }

    addAffiliationContent(affiliationId: number,
                          contentType: AffiliationContentType,
                          contentId?: number,
                          mode: string = AffiliationModelService.MODE_CLASS): Observable<void> {
        return this.connection
            .service("bridge")
            .setPath(`/identity/${mode}/${affiliationId}/${contentType}/${contentId}`)
            .post();
    }

    removeAffiliationContent(affiliationId: number,
                             contentType: AffiliationContentType,
                             contentId?: number,
                             mode: string = AffiliationModelService.MODE_CLASS): Observable<void> {
        return this.connection
            .service("bridge")
            .setPath(`/identity/${mode}/${affiliationId}/${contentType}/${contentId}`)
            .delete();
    }

    deleteAffiliationOrganizationClassesCache(): void {
        this.affiliationOrganizationClassesCache.destroy();
    }

    deleteAffiliationCache(): void {
        this.affiliationCache.destroy();
    }

    getAffiliationByAccountId(accountId: number): Observable<AccountAffiliation> {
        return this.connection
            .service("bridge")
            .setPath(`/identity/school/account`)
            .get({ accountID: accountId });
    }
}
