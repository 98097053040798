export class SessionStatus {
    static STATUS_COMPLETED: string = "Completed";
    static STATUS_SCHEDULED: string = "Scheduled";
    static STATUS_MISSED: string = "Missed";
    static STATUS_REPORT_PENDING: string = "ReportPending";
    static STATUS_REPORT_NEEDS_REVIEW: string = "ReportNeedsReview";
    static STATUS_CANCELLED: string = "Cancelled";
    //@NOTE arbitrary session status between scheduled and reportpending
    static STATUS_ONGOING: string = "Ongoing";

    static isCompleted(session: { status: string }): boolean {
        return session?.status == SessionStatus.STATUS_COMPLETED;
    }

    static isScheduled(session: { status: string }): boolean {
        return session?.status == SessionStatus.STATUS_SCHEDULED;
    }

    static isMissed(session: { status: string }): boolean {
        return session?.status == SessionStatus.STATUS_MISSED;
    }

    static isCancelled(session: { status: string }): boolean {
        return session?.status == SessionStatus.STATUS_CANCELLED;
    }

    static isReportPending(session: { status: string }): boolean {
        return session?.status == SessionStatus.STATUS_REPORT_PENDING;
    }

    static isReportNeedsReview(session: { status: string }): boolean {
        return session?.status == SessionStatus.STATUS_REPORT_NEEDS_REVIEW;
    }
}
