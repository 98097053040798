// @deprecated
// Please keep isCurrentRoute and getQueryParams. When page loads query params and urlTree are always empty.
// Behind the scenes "this.route" subscriptions work with a BehaviourSubject. First value is always empty.
// See: https://github.com/angular/angular/issues/12157
// this issue only happens because the routing is most probably misconfigured and we're listening too late to the route changes
import {
    get,
    includes,
    isBoolean,
    isEmpty,
    isNumber,
    isRegExp,
    isString,
    reduce,
    replace,
    split,
    toNumber
} from "lodash-es";
import { Browser } from "./browser";
import { ElementRef, QueryList } from "@angular/core";
import { Logger } from "./logger/logger";

const logger = new Logger();

export const isCurrentRoute = (routeName: string): boolean => {
    if (!window) {
        return false;
    }
    const pathName = get(window, "location.pathname");
    return pathName ? pathName.indexOf(routeName) !== -1 : false;
};

/*
 * @deprecated
 * DO NOT USE: use the angular router instead
 * https://alligator.io/angular/query-parameters/
*/
export const getQueryParams = (): Record<string, string> => {
    let query = replace(get(window, "location.search"), "?", "");
    if (isEmpty(query)) {
        return {};
    }

    return reduce(query.split("&"), (acc, pair) => {
        const splittedPair = split(pair, "=");
        if (splittedPair.length === 1) {
            return acc;
        }
        const paramKey = decodeURIComponent(splittedPair[0]);
        const paramValue = decodeURIComponent(splittedPair[1]);
        return {...acc, [paramKey]: paramValue};
    }, {});
};

// * DO NOT USE: use the angular router instead. Written only for handling initial queryParams before router.initialNavigation()
export const parseQueryParams = (queryParams: string = ""): { [key: string]: any } => {
    return reduce(queryParams.replace("?", "").split("&"), (acc, param: string) => {
        const [key, value] = split(param, "=");
        return {...acc, [key]: value};
    }, {});
};

export const generateBooleanFromUnknown = (val: unknown): boolean => {
    return (val === "false" || val == "0") ? false : !!val;
};

export const generateNumberFromUnknown = (val: unknown): number => {
    if (isNumber(val)) {
        return val;
    }
    if (isBoolean(val)) {
        return val ? 1 : 0;
    }
    if (isString(val)) {
        if (val === "false" || val === "undefined" || val === "null") {
            return 0;
        }
        const int = toNumber(val);
        return isNaN(int)
            ? (isEmpty(val) ? 0 : 1)
            : int;
    }
    return isEmpty(val) ? 0 : 1;
};

export const isActiveRoute = (routeUrlSegment: string | RegExp, urlSegmentsMergedPaths: string): boolean => {
    if (isString(routeUrlSegment)) {
        return includes(urlSegmentsMergedPaths, routeUrlSegment);
    }
    if (isRegExp(routeUrlSegment)) {
        return (<RegExp>routeUrlSegment).test(urlSegmentsMergedPaths);
    }
    return false;
};

export const generateSmsAnchor = (smsBody: string, smsPhoneNumber: number | string): string => {
    const isIos = Browser.isIos();
    const divider = isIos ? "&" : "?";
    return `sms:${smsPhoneNumber}${divider}body=${smsBody}`;
};
export const slideToElement = (el?: Element, additionalOptions: Partial<ScrollToOptions> = {}): void => {
    const customOptions = {
        behavior: "smooth",
        block: "start",
        inline: "nearest",
        ...additionalOptions
    } as ScrollIntoViewOptions;

    if (el && el.scrollIntoView) {
        el.scrollIntoView(customOptions);
        return;
    }
    // If no argument is passed, page slides to top.
    if (window) {
        window?.scrollTo({top: 0, ...customOptions});
    }
};

export const scrollIntoHorizontalView = (
    wrapper: ElementRef,
    items: QueryList<ElementRef>,
    dataIdentifier: string,
    id: any,
    offset: number = 0
) => {
    const itemsWrapper = wrapper?.nativeElement;
    if (!items || !itemsWrapper || !dataIdentifier || !id) {
        return;
    }
    items.some((el) => {
        const nativeEl = el?.nativeElement;
        if (!nativeEl) {
            return true;
        }
        const idFromDataAttribute = nativeEl.getAttribute(`data-${dataIdentifier}`);
        if (idFromDataAttribute == id) {
            const elBoundingRect = nativeEl.getBoundingClientRect();
            itemsWrapper.scrollTo({
                left: nativeEl.offsetLeft - (window.innerWidth / 2) + (elBoundingRect.width / 2) + offset,
                behavior: "smooth"
            });
        }
        return idFromDataAttribute == id;
    });
};

export const isOverFlowing = (itemList: HTMLDivElement, contentWrap: HTMLDivElement): boolean => {
    if (itemList.getClientRects().length === 0 || contentWrap.getClientRects().length === 0) {
        return;
    }
    return itemList.getClientRects()[0].top < contentWrap.getClientRects()[0].top || itemList.getClientRects()[0].bottom > contentWrap.getClientRects()[0].bottom;
};

const fallbackCopyTextToClipboard = (text): Promise<any> => {
    const textArea = document.createElement("textarea");
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    const handleError = (error?) => {
        logger.log(error);
        document.body.removeChild(textArea);
        return Promise.reject(error);
    };
    const handleSuccess = () => {
        document.body.removeChild(textArea);
        return Promise.resolve();
    };
    try {
        const isSuccessful = document.execCommand("copy");
        return isSuccessful ? handleSuccess() : handleError();
    } catch (error) {
        return handleError(error);
    }
};

export const copyToClipboard = (text: string): Promise<any> => {
    if (!navigator.clipboard) {
        return fallbackCopyTextToClipboard(text);
    }
    return navigator.clipboard.writeText(text);
};

export const stripHtml = (html: string): string => {
    if (!html) {
        return "";
    }
    let tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
};

type ObjectFromEnum<T> = Record<keyof T, T[keyof T]>;
export const convertEnumToObject = <T>(targetEnum: T): ObjectFromEnum<T> => {
    return Object.keys(targetEnum).reduce((acc, key) => {
        return {...acc, [key]: targetEnum[key]};
    }, {}) as ObjectFromEnum<T>;
};
