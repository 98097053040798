import { AnalyticsFacebook } from "./facebook.analytics";
import { Tracker, TrackerName } from "../tracker";
import { TrackerLogger } from "../tracker.logger";

export class FacebookTracker extends TrackerLogger implements Tracker {
    constructor(private clientId: string, private isProductionMode: boolean = false) {
        super(TrackerName.FB, isProductionMode);
    }

    init(): void {
        this.setTrackingScript();
    }

    /* eslint-disable */
    private setTrackingScript() {
        "use strict";
        if (typeof (<any>window).fbq == "function") {
            return;
        }
        let fb = (f, b, e, v, n?, t?, s?) => {
            if (f.fbq) return;
            n = f.fbq = function () {
                n.callMethod ?
                    n.callMethod.apply(n, arguments) : n.queue.push(arguments);
            };
            if (!f._fbq) f._fbq = n;
            n.push = n;
            n.loaded = !0;
            n.version = "2.0";
            n.queue = [];
            t = b.createElement(e);
            t.async = !0;
            t.src = v;
            s = b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t, s);
            return false;
        };
        !fb(<any>window, <any>document, "script", "https://connect.facebook.net/en_US/fbevents.js");
        (<any>window).fbq(AnalyticsFacebook.Command.INIT, this.clientId);
    }

    getTracker(): (...args) => void {
        return (<any>window).fbq ?? function() {};
    }
    /* eslint-enable */

    trackPageView(page?: string): void {
        this.getTracker()(AnalyticsFacebook.Command.TRACK, AnalyticsFacebook.EventName.PAGE_VIEW);
        if (this.loggingEnabled) {
            this.log(`${AnalyticsFacebook.Command.TRACK}, ${AnalyticsFacebook.EventName.PAGE_VIEW}`);
        }
    }

    trackEvent({ eventName, eventParams, command }: AnalyticsFacebook.TrackParams): void {
        const cmd = command || AnalyticsFacebook.Command.TRACK;
        this.getTracker()(cmd, eventName, eventParams || {});
        if (this.loggingEnabled) {
            this.log(`${cmd}, ${eventName}${eventParams ? ", " + JSON.stringify(eventParams) : ""}`);
        }
    }
}
