let localforage = require("localforage");
import { MemoryCache } from "./memory-cache";
import { Browser } from "./browser";
import { reduce } from "lodash-es";

export class LocalForageGeneric<T> {
    static readonly DRIVERS = [
        localforage.INDEXEDDB,
        localforage.WEBSQL,
        localforage.LOCALSTORAGE
    ];
    private storage;
    private useMemory: boolean = false;
    private memoryStorage = new MemoryCache<T>();

    constructor(nameSpace: string) {
        try {
            this.storage = localforage?.createInstance({
                name: nameSpace
            });
            let drivers = this.getDriver();
            if (drivers.length) {
                this.storage.setDriver(this.getDriver()).catch(e => {
                    console.error(e);
                    this.useMemory = true;
                });
            } else {
                this.useMemory = true;
            }
        } catch (e) {
            console.error("localForage creation", e);
        }
    }

    private getDriver(): string[] {
        return reduce(LocalForageGeneric.DRIVERS, (acc: string[], driver) => {
            if (Browser.isSafari() && driver == localforage?.WEBSQL
            || Browser.isSafari() && driver == localforage?.INDEXEDDB) {
                return acc;
            }

            if (localforage?.supports(driver)) {
                acc.push(driver);
            }
            return acc;
        }, []);
    }

    isSupported(): boolean {
        return localforage?.supports(localforage?.INDEXEDDB)
            || localforage?.supports(localforage?.WEBSQL)
            || localforage?.supports(localforage?.LOCALSTORAGE);
    }

    setItem(key: string, value: T): Promise<T | undefined> {
        if (!this.storage || this.useMemory) {
            this.memoryStorage.setValue({key: key}, value);
            return Promise.resolve(value);
        }

        return this.storage
            .setItem(key, value)
            .catch(e => {
                console.error("localForage setItem", key, e);
                return Promise.reject(e);
            });
    }

    getItem(key: string): Promise<T | undefined> {
        if (!this.storage || this.useMemory) {
            return this.memoryStorage.getCachePromise({key: key});
        }

        return this.storage
            .getItem(key)
            .catch(e => {
                console.error("localForage getItem", key, e);
                return Promise.reject(e);
            });
    }

    removeItem(key: string): Promise<void> {
        if (!this.storage || this.useMemory) {
            this.memoryStorage.deleteCache({key: key});
            return Promise.resolve();
        }

        return this.storage
            .removeItem(key)
            .catch(e => {
                console.error("localForage removeItem", key, e);
                return Promise.reject(e);
            });
    }

    clear(): Promise<undefined> {
        if (!this.storage || this.useMemory) {
            this.memoryStorage.destroy();
            return Promise.resolve(undefined);
        }

        return this.storage
            .clear()
            .catch(e => {
                console.error("localForage clear", e);
                return undefined;
            });
    }

    length(): Promise<number | undefined> {
        if (!this.storage || this.useMemory) {
            return Promise.resolve(this.memoryStorage.getLength());
        }

        return this.storage
            .length()
            .catch(e => {
                console.error("localForage length", e);
                return Promise.reject(undefined);
            });
    }

}
