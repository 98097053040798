export const PACKAGE_NAME_ANDROID = "com.englishcentral.android.app";
export const ANDROIDTWA_APP_VERSION = "1000";
export const WEB_APP_VERSION = "1500";
export const MOBILEWEB_APP_VERSION = "2000";
export const EVENT_TYPE_RECEIVED = "14";
export const EVENT_TYPE_OPEN = "15";
export const EVENT_TYPE_IGNORED = "16";
export const EVENT_TYPE_FAILED = "19";
export const APPLICATIONSTATELAUNCHED_ACTIVE = "Active";
export const APPLICATIONSTATELAUNCHED_LAUNCHED = "Launched";
export const APPLICATIONSTATELAUNCHED_BACKGROUND = "Background";

export enum APP_VERSION {
    WEB = "1000",
    MOBILEWEB = "1500",
}

export enum APP_ID {
    ANDROIDTWA = "androidtwa",
    WEB = "web",
    MOBILEWEB = "mobileweb",
}

export enum CONTACT_TYPE {
    ANDROIDTWA = "androidtwa",
    WEB = "web",
    MOBILEWEB = "mobileweb",
    PHONE = "phone",
    MOBILE = "mobile",
    VOIP = "voip",
    SKYPE = "skype",
    FACEBOOK = "facebook",
    TMS = "tms"
}

export class DeleteContactinformation {
    isExpired?: boolean;
}

export class Contactinformation {
    accountID?: number;
    contactType?: CONTACT_TYPE;
    contactValue?: string;
    mobileAppVersion?: string;
    packageName?: string;
    checkSessionSkypeConflict?: boolean;
    skipPhoneValidation?: boolean;
    contactInformationID?: number;
}

export class PushNotificationPermission {
    accountId: number;
    contactType: CONTACT_TYPE;
    acceptedPermission: boolean;
}

export class AccountContactinformation {
    accountId?: number;
    active: boolean;
    contactInformationId: number;
    contactType: string;
    contactValue: string;
    dateCreated: number;
    dateModified?: number;
    packageName?: string;
}

export class ActiveContactInformationParams {
    accountId?: number;
    contactType: string;
    active?: boolean;
}

export class PayloadMessage {
    alert?: string;
    meta?: string;
    tracking?: string;
}
