export class DailyGoal {
    dailyGoalId: number;
    pointsPerDay: number;
    approximateMinutes: number;
    name: string;
    recommended: boolean;
}

export interface AccountDailyGoal {
    accountId: number;
    dailyGoalId: number;
    pointsPerDay: number;
}

export interface DailyGoalStreak {
    accountId: number;
    dailyGoal: number;
    streakCount: number;
    points: number[];
}

export enum DailyGoalSetting {
    CASUAL = 1,
    INTERESTED = 2,
    SERIOUS = 3,
    COMMITTED = 4,
    EXTREME = 5,
}
