<div class="ec-pwa-v2-landing-app"
     [class.header-disabled]="!shouldRenderHeader()"
     [class.loading]="isSettingsLoading()"
     [class.anonymous]="isAnonymous()"
     [ngClass]="getPartnerStyleClassName()">
    <!-- Header -->
    <!-- IMPORTANT: (keep header visibility with ngIf, do NOT change it to [class.d-none]=isSettingsLoading()) -->
    <!-- Note: Header should render itself after globalSettings and identity is initialized -->
    <div class="header">
        <ng-container #headerNavigationAppRef></ng-container>
    </div>
    <!-- Main routes -->
    <main [class.mb-5]="isHomeRoute()" [class.home]="isHomeRoute()" role="main" #mainEl>
        <ng-template [ngIf]="!isSettingsLoading()">
            <!-- Routes -->
            <router-outlet #router="outlet"></router-outlet>
        </ng-template>
    </main>
    <!-- Footer -->
    <div class="footer-wrapper">
        <ng-container #footerRef></ng-container>
    </div>
    <div class="splash-loader d-flex align-items-center justify-content-center" *ngIf="isSettingsLoading()">
        <ec-loading-ring [themeClass]="'primary'" [size]="'large'"></ec-loading-ring>
    </div>
</div>

<ng-container #cookieConsentRef></ng-container>
<ng-container #pushNotificationRef></ng-container>
<ng-container #globalNotificationRef></ng-container>
<ng-container #twilioSiteChatRef></ng-container>
<!-- @INFO: Debugger is used for debugging purposes -->
<ng-container #twilioChatDebuggerRef></ng-container>
