export type Theme = "light" | "dark" | "kids" | "northsun" | "spicus";

export const THEME_LIGHT = "light";
export const THEME_DARK = "dark";
export const THEME_KIDS = "kids";
export const THEME_NORTHSUN = "northsun";
export const THEME_SPICUS = "spicus";

const THEME_CSS_MAP = {
    [THEME_LIGHT]: "default/default",
    [THEME_DARK]: "dark/dark",
    [THEME_KIDS]: "kids/kids",
    [THEME_NORTHSUN]: "northsun/northsun",
    [THEME_SPICUS]: "spicus/spicus"
};

export const getAvailableThemes = (): Theme[] => {
    return Object.keys(THEME_CSS_MAP) as Theme[];
};

export const useTheme = async (theme: Theme): Promise<void> => {
    const themeUrl = THEME_CSS_MAP[theme];
    require.ensure([], () => {
        const themeCss = require(`global-styles/themes/site/${themeUrl}.theme.lazy.scss`)?.default;
        themeCss?.use();
    });
};

export const unuseTheme = async (theme: Theme): Promise<void> => {
    const themeUrl = THEME_CSS_MAP[theme];
    require.ensure([], () => {
        const themeCss = require(`global-styles/themes/site/${themeUrl}.theme.lazy.scss`)?.default;
        themeCss?.unuse();
    });
};
