import { ROUTE_PARTNER_NORTHSUN } from "../../../routes/routes.partners";

import { PartnerConfig } from "./partner-config";

export const NORTHSUN_CONFIG: PartnerConfig = {
    route: ROUTE_PARTNER_NORTHSUN,
    styleClassName: "partner-northsun",
    disableBrowseVideosLink: true,
    disableBrowseCoursesLink: true,
    disableAnonCourseAccess: true
};
