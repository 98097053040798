import { ROUTE_PARTNER_VIDEOENGLISH } from "../../../routes/routes.partners";
import { PartnerConfig } from "./partner-config";

export const VIDEOENGLISH_CONFIG: PartnerConfig = {
    route: ROUTE_PARTNER_VIDEOENGLISH,
    styleClassName: "partner-videoenglish",
    accessCodeImageLink: "https://cdna.englishcentral.com/promo/demo/howitworks/partner-bg2.jpeg",
    authenticationImageLink: "https://cdna.englishcentral.com/promo/demo/howitworks/partner-bg2.jpeg",
    courseIds: [1504, 1505, 1506, 1507, 1508, 1556, 1557],
    courseLinks: {
        business: {
            beginner: 4532,
            intermediate: 4533,
            advanced: 4534
        },
        education: {
            intermediate: 4536
        },
        law: {
            advanced: 4539
        },
        environmentalSciences: {
            advanced: 4541
        },
        foodsAndDrinks: {
            advanced: 4543
        },
        healthSciences: {
            intermediate: 4597
        },
        socialSciences: {
            intermediate: 4549
        },
        innovationAndCreativity: {
            intermediate: 4553,
            advanced: 4552
        }
    }
};

