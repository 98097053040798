import { TrackerLogger } from "../tracker.logger";
import { TrackConversionParams, TrackerName } from "../tracker";
import { clone, isEmpty, isFunction, noop } from "lodash-es";
import { CURRENCY_MAP } from "../../../currencies";
import { GEOLOCATION } from "../../../geolocation";
import { GoogleAds } from "./google.ads";

declare const window: any;

export class GoogleAdsTracker extends TrackerLogger {
    constructor(private clientId: string,
                private sendToIds: object,
                private isProductionMode: boolean = false) {
        super(TrackerName.GA, isProductionMode);
    }

    init(isProductionMode: boolean = false): void {
        if (!isProductionMode) {
            this.loggingEnabled = true;
        }
        this.setTrackingScript();
    }

    /* eslint-disable */
    private setTrackingScript() {
        "use strict";
        if (typeof (<any>window).gtag == "function") {
            return;
        }
        this.insertScript(window, document, "script", `https://www.googletagmanager.com/gtag/js?id=${this.clientId}`);
        (<any>window).dataLayer = window.dataLayer || [];
        window.gtag = function () { window.dataLayer.push(arguments); };
        window.gtag("js", new Date());
        window.gtag("config", this.clientId);
    }

    private insertScript(i, s, o, g): void {
        var a, m;
        a = s.createElement(o);
        m = s.getElementsByTagName(o)[0];
        a.async = 1;
        a.src = g;
        m.parentNode.insertBefore(a, m);
    }
    /* eslint-enable */

    trackEvent(conversionType: GoogleAds.ConversionType,conversionParams: GoogleAds.EventConversionParams = {}): boolean {
        if (!window.gtag) {
            return false;
        }
        const conversionParamsClone = clone(conversionParams);
        conversionParamsClone.send_to = this.sendToIds[conversionType] || "";
        if (conversionType === GoogleAds.ConversionType.PURCHASE) {
            if (!conversionParamsClone.transaction_id) {
                conversionParamsClone.transaction_id = "";
            }
        }
        if (!conversionParams.event_callback || !isFunction(conversionParams.event_callback)) {
            conversionParamsClone.event_callback = noop;
        }
        if (isEmpty(conversionParamsClone.currency)) {
            conversionParamsClone.currency = CURRENCY_MAP[GEOLOCATION.US].currency;
        }
        if (!conversionParamsClone.value) {
            conversionParamsClone.value = 1;
        }
        if (isEmpty(conversionParamsClone.send_to)) {
            return false;
        } else {
            window.gtag("event", "conversion", conversionParamsClone);
            this.log(`event, conversion -> ${JSON.stringify(conversionParamsClone)}`);
        }
        return true;
    }

    trackConversion(conversionParams: TrackConversionParams): void {
        const params = {
            send_to: conversionParams?.send_to,
            value: conversionParams?.value,
            currency: conversionParams?.currency,
            event_callback: conversionParams?.event_callback,
            transaction_id: conversionParams?.transaction_id
        };
        this.trackEvent(conversionParams?.conversionType, params);
    }
}
