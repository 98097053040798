import { Location, LocationStrategy, PathLocationStrategy } from "@angular/common";
import { NgModule } from "@angular/core";
import { LandingAppComponent } from "./landing-app.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { AppRoutingModule } from "./routes/app-routing.module";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { CoreModule } from "../core/core.module";
import { FreelessonPromoAppModalModule } from "./modules/freelesson-promo-app/freelesson-promo-app-modal.module";
import { DailyGoalModelService } from "../model/identity/daily-goal-model.service";
import { DailyGoalProgressService } from "../activity-app/shared-activity/daily-goal-progress.service";
import { VocabBuilderModelService } from "../model/content/vocab-builder-model.service";
import { LoadingRingComponent } from "../shared/loading-ring/loading-ring.component";

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        CoreModule,
        NgbModule,
        AppRoutingModule,
        LoadingRingComponent,
        // @INFO: This is a wrapper module that lazy loads freelesson promo.
        FreelessonPromoAppModalModule
    ],
    declarations: [
        LandingAppComponent
    ],
    providers: [
        Location,
        { provide: LocationStrategy, useClass: PathLocationStrategy },
        DailyGoalProgressService,
        DailyGoalModelService,
        VocabBuilderModelService
    ],
    bootstrap: [
        LandingAppComponent
    ]
})
export class LandingAppModule {
}
