// The browser platform with a compiler
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import getTranslationProviders from "./i18n-provider";
import { LandingAppModule } from "./pwa-v2-landing-app/landing-app.module";
import { ecProductionMode } from "./core/core-utility";

import "global-styles/themes/site/base.scss";

ecProductionMode();

getTranslationProviders().then(providers => {
    // Compile and launch the module
    if (document.getElementsByTagName("ec-pwa-v2-landing-app").length) {
        platformBrowserDynamic().bootstrapModule(LandingAppModule, {providers: providers});
    }
});
