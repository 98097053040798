/*
 * Old routes are written with "pathMatch: full" in order to handle old routes which are directed to pwa-v2 from php side.
 * With these routes, application will know which lazyRoute to load when it receives an old url
 * */
import { Routes } from "@angular/router";
import { AuthTypes } from "../views/authentication-view/authentication-types";
import {
    ROUTE_ABOUT_US,
    ROUTE_AUTHENTICATION,
    ROUTE_BROWSE,
    ROUTE_CART,
    ROUTE_COMPANY_INFORMATION_NEWS,
    ROUTE_FORGOT_PASSWORD,
    ROUTE_PAYMENT,
    ROUTE_PRIVACY_POLICY,
    ROUTE_PROFILE_SETTINGS_GENERAL,
    ROUTE_STUDENTS,
    ROUTE_TEACHER_TOOLS,
    ROUTE_TERMS_OF_USE
} from "./routes";


export const ROUTES_OLD: Routes = [
    {
        path: "login",
        redirectTo: `${ROUTE_AUTHENTICATION}/${AuthTypes.LOGIN}`,
        pathMatch: "full"
    },
    {
        path: "register",
        redirectTo: `${ROUTE_AUTHENTICATION}/${AuthTypes.REGISTER}`,
        pathMatch: "full"
    },
    {
        path: "videos",
        redirectTo: `${ROUTE_BROWSE}/videos`,
        pathMatch: "full"
    },
    {
        path: "courses",
        redirectTo: `${ROUTE_BROWSE}/courses`,
        pathMatch: "full"
    },
    {
        path: "courses/preview/:courseId",
        redirectTo: "course/:courseId",
        pathMatch: "full"
    },
    {
        path: "student",
        redirectTo: ROUTE_STUDENTS,
        pathMatch: "full"
    },
    {
        path: "studentlogin",
        redirectTo: ROUTE_STUDENTS,
        pathMatch: "full"
    },
    {
        path: "studentregister",
        redirectTo: ROUTE_STUDENTS,
        pathMatch: "full"
    },
    {
        path: "studentslogin",
        redirectTo: ROUTE_STUDENTS,
        pathMatch: "full"
    },
    {
        path: "studentsregister",
        redirectTo: ROUTE_STUDENTS,
        pathMatch: "full"
    },
    {
        path: "teach",
        redirectTo: ROUTE_TEACHER_TOOLS,
        pathMatch: "full"
    },
    {
        path: ROUTE_CART,
        redirectTo: ROUTE_PAYMENT
    },
    {
        path: "login/forgotpassword",
        redirectTo: ROUTE_FORGOT_PASSWORD,
        pathMatch: "full"
    },
    {
        path: "static/privacy",
        redirectTo: ROUTE_PRIVACY_POLICY,
        pathMatch: "full"
    },
    {
        path: "privacy",
        redirectTo: ROUTE_PRIVACY_POLICY,
        pathMatch: "full"
    },
    {
        path: "terms",
        redirectTo: ROUTE_TERMS_OF_USE,
        pathMatch: "full"
    },
    {
        path: "mobile/pricing",
        redirectTo: ROUTE_PAYMENT
    },
    {
        path: "news",
        redirectTo: ROUTE_COMPANY_INFORMATION_NEWS
    },
    {
        path: "static/corporate/*",
        redirectTo: ROUTE_ABOUT_US,
        pathMatch: "full"
    },
    {
        path: "mobile/en",
        redirectTo: ROUTE_BROWSE
    },
    {
        path: "mobile/ja",
        redirectTo: ROUTE_BROWSE
    },
    {
        path: "landing/discount",
        redirectTo: ROUTE_BROWSE
    },
    {
        path: "account",
        redirectTo: ROUTE_PROFILE_SETTINGS_GENERAL
    },
    {
        path: "teach/store",
        redirectTo: "teacher-tools/purchase"
    }
];
