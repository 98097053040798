import { TrackerLogger } from "../tracker.logger";
import { TrackConversionParams, Tracker, TrackerName } from "../tracker";

export class AdlocateTracker extends TrackerLogger implements Tracker {

    private trackUrl: string;

    constructor(private config: any,
                private isProductionMode: boolean = false) {
        super(TrackerName.ADLOCATE, isProductionMode);
    }

    trackConversion(params: TrackConversionParams): void {
        let _CIDN = "cid";
        let _PMTV = params?.programId;
        let _ARGSV = params?.orderNumber;
        let _TKP1V = params?.accountId;
        let _TKP2V = "";
        let _TKP3V = params?.itemCode;
        let _TKP4V = params?.totalOrderPrice;
        let _TKP5V = "";
        let _TKP6V = "";
        let _TKP7V = "";
        let _TKP8V = "";
        let _TKP9V = "";
        let _TKP10V = "";
        let _TRKU = "https://adlocate.jp/track.php?p=" + _PMTV + "&t=620f3c24&args=" + _ARGSV + "&tkp1=" + _TKP1V + "&tkp2=" + _TKP2V + "&tkp3=" + _TKP3V + "&tkp4=" + _TKP4V + "&tkp5=" + _TKP5V + "&tkp6=" + _TKP6V + "&tkp7=" + _TKP7V + "&tkp8=" + _TKP8V + "&tkp9=" + _TKP9V + "&tkp10=" + _TKP10V;
        let _cks = document.cookie.split("; ");
        let _cidv = "";
        for (let i = 0; i < _cks.length; i++) {
            let _ckd = _cks[i].split("=");
            if (_ckd[0] == "CL_" + _PMTV && _ckd[1].length > 1) {
                _cidv = _ckd[1];
                break;
            }
        }
        if (!_cidv && localStorage.getItem("CL_" + _PMTV)) {
            _cidv = localStorage.getItem("CL_" + _PMTV);
        }
        if (_cidv) {
            _TRKU += "&" + _CIDN + "=" + _cidv;
        }
        let img = document.body.appendChild(document.createElement("img"));
        img.src = _TRKU;
        this.trackUrl = _TRKU;
    }

    getTrackerUrl(): string {
        return this.trackUrl;
    }

    init(): void {
        let _CIDN = "cid";
        let _PMTN = "p";
        let _LPTU = "./";
        let _param = location.search.substring(1).split("&");
        let _ulp = "";
        let _ulcid = "";
        for (let i = 0; _param[i]; i++) {
            let kv = _param[i].split("=");
            console.log(kv[0], kv[1]);
            if (kv[0] == _PMTN && kv[1].length > 1) {
                _ulp = kv[1];
            }
            if (kv[0] == _CIDN && kv[1].length > 1) {
                _ulcid = kv[1];
            }
        }
        if (_ulp && _ulcid) {
            let _xhr = new XMLHttpRequest();
            _xhr.open("GET", _LPTU + "lptag.php?p=" + _ulp + "&cid=" + _ulcid);
            _xhr.send();
            localStorage.setItem("CL_" + _ulp, _ulcid);
        }
    }

    trackPageView(): void {
    }

    trackEvent(): void {
    }
}
