import { Injectable } from "@angular/core";
import { ConnectionFactoryService } from "../../core/connection-factory.service";
import { Observable, of } from "rxjs";
import { AccountLessonEligibility } from "../types/account-lesson-eligibility";
import { StorageCache } from "../../core/storage-cache";
import { ProgressQueueService } from "../../common-app/progress-app/progress-queue.service";
import { catchError } from "rxjs/operators";

@Injectable({
    providedIn: "root"
})
export class TutorAccountModelService {
    private cache = new StorageCache<AccountLessonEligibility>("TutorAccountModelService");

    constructor(private progressQueueService: ProgressQueueService,
                private connection: ConnectionFactoryService) {
        this.progressQueueService.subscribe(ProgressQueueService.EVENT_ADDED, () => {
            this.cache.destroy();
        });
    }

    getEligibility(options: object = {},
                   refresh: boolean = false,
                   expiration: number = ConnectionFactoryService.CACHE_LIFETIME.eligibility): Observable<AccountLessonEligibility> {
        return this.cache.getCache(options, () => {
            return this.getRawEligibility(options);
        }, expiration, refresh);
    }

    private getRawEligibility(additionalOptions?: object): Observable<AccountLessonEligibility> {
        return this.connection
            .service("tutor")
            .setPath("/status/account/eligibility")
            .get(additionalOptions, undefined, ConnectionFactoryService.SERVICE_VERSION.v2)
            .pipe(catchError(() => of({})));
    }

    deleteCache(): void {
        this.cache.destroy();
    }
}
