import { ROUTE_PARTNER_CLASSI } from "../../../routes/routes.partners";
import { PartnerConfig } from "./partner-config";

export const CLASSI_CONFIG: PartnerConfig = {
    route: ROUTE_PARTNER_CLASSI,
    styleClassName: "partner-classi",
    disableHeaderRightNavs: true,
    disableFooterToggling: true,
    disableBrowseVideosLink: true,
    disableBrowseCoursesLink: true,
    disableAnonCourseAccess: true
};

