import { from, Observable, of } from "rxjs";
import { MemoryCache } from "./memory-cache";
import { LocalForageGeneric } from "./local-forage-generic";
import { CacheAbstract } from "./cache-abstract";
import { map, mergeMap } from "rxjs/operators";

class StorageData<T> {
    data: T;
    expires?: number;
}

export class StorageCache<T> extends CacheAbstract<T> {
    private memoryCache = new MemoryCache<T>();
    private cache: LocalForageGeneric<StorageData<T>>;
    private useMemoryCache: boolean = false;

    constructor(nameSpace: string) {
        super();
        this.cache = new LocalForageGeneric<StorageData<T>>(nameSpace);
        this.useMemoryCache = !this.cache.isSupported();
    }

    getCache(params: object,
             generateFn?: () => Observable<T>,
             expirationMs?: number,
             refresh: boolean = false): Observable<T | undefined> {
        if (refresh) {
            this.deleteCache(params);
        }

        if (this.useMemoryCache) {
            return this.memoryCache.getCache(params, generateFn);
        }

        let hash = this.generateHash(params);
        return from(this.cache.getItem(hash)).pipe(
            mergeMap((cache: StorageData<T> | undefined) => {
                if (cache && !this.isExpired(cache)) {
                    return of(cache.data);
                }
                if (!generateFn) {
                    return of(undefined);
                }

                return generateFn()
                    .pipe(
                        map((value) => {
                            this.setValue(params, value, expirationMs);
                            return value;
                        })
                    );
            })
        );
    }

    isExpired(cache: StorageData<T>): boolean {
        if (!cache?.expires) {
            return false;
        }
        return cache.expires < new Date().getTime();
    }

    getCachePromise(params: object,
                    generateFn?: () => Observable<T>,
                    expirationMs?: number,
                    refresh: boolean = false): Promise<T | undefined> {
        return this.getCache(params, generateFn, expirationMs, refresh).toPromise();
    }

    setValue(params: object, value: T, expirationMs?: number): T | undefined {
        if (this.useMemoryCache) {
            return this.memoryCache.setValue(params, value);
        }
        let hash = this.generateHash(params);
        this.cache.setItem(hash, {data: value, expires: this.generateExpiration(expirationMs)});

        return value;
    }

    deleteCache(params: object): void {
        if (this.useMemoryCache) {
            return this.memoryCache.deleteCache(params);
        }
        let hash = this.generateHash(params);
        this.cache.removeItem(hash);
    }

    destroy(): void {
        if (this.useMemoryCache) {
            return this.memoryCache.destroy();
        }

        this.cache.clear();
    }
}
