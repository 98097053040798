import { TrackerLogger } from "../tracker.logger";
import { Tracker, TrackerName } from "../tracker";
import { AnalyticsTiktok } from "./tiktok.analytics";
import TrackParams = AnalyticsTiktok.TrackParams;

export class TiktokTracker extends TrackerLogger implements Tracker {

    constructor(private tiktokPixelId: string, private isProductionMode: boolean = false) {
        super(TrackerName.TIKTOK, isProductionMode);
    }

    init(): void {
        this.setTrackingScript(this.getTiktokPixelId());
    }

    getTiktokPixelId(): string {
        return this.tiktokPixelId;
    }

    /* eslint-disable */
    private setTrackingScript(tiktokPixelId: string) {
        "use strict";
        this.insertScript(window, document, "ttq", tiktokPixelId);
    }

    private insertScript(w, d, t, tiktokPixelId: string): void {
        // @ts-ignore
        w.TiktokAnalyticsObject = t;
        var ttq = w[t] = w[t] || [];
        ttq.methods = ["page", "track", "identify", "instances", "debug", "on", "off", "once", "ready", "alias", "group", "enableCookie", "disableCookie"], ttq.setAndDefer = function(t, e) {
            t[e] = function() {
                t.push([e].concat(Array.prototype.slice.call(arguments, 0)));
            };
        };
        for (var i = 0; i < ttq.methods.length; i++) {
            ttq.setAndDefer(ttq, ttq.methods[i]);
        }
        ttq.instance = function(t) {
            for (var e = ttq._i[t] || [], n = 0; n < ttq.methods.length; n++) {
                ttq.setAndDefer(e, ttq.methods[n]);
            }
            return e;
        }
        ttq.load = function(e, n) {
            var i = "https://analytics.tiktok.com/i18n/pixel/events.js";
            ttq._i = ttq._i || {}, ttq._i[e] = [], ttq._i[e]._u = i, ttq._t = ttq._t || {}, ttq._t[e] = +new Date, ttq._o = ttq._o || {}, ttq._o[e] = n || {};
            var o = document.createElement("script");
            o.type = "text/javascript", o.async = !0, o.src = i + "?sdkid=" + e + "&lib=" + t;
            var a = document.getElementsByTagName("script")[0];
            a.parentNode.insertBefore(o, a);
        };

        ttq.load(tiktokPixelId);
        ttq.page();

    }
    /* eslint-enable */

    getTracker() {
        return window["ttq"];
    }

    trackPageView(page: string | undefined) {
        this.getTracker().page();
        if (this.loggingEnabled) {
            this.log("Page view from TikTok");
        }
    }

    trackEvent({ eventType, eventParams }: TrackParams) {
        eventParams ? this.getTracker().track(eventType, eventParams) : this.getTracker().track(eventType);
        if (this.loggingEnabled) {
            this.log(`${eventType}${eventParams ? ", " + JSON.stringify(eventParams) : ""}`);
        }
    }
}
