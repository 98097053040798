import { PartnerConfig } from "./partner-config";
import { ROUTE_MY_ENGLISH, ROUTE_VI_LANDING_CAMPAIGN } from "../../../routes/routes";
import { ROUTE_PARTNER_TOPCV } from "../../../routes/routes.partners";

export const BAZA_VNPT_CONFIG: PartnerConfig = {
    route: ROUTE_VI_LANDING_CAMPAIGN,
    styleClassName: undefined,
    accessCodeLinkOnSuccess: ROUTE_MY_ENGLISH,
    accessCodeLinkOnSkip: ROUTE_MY_ENGLISH,
    levelSelectorEnabled: true
};
