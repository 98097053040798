import { PartnerConfig } from "./partner-config";
import { ROUTE_PARTNER_TOPCV } from "../../../routes/routes.partners";
import { ROUTE_MY_ENGLISH } from "../../../routes/routes";

export const TOPCV_CONFIG: PartnerConfig = {
    route: ROUTE_PARTNER_TOPCV,
    styleClassName: "partner-topcv",
    levelSelectorEnabled: true,
    accessCodeLinkOnSkip: ROUTE_MY_ENGLISH,
    accessCodeLinkOnSuccess: ROUTE_MY_ENGLISH
};
