import { Observable, of } from "rxjs";
import { CacheAbstract } from "./cache-abstract";
import { map, share } from "rxjs/operators";
import { has, isEmpty, isUndefined, reduce, size } from "lodash-es";
import { Dictionary } from "../model/types/dictionary";

export class MemoryCache<T> extends CacheAbstract<T> {
    private cache: Dictionary<T | undefined> = {};

    constructor() {
        super();
    }

    getCache(params: object, generateFn?: () => Observable<T>): Observable<T | undefined> {
        let cached = this.getCachedValue(params);
        if (!isUndefined(cached)) {
            return of(cached);
        }

        if (!generateFn) {
            return of(undefined);
        }

        return generateFn()
            .pipe(
                map((value) => {
                    this.setValue(params, value);
                    return value;
                }),
                share()
            );
    }

    getCachePromise(params: object, generateFn?: () => Observable<T>): Promise<T | undefined> {
        return this.getCache(params, generateFn).toPromise();
    }

    getCachedValue(params: object): T | undefined {
        let hash = this.generateHash(params);

        return this.cacheExists(params, hash)
            ? this.cache[hash]
            : undefined;
    }

    cacheExists(params: object, baseHash?: string): boolean {
        let hash = baseHash || this.generateHash(params);

        return has(this.cache, hash);
    }

    setValue(params: object, value?: T): T | undefined {
        let hash = this.generateHash(params);
        this.cache[hash] = value;

        return value;
    }

    deleteCache(params: object): void {
        let hash = this.generateHash(params);
        if (this.cacheExists(params, hash)) {
            delete this.cache[hash];
        }
    }

    toList(): T[] {
        if (isEmpty(this.cache)) {
            return [];
        }

        return reduce(this.cache, (acc: T[], cache: T) => {
            if (cache) {
                acc.push(cache);
            }
            return acc;
        }, []);
    }

    getLength(): number {
        return size(this.cache);
    }

    destroy(): void {
        this.cache = {};
    }
}
