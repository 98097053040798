export enum ConversationType {
    CONVERSATION_TYPE_LT3 = 1,
    CONVERSATION_TYPE_VIDEO_CHAT = 2,
    CONVERSATION_TYPE_ROLE_PLAY = 3,
    CONVERSATION_TYPE_OPEN_RESPONSE = 5
}

export const isConversationTypeOpenResponse = (conversationTypeId: number): boolean => {
    return conversationTypeId === ConversationType.CONVERSATION_TYPE_OPEN_RESPONSE;
};
