import { Injectable } from "@angular/core";
import { ConnectionFactoryService } from "../../core/connection-factory.service";
import { ReportCardEvent } from "../../common-app/progress-app/event-factory.service";
import { Observable } from "rxjs";

@Injectable({
    providedIn: "root"
})
export class EventModelService {
    constructor(private connection: ConnectionFactoryService) {
    }

    postEvent(events: ReportCardEvent[]) {
        return this.connection
            .service("reportcard", false, false)
            .setPath("/event")
            .post({}, events, "", {responseType: "text"});
    }

    getAttempt(activityId: number): Observable<number> {
        return this.connection
            .service("reportcard")
            .setPath(`/report/activity/${activityId}/attempt`)
            .get();
    }
}
