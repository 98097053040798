import { AssetType } from "../../model/types/content/asset";
import { VoicePersona } from "../../talking-mimi-app/voice-persona";

export const SPEAKMODE_REPEAT_TRANSCRIPTION = 1;
export const SPEAKMODE_REPEAT_NO_TRANSCRIPTION = 2;
export const SPEAKMODE_REPEAT_MISSING_WORD = 3;
export const SPEAKMODE_READ = 4;
export const SPEAKMODE_READ_MISSING_WORD = 5;

export const LOCAL_USER_SETTINGS_COMPREHENSIONQUIZENABLED = "isComprehensionQuizEnabled";

// add here settings derived from local storage + their default values
export class LocalUserSettings {
    speakExerciseMode: number = SPEAKMODE_REPEAT_TRANSCRIPTION;
    autoAdjustMicrophoneGain: boolean = true;
    learnDynamicMode: boolean = false;
    speakDynamicMode: boolean = false;
    playerVideoResolution: AssetType = AssetType.DIALOG_THUMB_SIZE_360P;
    multipleChoiceEnabled?: boolean;
    isComprehensionQuizEnabled: boolean = false;
    autoPauseWatchMode?: boolean;
    talkingHeadVolume?: number;
    voicePersona?: VoicePersona;
    applyCostume?: boolean;
    talkingHeadEnabled?: boolean;
    chatMode?: boolean;
    openResponseChatMode?: boolean;
    mimiTtsEnabled?: boolean;
}


// @README temporary singleton storage until services from lazy loader is fixed
export class ActivitySetting {
    private static localUserSettings: Record<number, LocalUserSettings> = {};

    static setLocalUserSettings(accountId: number, settings: Partial<LocalUserSettings>): void {
        const prevSettings = ActivitySetting.localUserSettings[accountId] ?? new LocalUserSettings();
        ActivitySetting.localUserSettings[accountId] = {...prevSettings, ...settings};
    }

    static setLocalUserSetting(accountId: number, key: keyof LocalUserSettings, value: any): LocalUserSettings {
        const prevSettings = ActivitySetting.localUserSettings[accountId] ?? new LocalUserSettings();
        ActivitySetting.localUserSettings[accountId] = {...prevSettings, [key]: value};

        return ActivitySetting.localUserSettings[accountId];
    }

    static getLocalUserSetting<T>(accountId: number, key: keyof LocalUserSettings): T | undefined {
        const prevSettings = ActivitySetting.localUserSettings[accountId] ?? new LocalUserSettings();
        return (prevSettings ? prevSettings[key] : undefined) as unknown as T;
    }
}

