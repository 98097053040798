import { ROUTE_PARTNER_TAI } from "../../../routes/routes.partners";
import { PartnerConfig } from "./partner-config";

export const TAI_CONFIG: PartnerConfig = {
    route: ROUTE_PARTNER_TAI,
    styleClassName: "partner-tai",
    disableHeaderRightNavs: true,
    disableBrowseVideosLink: true,
    disableBrowseCoursesLink: true,
    disableAnonCourseAccess: true
};
