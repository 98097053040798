import { getGlobalConfig, replaceSiteUrl } from "../../config";
import { LANGUAGE_DEFAULT } from "../../core/locale";
import { Dictionary } from "../../model/types/dictionary";
import { Browser } from "../../core/browser";
import { MobileIntentConfig } from "../../core/mobile-intent.service";
import { isEmpty } from "lodash-es";

export class TwaEventHandler {
    private static enabled: boolean = false;
    private static mobileAppVersion: string = "";
    private static language: string = LANGUAGE_DEFAULT;
    private static TWAENABLED_KEY = "twaEnabled";
    private static MOBILEAPPVERSION_KEY = "mobileAppVersion";
    private static TWAENABLED_KEY_TRUE = "true";

    static setEnabled(enabled: boolean): void {
        if (TwaEventHandler.isTwaEnabled()) {
            return;
        }
        TwaEventHandler.enabled = enabled;
        sessionStorage.setItem(TwaEventHandler.TWAENABLED_KEY, enabled.toString());
    }

    static setLanguage(language: string): void {
        TwaEventHandler.language = language;
    }

    static isTwaEnabled(): boolean {
        return TwaEventHandler.enabled || sessionStorage.getItem(TwaEventHandler.TWAENABLED_KEY) === TwaEventHandler.TWAENABLED_KEY_TRUE;
    }

    static setMobileAppVersion(mobileAppVersion: string): void {
        if (TwaEventHandler.getMobileAppVersion()) {
            return;
        }
        TwaEventHandler.mobileAppVersion = mobileAppVersion;
        sessionStorage.setItem(TwaEventHandler.MOBILEAPPVERSION_KEY, mobileAppVersion?.toString());
    }

    static getMobileAppVersion(): string {
        return TwaEventHandler?.mobileAppVersion || sessionStorage.getItem(TwaEventHandler.MOBILEAPPVERSION_KEY);
    }

    static getIntentUrl(url?: string, fallbackUrl?: string): string {
        let mobileConfig: Dictionary<MobileIntentConfig> = getGlobalConfig()?.mobile;
        if (!mobileConfig) {
            return undefined;
        }

        let config = Browser.isIos() || Browser.isIosDesktopView() ? mobileConfig.ios : mobileConfig.android;
        const intentUrl = replaceSiteUrl(config.intentUrl, TwaEventHandler.language);
        const storeUrl = config.storeUrl[TwaEventHandler.language] || config.storeUrl[LANGUAGE_DEFAULT];

        const path = url || window.location.pathname || "";
        if (isEmpty(intentUrl)) {
            return path;
        }

        return intentUrl
            .replace("[URL]", path)
            .replace("[STORE_URL]", fallbackUrl || storeUrl);
    }

    static deeplink(route: string, params?: Dictionary<any>): void {
        const queryString = params ? "?" + Object.keys(params).map(key => `${key}=${params[key]}`).join("&") : "";
        window.location.href = TwaEventHandler.getIntentUrl(`${route}${queryString}`);
    }
}
