import { PartnerConfig } from "./partner-config";
import { ROUTE_PARTNER_SEMED_SALVADOR } from "../../../routes/routes.partners";

export const SEMED_SALVADOR_CONFIG: PartnerConfig = {
    route: ROUTE_PARTNER_SEMED_SALVADOR,
    styleClassName: "partner-semed-salvador",
    authenticationImageLink: "https://cdna.englishcentral.com/promo/demo/howitworks/partner-bg2.jpeg",
    posterLink: "https://cdna.englishcentral.com/posters/nectar-poster.png",
    videoLink: "https://cdna.englishcentral.com/posters/nectar-intro-video.mp4",
    registrationEnabled: false,
    courseIds: [8354, 8355, 8356, 8357],
    addHashedEmailTokenOnLogoRedirect: true
};
