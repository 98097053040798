// Helper Functions
import { isEmpty, isString, isUndefined, keys, reduce } from "lodash-es";
import { TrackerCustomDimensionsList } from "./trackers/tracker";

export const sanitizeTrackerParams = <T>(trackerParams): T => {
    return reduce(keys(trackerParams), (acc, key) => {
        const value = trackerParams[key];
        if ((isString(value) && isEmpty(value)) || isUndefined(value)) {
            return acc;
        }
        return {...acc, [key]: value};
    }, {} as T);
};

export const sanitizeCustomDimensionsList = (customDimensionsLists: TrackerCustomDimensionsList) => {
    return Object.keys(customDimensionsLists).reduce((acc, key) => {
        let value = customDimensionsLists[key];
        if (!value || value === "NULL" || value === "null" || value === "undefined") {
            value = "N/A";
        }
        return {...acc, [key]: value};
    }, {});
};
