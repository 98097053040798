import { AbstractEventFactory } from "./abstract";
import * as EventValidators from "../validator/index";
import { Activity } from "../../../../model/types/content/activity";

export class CompleteEventFactory extends AbstractEventFactory {
    constructor(protected accountId: number = 0) {
        super(accountId);

        let completeCliplist = {
            eventType: "CompleteCliplist",
            validator: new EventValidators.CompleteCliplistValidator
        };
        let completeQuiz = {
            eventType: "CompleteQuiz",
            validator: new EventValidators.CompleteQuizValidator
        };
        let completeWatch = {
            eventType: "CompleteActivityWatch",
            validator: new EventValidators.CompleteActivityWatchValidator
        };

        this.eventTypeLookup[Activity.ACTIVITY_TYPE_ID_WATCH] = completeWatch;
        this.eventTypeLookup[Activity.ACTIVITY_TYPE_ID_COMPREHENSION_QUIZ] = completeWatch;
        this.eventTypeLookup[Activity.ACTIVITY_TYPE_ID_LEARN] = {
            eventType: "CompleteActivityLearn",
            validator: new EventValidators.CompleteActivityLearnValidator
        };
        this.eventTypeLookup[Activity.ACTIVITY_TYPE_ID_SPEAK] = {
            eventType: "CompleteActivitySpeak",
            validator: new EventValidators.CompleteActivitySpeakValidator
        };
        this.eventTypeLookup[Activity.ACTIVITY_CLIPLIST_NAMED_WATCH] = completeCliplist;
        this.eventTypeLookup[Activity.ACTIVITY_CLIPLIST_NAMED_LEARN] = completeCliplist;
        this.eventTypeLookup[Activity.ACTIVITY_CLIPLIST_NAMED_SPEAK] = completeCliplist;
        this.eventTypeLookup[Activity.ACTIVITY_CLIPLIST_PRON_WATCH] = completeCliplist;
        this.eventTypeLookup[Activity.ACTIVITY_CLIPLIST_PRON_SPEAK] = completeCliplist;
        this.eventTypeLookup[Activity.ACTIVITY_CLIPLIST_VOCAB_WATCH] = completeCliplist;
        this.eventTypeLookup[Activity.ACTIVITY_CLIPLIST_VOCAB_LEARN] = completeCliplist;
        this.eventTypeLookup[Activity.ACTIVITY_CLIPLIST_VOCAB_SPEAK] = completeCliplist;
        this.eventTypeLookup[Activity.ACTIVITY_CLIPLIST_DYNAMIC] = completeCliplist;
        this.eventTypeLookup[Activity.ACTIVITY_TYPE_ID_QUIZ] = completeQuiz;
        this.eventTypeLookup[Activity.ACTIVITY_TYPE_ID_COURSE_QUIZ] = completeQuiz;
        this.eventTypeLookup[Activity.ACTIVITY_TYPE_ID_COURSE_QUIZ_CURATED] = completeQuiz;
        this.eventTypeLookup[Activity.ACTIVITY_TYPE_ID_QUIZ_WORD] = completeQuiz;
        this.eventTypeLookup[Activity.ACTIVITY_TYPE_ID_VOCABULARY_ASSESSMENT] = {
            eventType: "",
            validator: new EventValidators.CompleteActivityValidator
        };
    }
}
