import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HAMMER_GESTURE_CONFIG } from "@angular/platform-browser";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ConnectionFactoryService } from "./connection-factory.service";
import { FeatureModelService } from "../model/identity/feature-model.service";
import { TutorAccountStateService } from "./tutor-account-state.service";
import { TutorRatingModelService } from "../model/tutor/tutor-rating-model.service";
import { TutorAccountModelService } from "../model/tutor/tutor-account-model.service";
import { AccessModelService } from "../model/commerce/access-model.service";
import { HttpClientModule } from "@angular/common/http";
import { ActivitySummaryModelService } from "../model/reportcard/activity-summary-model.service";
import { ClassReportModelService } from "../model/reportcard/class-report-model.service";
import { SafeHtmlPipe } from "../shared/pipes/safe-html.pipe";
import { MobileIntentService } from "./mobile-intent.service";
import { SafeUrlPipe } from "../shared/pipes/safe-url.pipe";
import { ClassIdentityModelService } from "../model/identity/class-identity-model.service";
import { ModalLaunchService } from "./modal-launch.service";
import { HammerConfig } from "./hammer-config";
import { ReferenceModelService } from "../model/content/reference-model.service";
import { ChannelService } from "./channel.service";
import { IntersectionObserverDirective } from "./elements/intersection-observer.directive";
import { InstrumentationService } from "./instrumentation/instrumentation.service";
import { EventModelService } from "../model/reportcard/event-model.service";
import { EventFactoryService } from "../common-app/progress-app/event-factory.service";
import { SubscriptionAbstract } from "./subscription.abstract";
import { DomEventService } from "../shared/dom-event.service";

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        NgbModule,
        SafeUrlPipe
    ],
    exports: [
        CommonModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        SafeHtmlPipe,
        SafeUrlPipe,
        IntersectionObserverDirective
    ],
    providers: [
        ChannelService,
        ConnectionFactoryService,
        FeatureModelService,
        TutorAccountStateService,
        TutorAccountModelService,
        TutorRatingModelService,
        AccessModelService,
        ActivitySummaryModelService,
        ClassIdentityModelService,
        ClassReportModelService,
        MobileIntentService,
        ModalLaunchService,
        ReferenceModelService,
        InstrumentationService,
        EventModelService,
        EventFactoryService,
        DomEventService,
        {
            provide: HAMMER_GESTURE_CONFIG,
            useClass: HammerConfig
        }
    ],
    declarations: [
        SafeHtmlPipe,
        IntersectionObserverDirective,
        SubscriptionAbstract
    ]

})
export class CoreModule {
}
