import { Logger } from "../../logger/logger";
import { TrackerName } from "./tracker";

// Logs
export const LOG_TAG_ANALYTICS = "[Analytics]";

const logger = new Logger();

export class TrackerLogger {
    constructor(private trackerName: TrackerName, protected loggingEnabled: boolean = false) {
    }

    log(message: string): void {
        if (!message || !this.loggingEnabled) {
            return;
        }
        logger.log(`${LOG_TAG_ANALYTICS} [${this.trackerName}]`, message);
    }
}
