import { ROUTE_PARTNER_DESCOMPLICA } from "../../../routes/routes.partners";

import { PartnerConfig } from "./partner-config";

export const DESCOMPLICA_CONFIG: PartnerConfig = {
    route: ROUTE_PARTNER_DESCOMPLICA,
    styleClassName: "partner-descomplica",
    levelSelectorEnabled: true,
    disableHeaderRightNavs: true
};
