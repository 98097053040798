import { Injectable } from "@angular/core";
import { Emitter } from "./emitters/emitter";
import { Observable, Subscription } from "rxjs";

/*
Channel Pattern

think of a TV station sending airwaves and multiple antennas receiving the signal
but once you miss a TV show, it's gone (regular Subject)

only use this one as a last resource
best use case is for components that are not related to each other but needs to communicate somehow
will make the bundle size smaller by not including all dependencies between apps

 */

export const CHANNEL_INITIALIZE_CHAT_MODE = "initializeChatMode";
export const CHANNEL_UPDATE_SCREEN_INNER_HEIGHT = "uptadeScreenInnerHeight";
export const CHANNEL_NATIVE_KEYBOARD_ENABLED     = "channelNativeKeyboardEnabled";
export const CHANNEL_SHOW_RELATED_VIDEOS = "channelShowRelatedVideos";
export const CHANNEL_LEVEL_SELECTION = "channelLevelSelection";
export const CHANNEL_OPEN_AUTHENTICATION_MODAL = "channelOpenAuthenticationModal";
export const CHANNEL_OPEN_INITIAL_STEPS_MODAL = "channelOpenInitialStepsModal";
export const CHANNEL_TWILIO_CHAT_TOGGLE = "channelTwilioChatToggle";
export const CHANNEL_TWILIO_CHAT_UPDATE = "channelTwilioChatUpdate";
export const CHANNEL_ON_LOGIN_TYPE_CHANGE = "onLoginTypeChange";

export const CHANNEL_ON_SEND_DISCUSSION_DRAFT = "onSendDiscussionDraft";
export const CHANNEL_ON_SEND_DISCUSSION_ANSWER_RECORDING = "onSendDiscussionAnswerRecording";
export const CHANNEL_ON_SEND_DISCUSSION_ANSWER = "onSendDiscussionAnswer";
export const CHANNEL_ON_COMPLETE_DISCUSSION = "onCompleteDiscussion";
export const CHANNEL_ON_CHAT_BOT_TYPING = "onChatBotTyping";
export const CHANNEL_ON_INITIAL_CHAT_MESSAGE = "onInitialChatMessage";
export const CHANNEL_ON_INITIAL_CHAT_MESSAGE_INIT = "onInitialChatMessageInnit";
export const CHANNEL_ON_MIMI_CHAT_MESSAGE = "onMiMiChatMessage";
export const CHANNEL_ON_USER_CHAT_MESSAGE = "onUserChatMessage";
export const CHANNEL_ON_UPDATE_USER_MESSAGE_COUNT = "onUpdateUserMessageCount";
export const CHANNEL_ON_CLICK_END_CHAT_CONFIRMATION = "onClickEndChatConfirmation";
export const CHANNEL_ON_END_CHAT_CONVERSATION = "onEndChatConversation";
export const CHANNEL_ON_DISPLAY_CHAT_RESULT = "onDisplayChatResult";
export const CHANNEL_ON_CLICK_END_CHAT = "onEndChat";
export const CHANNEL_ON_START_ROLE_PLAY = "onStartRolePlay";
export const CHANNEL_ON_COMPLETE_ROLE_PLAY = "onCompleteRolePlay";

@Injectable({providedIn: "root"})
export class ChannelService {
    private emitter = new Emitter(true);
    private behaviorEmitter = new Emitter(true, Emitter.TYPE_BEHAVIOR);

    getObservable<T>(eventName: string): Observable<T> {
        return this.emitter.getObservable(eventName);
    }

    getBehaviorObservable<T>(eventName: string): Observable<T> {
        return this.behaviorEmitter.getObservable(eventName);
    }

    subscribe(eventName: string, successFn: (data?) => void, errorFn?: (e?) => void): Subscription {
        return this.emitter.subscribe(eventName, successFn, errorFn);
    }

    subscribeBehavior(eventName: string, successFn: (data?) => void, errorFn?: (e?) => void): Subscription {
        return this.behaviorEmitter.subscribe(eventName, successFn, errorFn);
    }

    publishBehavior(eventName: string, data?: any): void {
        this.behaviorEmitter.publish(eventName, data);
    }

    publish(eventName: string, data?: any): void {
        this.emitter.publish(eventName, data);
    }

    destroy(): void {
        this.emitter.destroy();
    }
}
