import { has, isEmpty } from "lodash-es";
import { AccountDemographics } from "./account-demographics";
import { AccountSubscriptionDowngrade } from "./product";
import { StudentOrganizationData } from "./student-organization-data";

export class Identity {
    accountID: number = 0;
    partnerID: number = 0;
    partnerIDs?: number[];
    gender: number = 0;
    primaryPartnerID: number = 0;
    originalPartnerID?: number = 0;
    originalConsumerID?: number = 0;
    currentConsumerID?: number = 0;
    difficultyLevel: number = 0;
    email: string = "";
    isStudent: boolean = false;
    isTeacher: boolean = false;
    name: string = "";
    alias?: string;
    nickName?: string = "";
    timezone: string = "";
    studentOrganizationData: StudentOrganizationData;
    siteLanguage: string = "";
    nativeLanguage: string = "";
    userLanguage: string = "";
    skypeID?: string = "";
    phone?: string = "";
    roleTypeIDs: number[] = [];
    playerSettings: any = undefined;
    geolocatorCountry?: string;
    transliteratedName?: string;
    avatarURL?: string = "";
    lastAddedCourse?: string = "";
    lastAddedCourseId?: string = "";
    nativeLanguageLabel?: string = "";
    dateAdded?: string = "";
    lastVisitDate?: string = "";
    dateSubscriptionExpiration?: string = "";
    dateDeactivated?: string = "";
    hasUsedMobile?: boolean = false;
    studentClassIDs: number[] = [];
    country?: string = "";
    datePremiumStart?: string = "";
    subscription?: string;
    useWebRtc?: boolean = false;
    substituteIfTutorNotAvailable: boolean = true;
    isInvisible: boolean = false;
    isCourseOnly?: boolean;
    isSubscriptionRenewing?: boolean = false;
    facebookID: number = 0;
    googleID: string = "";
    demographics?: AccountDemographics;
    comprehensionQuizEnabled?: boolean;
    userGoal?: number;
    whatsapp?: string = "";
    partnerWhitelist?: boolean = false;
    subscriptions?: AccountSubscriptionDowngrade[];
    latestStudyLanguage?: string = "";
}

export class PartnerName {
    Name: string;
    name: string;
    accountId: number;
    added: string;
    partnerAccountId: number;
}

export class PartnerNames {
    [index: number]: PartnerName
}

export const DEMOGRAPHICSTYPE_CONTACTTIMEPREFERENCE = "contactTimePreference";
export const CONTACTTIMEPREFERENCE_TYPE_MORNING = "morning";
export const CONTACTTIMEPREFERENCE_TYPE_AFTERNOON = "afternoon";
export const CONTACTTIMEPREFERENCE_TYPE_EVENING = "evening";
export const CONTACTTIMEPREFERENCE_TYPES = [
    {"type": "", "name": "(none selected)"},
    {"type": CONTACTTIMEPREFERENCE_TYPE_MORNING, "name": "Morning"},
    {"type": CONTACTTIMEPREFERENCE_TYPE_AFTERNOON, "name": "Afternoon"},
    {"type": CONTACTTIMEPREFERENCE_TYPE_EVENING, "name": "Evening"}
];


export const getName = (account: PartnerName, fallBackName: string): string => {
    if (has(account, "Name")) {
        return !isEmpty(account.Name) ? account.Name : fallBackName;
    }
    return fallBackName;
};

export const DEFAULT_EMPTY_NAME = "Anonymous";
