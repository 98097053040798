import { TrackerLogger } from "../tracker.logger";
import { Tracker, TrackerName } from "../tracker";

declare var _nasa: any;

export class NaverTracker extends TrackerLogger implements Tracker {

    constructor(private config: any,
                private isProductionMode: boolean = false) {
        super(TrackerName.NAVER, isProductionMode);
    }

    isEnabled(): boolean {
        return typeof _nasa !== "undefined";
    }

    trackConversion(): void {
        if (!this.isEnabled()) {
            return;
        }

        /* start naver script don't modify */
        _nasa = {};
        if ((<any>window).wcs) {
            _nasa["cnv"] = (<any>window).wcs.cnv("1","10");
        }
        /* end naver script don't modify */
    }

    /* eslint-disable */
    init(): void {
        "use strict";
        this.insertScript(window, document, "script", `//wcs.naver.net/wcslog.js`);
        if (!(<any>window).wcs_add) (<any>window).wcs_add={};
        (<any>window).wcs_add["wa"] = this.config?.id;
        if (!(<any>window)._nasa) (<any>window)._nasa={};
        if((<any>window).wcs){
            (<any>window).wcs.inflow();
            (<any>window).wcs_do(_nasa);
        }
    }

    private insertScript(i, s, o, g): void {
        var a, m;
        a = s.createElement(o);
        m = s.getElementsByTagName(o)[0];
        a.async = 1;
        a.src = g;
        m.parentNode.insertBefore(a, m);
    }
    /* eslint-enable */

    trackPageView(): void {
    }

    trackEvent(): void {
    }
}
