import { CanActivateFn, Router, UrlTree } from "@angular/router";
import { IdentityService } from "../../../../core/identity.service";
import { inject } from "@angular/core";
import { ROUTE_MY_ENGLISH } from "../../../routes/routes";

export const partnerParanaAuthGuard: CanActivateFn = (): UrlTree | boolean => {
    const identityService: IdentityService = inject(IdentityService);
    const router: Router = inject(Router);
    if (!identityService.isAnonymous()) {
        return router.parseUrl(ROUTE_MY_ENGLISH);
    }
    return true;
};
