import { ROUTE_PARTNER_SEEDPR } from "../../../routes/routes.partners";

import { PartnerConfig } from "./partner-config";

export const SEEDPR_CONFIG: PartnerConfig = {
    route: ROUTE_PARTNER_SEEDPR,
    styleClassName: "partner-seedpr",
    disableFooterToggling: true,
    levelSelectorEnabled: true,
    paidLessonLength: 40, // minutes,
    courseIds: [6252, 6307, 6253, 4633, 4634, 4635, 4636]
};
