import { Injectable } from "@angular/core";
import {
    ActivatedRoute,
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot,
    UrlTree
} from "@angular/router";
import { FeatureService } from "../../core/feature.service";
import { Observable } from "rxjs";
import { ROUTE_PARTNER_ROOT } from "./routes.partners";
import { AppStateService } from "../app-state.service";
import { IdentityService } from "../../core/identity.service";
import {
    getConfigByPartnerId,
    isBrowseVideosLinkInvisibleForPartnerPage,
    shouldPartnerGetRedirectedToMyRoutes,
    shouldPartnerGetRedirectedToTeacherTools
} from "../modules/partners/partners-config/partners.config";
import { map as rxJsMap } from "rxjs/operators";
import { MyClassAppStateService, MyClassState } from "../modules/my-class-app/my-class-app-state.service";
import { getQueryParams } from "../../core/utility-functions";
import {
    ROUTE_BROWSE, ROUTE_CORPORATE,
    ROUTE_COURSES,
    ROUTE_HOME,
    ROUTE_MY_CLASS,
    ROUTE_MY_ENGLISH,
    ROUTE_TEACHER_TOOLS, ROUTE_VI_LANDING,
    ROUTE_VIDEOS
} from "./routes";
import { GlobalSettingService } from "../../core/global-setting.service";

@Injectable({ providedIn: "root" })
export class LandingPageRedirectorGuard implements CanActivate {
    constructor(private myClassAppStateService: MyClassAppStateService,
                private appStateService: AppStateService,
                private identityService: IdentityService,
                private featureService: FeatureService,
                private router: Router,
                private route: ActivatedRoute,
                private globalSettingService: GlobalSettingService
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (this.shouldRedirectToVietnamLandingPage()) {
            return this.router.createUrlTree([ROUTE_VI_LANDING], { queryParamsHandling: "merge" });
        }

        if (this.shouldRedirectToConversionPage()) {
            return this.router.createUrlTree([ROUTE_HOME], { queryParamsHandling: "merge" });
        }

        if (this.shouldRedirectToMyClassView()) {
            return this.getLastAccessedClassState();
        }

        if (this.shouldRedirectToTeachView()) {
            return this.router.createUrlTree([ROUTE_TEACHER_TOOLS], { queryParamsHandling: "merge" });
        }

        const partnerRoute = this.getPartnerRoute();
        if (!!partnerRoute) {
            return this.router.parseUrl(partnerRoute);
        }

        if (this.shouldRedirectToMyEnglishView()) {
            return this.router.createUrlTree([ROUTE_MY_ENGLISH], { queryParamsHandling: "merge" });
        }

        if (this.shouldRedirectToBrowseVideos()
            && this.featureService.getFeature("isPwaVideosHeaderLinkEnabled", true)) {
            return this.router.createUrlTree([ROUTE_BROWSE, ROUTE_VIDEOS], { queryParamsHandling: "merge" });
        }

        const partnerCourseRoute = this.getPartnerCourseRoute();
        if (partnerCourseRoute) {
            return this.router.createUrlTree(partnerCourseRoute, { queryParamsHandling: "merge" });
        }

        if (this.featureService.getFeature("isPwaCoursesHeaderLinkEnabled", true)) {
            return this.router.createUrlTree([ROUTE_BROWSE, ROUTE_COURSES], { queryParamsHandling: "merge" });
        }
    }

    private shouldRedirectToBrowseVideos(): boolean {
        if (!this.identityService.isAnonymous()) {
            return true;
        }
        return !isBrowseVideosLinkInvisibleForPartnerPage(this.identityService.getPartnerId());
    }

    private shouldRedirectToConversionPage(): boolean {
        return this.identityService.isAnonymous()
            && this.featureService.getFeature("isConversionLandingPageEnabled", false);
    }

    private shouldRedirectToVietnamLandingPage(): boolean {
        return this.identityService.isAnonymous()
            && this.globalSettingService.get("isVietnamDomain");
    }

    getPartnerRoute(): string | undefined {
        const partnerConfig = getConfigByPartnerId(this.identityService.getPartnerId());
        if (!partnerConfig?.route) {
            return;
        }
        return `/${ROUTE_PARTNER_ROOT}/${partnerConfig.route}`;
    }

    private getPartnerCourseRoute(): string[] | undefined {
        const isPartnerCustomCoursesLinkEnabled = this.featureService.getFeature("isPartnerCustomCoursesLinkEnabled", false);
        if (!isPartnerCustomCoursesLinkEnabled) {
            return undefined;
        }
        const partnerConfig = getConfigByPartnerId(this.identityService.getPartnerId());
        if (partnerConfig?.route) {
            return [ROUTE_PARTNER_ROOT, partnerConfig.route, "courses"];
        }
        return undefined;
    }

    private shouldRedirectToTeachView(): boolean {
        const accountIdentity = this.identityService.getIdentity();
        const partnerId = this.identityService.getPartnerId();

        return shouldPartnerGetRedirectedToTeacherTools(partnerId, accountIdentity);
    }

    private shouldRedirectToMyClassView(): boolean {
        return !this.identityService.isTeacher() &&
            this.identityService.isStudent() &&
            this.isMyClassEnabled();
    }

    private getLastAccessedClassState(): Observable<UrlTree> {
        return this.myClassAppStateService
            .getLastAccessedState(this.identityService.getAccountId())
            .pipe(
                rxJsMap((myClassState: MyClassState) => {
                    const navigationPath = [ROUTE_MY_CLASS];
                    if (myClassState?.classId) {
                        navigationPath.push(myClassState.classId.toString());
                        if (myClassState?.groupId) {
                            navigationPath.push(myClassState?.groupId.toString());
                        }
                    }

                    return this.router.createUrlTree(navigationPath, {
                        queryParamsHandling: "merge"
                    });
                })
            );
    }

    private isMyClassEnabled(): boolean {
        return this.featureService.getFeature("isMyClassEnabled", true);
    }

    private isUrlParamTruthy(urlParam: any): boolean {
        return urlParam == 1 || urlParam == "true" || urlParam === true;
    }

    private hasEnableInitialStepsQueryParam(): boolean {
        // IMPORTANT => initialNavigation is not triggered here yet. Therefore we need to get queryParams from location.
        return this.isUrlParamTruthy(this.route.snapshot.queryParamMap.get("enableInitialSteps") || getQueryParams()["enableInitialSteps"]);
    }

    private shouldRedirectToMyEnglishView(): boolean {
        if (!this.featureService.isMyEnglishEnabled()) {
            return false;
        }

        // Edge case => prevent redirection if enableInitialStepsParam is truthy. This usually comes from "login" screen when
        // site language and user language does not match. In order to prevent "MyEnglish Onboarding" and "InitialSteps" conflict,
        // here we prevent redirection. Then we initialize lazyLoadedInitialStepsModule.
        if (this.hasEnableInitialStepsQueryParam()) {
            return false;
        }
        const formData = this.appStateService.getFormData();
        if (formData && formData[AppStateService.SHOW_FL_PROMO]) {
            return false;
        }
        const partnerId = this.identityService.getPartnerId();
        // IF => User is trying to reach exact routes, let them reach it. Do not redirect to MyEnglish.
        return !this.identityService.isAnonymous()
            && this.featureService.isMyEnglishEnabled()
            && !shouldPartnerGetRedirectedToMyRoutes(partnerId);
    }
}
