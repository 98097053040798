import {
    LANGUAGE_DEFAULT,
    LANGUAGE_JAPANESE,
    LANGUAGE_KOREAN,
    LANGUAGE_TURKISH,
    LOCALE_AUSTRALIA,
    LOCALE_CANADA,
    LOCALE_ENGLAND,
    LOCALE_FRANCE,
    LOCALE_UNITED_STATES,
    LOCALES_EU
} from "./locale";

export const GEOLOCATION_EU = {
    AT: "AT",
    BE: "BE",
    BG: "BG",
    DE: "DE",
    DK: "DK",
    EE: "EE",
    ES: "ES",
    FI: "FI",
    FR: "FR",
    GR: "GR",
    HR: "HR",
    HU: "HU",
    IE: "IE",
    IT: "IT",
    LT: "LT",
    LU: "LU",
    LV: "LV",
    MT: "MT",
    NL: "NL",
    PO: "PO",
    PT: "PT",
    RO: "RO",
    SE: "SE",
    SI: "SI",
    SK: "SK"
};

export const GEOLOCATION_EU_INFLUENCE_ZONE = {
    AD: "AD",
    AL: "AL",
    BA: "BA",
    FO: "FO",
    IS: "IS",
    XK: "XK",
    LI: "LI",
    MK: "MK",
    MD: "MD",
    MC: "MC",
    ME: "ME",
    NO: "NO",
    SM: "SM",
    CH: "CH",
    VA: "VA"
};

export const GEOLOCATION_GB_INFLUENCE_ZONE = {
    IM: "IM",
    GI: "GI"
};

export const GEOLOCATION_GULF_ZONE = {
    SA: "SA",
    KW: "KW",
    AE: "AE",
    OM: "OM",
    QA: "QA",
    BH: "BH"
};

export const GEOLOCATION_ARAB_ZONE = {
    SA: "SA",
    JO: "JO",
    QA: "QA",
    AE: "AE",
    BH: "BH",
    OM: "OM",
    YE: "YE",
    IQ: "IQ",
    LB: "LB",
    EG: "EG",
    AR: "AR"
};

export const GEOLOCATION = {
    DEFAULT: "DEFAULT",
    TR: "TR",
    US: "US",
    JP: "JP",
    CA: "CA",
    AU: "AU",
    EU: "EU",
    GB: "GB",
    KR: "KR",
    BR: "BR",
    AR: "AR",
    VN: "VN",
    CN: "CN",
    PL: "PL",
    ...GEOLOCATION_EU,
    ...GEOLOCATION_EU_INFLUENCE_ZONE,
    ...GEOLOCATION_GB_INFLUENCE_ZONE,
    ...GEOLOCATION_GULF_ZONE
};

export const isEuCountry = (countryCode: string): boolean => {
    return countryCode == GEOLOCATION.EU || Object.values(GEOLOCATION_EU).includes(countryCode);
};

export const isEuInfluenceZoneCountry = (countryCode: string): boolean => {
    return countryCode === GEOLOCATION.EU || Object.values(GEOLOCATION_EU_INFLUENCE_ZONE).includes(countryCode);
};

export const isGbInfluenceZoneCountry = (countryCode: string): boolean => {
    return countryCode === GEOLOCATION.GB || Object.values(GEOLOCATION_GB_INFLUENCE_ZONE).includes(countryCode);
};

export const isGulfZoneCountry = (countryCode: string): boolean => {
    return Object.values(GEOLOCATION_GULF_ZONE).includes(countryCode);
};

export const isArabZoneCountry = (countryCode: string): boolean => {
    return Object.values(GEOLOCATION_ARAB_ZONE).includes(countryCode);
};

export const getGeolocationFromBrowserLang = (): string => {
    let browserLanguages: readonly any[] = (window.navigator.languages
        || [window.navigator.language || window.navigator["userLanguage"]]
    ) || [];
    browserLanguages = browserLanguages.filter(lang => typeof lang === "string");
    const convertBrowserLangToGeolocation = (browserLang: string): string => {
        if (browserLang == LANGUAGE_JAPANESE) {
            return GEOLOCATION.JP;
        }
        if (browserLang == LANGUAGE_TURKISH) {
            return GEOLOCATION.TR;
        }
        if (browserLang == LANGUAGE_KOREAN) {
            return GEOLOCATION.KR;
        }
        if (browserLang == LOCALE_UNITED_STATES) {
            return GEOLOCATION.US;
        }
        if (browserLang == LOCALE_ENGLAND) {
            return GEOLOCATION.GB;
        }
        if (browserLang == LOCALE_CANADA) {
            return GEOLOCATION.CA;
        }
        if (browserLang == LOCALE_AUSTRALIA) {
            return GEOLOCATION.AU;
        }
        if (browserLang == LOCALE_FRANCE) {
            return GEOLOCATION.FR;
        }
        if (LOCALES_EU.includes(browserLang)) {
            return GEOLOCATION.EU;
        }
        return GEOLOCATION.DEFAULT;
    };

    const defaultEnglishLanguages = [LANGUAGE_DEFAULT];
    let browserGeolocation = GEOLOCATION.DEFAULT;
    browserLanguages.some((browserLang) => {
        const isDefaultLanguage = defaultEnglishLanguages.includes(browserLang);
        if (!isDefaultLanguage) {
            browserGeolocation = convertBrowserLangToGeolocation(browserLang);
        }
        return !isDefaultLanguage;
    });
    return browserGeolocation;
};
