import { Language } from "../model/types/language";
import { Dictionary } from "../model/types/dictionary";
import { isEuCountry, isEuInfluenceZoneCountry, isGbInfluenceZoneCountry } from "./geolocation";

export const ALL_LANGUAGES = {
    "af": "Afrikaans",
    "sq": "Albanian",
    "ar": "Arabic",
    "hy": "Armenian",
    "as": "Assamese",
    "az": "Azeri",
    "eu": "Basque",
    "be": "Belarusian",
    "bn": "Bengali",
    "bg": "Bulgarian",
    "ca": "Catalan",
    "zh": "Chinese",
    "hr": "Croatian",
    "cs": "Czech",
    "da": "Danish",
    "div": "Divehi",
    "nl": "Dutch",
    "en": "English",
    "et": "Estonian",
    "fo": "Faeroese",
    "fa": "Farsi",
    "fi": "Finnish",
    "fr": "French",
    "mk": "FYRO Macedonian",
    "gd": "Gaelic",
    "ka": "Georgian",
    "de": "German",
    "el": "Greek",
    "gu": "Gujarati",
    "he": "Hebrew",
    "hi": "Hindi",
    "hu": "Hungarian",
    "is": "Icelandic",
    "id": "Indonesian",
    "it": "Italian",
    "ja": "Japanese",
    "ja-yl": "Japan Young Learner",
    "kn": "Kannada",
    "kk": "Kazakh",
    "kok": "Konkani",
    "ko": "Korean",
    "kz": "Kyrgyz",
    "lv": "Latvian",
    "lt": "Lithuanian",
    "ms": "Malay",
    "ml": "Malayalam",
    "mt": "Maltese",
    "mr": "Marathi",
    "mn": "Mongolian",
    "ne": "Nepali",
    "no": "Norwegian",
    "or": "Oriya",
    "pl": "Polish",
    "pt": "Portuguese",
    "pa": "Punjabi",
    "rm": "Rhaeto-Romanic",
    "ro": "Romanian",
    "ru": "Russian",
    "sa": "Sanskrit",
    "sr": "Serbian",
    "sk": "Slovak",
    "ls": "Slovenian",
    "sb": "Sorbian",
    "es": "Spanish",
    "sx": "Sutu",
    "sw": "Swahili",
    "sv": "Swedish",
    "syr": "Syriac",
    "ta": "Tamil",
    "tt": "Tatar",
    "te": "Telugu",
    "th": "Thai",
    "ts": "Tsonga",
    "tn": "Tswana",
    "tr": "Turkish",
    "uk": "Ukrainian",
    "ur": "Urdu",
    "uz": "Uzbek",
    "vi": "Vietnamese",
    "xh": "Xhosa",
    "yi": "Yiddish",
    "zu": "Zulu"
};

let generatedLanguages = [];
export const getAllLanguages = (): Language[] => {
    if (!generatedLanguages.length) {
        generatedLanguages = Object.keys(ALL_LANGUAGES).map((langKey) => {
            return {id: langKey, label: ALL_LANGUAGES[langKey]};
        });
    }
    return generatedLanguages;
};

export const SUPPORTED_LANGUAGES: Dictionary<Locale> = {
    en: {
        id: 0,
        language: "en",
        label: "English",
        locale: "en_US",
        description: "English",
        shortDateFormat: "MMM DD, YYYY",
        shortDateFormatDateFns: "MMM dd, yyyy",
        longDateFormat: "dddd, MMMM DD, YYYY",
        longDateFormatDateFns: "eeee, MMMM dd, yyyy"
    },
    es: {
        id: 1,
        language: "es",
        label: "Español",
        locale: "es_ES",
        description: "Spanish"
    },
    ja: {
        id: 2,
        language: "ja",
        label: "日本語",
        locale: "ja_JP",
        description: "Japanese",
        shortDateFormat: "YYYY年M月DD日 (dddd)",
        shortDateFormatDateFns: "yyyy'年'M'月'dd'日' (eeee)",
        longDateFormat: "YYYY年M月DD日",
        longDateFormatDateFns: "yyyy'年'M'月'dd'日' (eeee)"
    },
    ko: {
        id: 3,
        language: "ko",
        label: "한국어",
        locale: "ko_KR",
        description: "Korean",
        shortDateFormat: "YYYY년MM월DD일",
        shortDateFormatDateFns: "yyyy'년'MM'월'dd'일'",
        longDateFormat: "YYYY년MM월DD일",
        longDateFormatDateFns: "yyyy'년'MM'월'dd'일'"
    },
    pt: {
        id: 4,
        language: "pt",
        label: "Português",
        locale: "pt_BR",
        description: "Portuguese"
    },
    ru: {
        id: 5,
        language: "ru",
        label: "Русский",
        locale: "ru_RU",
        description: "Russian"
    },
    tr: {
        id: 6,
        language: "tr",
        label: "Türkçe",
        locale: "tr_TR",
        description: "Turkish",
        shortDateFormat: "DD MMM YYYY",
        shortDateFormatDateFns: "dd MMM yyyy",
        longDateFormat: "dddd, DD MMMM, YYYY",
        longDateFormatDateFns: "eee, dd MMMM, yyyy"
    },
    vi: {
        id: 7,
        language: "vi",
        label: "Tiếng Việt",
        locale: "vi_VN",
        description: "Vietnamese"
    },
    zh: {
        id: 8,
        language: "zh",
        label: "简体中文",
        locale: "zh_CN",
        description: "Chinese"
    },
    he: {
        id: 9,
        language: "he",
        label: "עִברִית",
        locale: "he_IL",
        description: "Hebrew"
    },
    ar: {
        id: 10,
        language: "ar",
        label: "عربى",
        locale: "ar_AE",
        description: "Arabic"
    },
    fr: {
        id: 11,
        language: "fr",
        label: "Français",
        locale: "fr_FR",
        description: "French",
        shortDateFormat: "DD MMM YYYY",
        shortDateFormatDateFns: "dd MMM yyyy",
        longDateFormat: "dddd, DD MMMM, YYYY",
        longDateFormatDateFns: "eee, dd MMMM, yyyy"
    },
    de: {
        id: 12,
        language: "de",
        label: "Deutsch",
        locale: "de_DE",
        description: "German",
        shortDateFormat: "DD MMM YYYY",
        shortDateFormatDateFns: "dd MMM yyyy",
        longDateFormat: "dddd, DD MMMM, YYYY",
        longDateFormatDateFns: "eee, dd MMMM, yyyy"
    },
    "ja-yl": {
        id: 14,
        language: "ja-yl",
        label: "にほんご",
        locale: "ja_JP",
        description: "Japanese Young Learners",
        shortDateFormat: "YYYY年M月DD日 (dddd)",
        shortDateFormatDateFns: "yyyy'年'M'月'dd'日' (eeee)",
        longDateFormat: "YYYY年M月DD日",
        longDateFormatDateFns: "yyyy'年'M'月'dd'日'"
    },
    th: {
        id: 15,
        language: "th",
        label: "Thai",
        locale: "th",
        description: "Thai"
    },
    tw: {
        id: 23,
        language: "tw",
        label: "臺灣國語",
        locale: "zh_TW",
        description: "Taiwanese"
    }
};

//Subdomain
export const SUBDOMAIN_DEFAULT = "www";
export const SUBDOMAIN_JAPANESE_YOUNG_LEARNERS = "ja-yl";

// Languages
export const LANGUAGE_CHINESE = "zh";
export const LANGUAGE_JAPANESE = "ja";
export const LANGUAGE_FRENCH = "fr";
export const LANGUAGE_DEFAULT = "en";
export const LANGUAGE_TURKISH = "tr";
export const LANGUAGE_KOREAN = "ko";
export const LANGUAGE_VIETNAMESE = "vi";
export const LANGUAGE_SPANISH = "es";
export const LANGUAGE_PORTUGUESE = "pt";
export const LANGUAGE_RUSSIAN = "ru";
export const LANGUAGE_ARABIC = "ar";
export const LANGUAGE_DANISH = "da";
export const LANGUAGE_GERMAN = "de";
export const LANGUAGE_HEBREW = "he";
export const LANGUAGE_ENGLISH = "en";
export const LANGUAGE_HEBREW_OLD = "iw";
export const LANGUAGE_JAPANESE_YOUNG_LEARNERS = "ja-yl";
export const LANGUAGE_THAI = "th";
export const LANGUAGE_TAIWANESE = "tw";

export const ROMANCE_LANGUAGES = [
    LANGUAGE_FRENCH,
    LANGUAGE_PORTUGUESE,
    LANGUAGE_SPANISH
];

// Locales
export const LOCALE_UNITED_STATES = "en-US";
export const LOCALE_ENGLAND = "en-GB";
export const LOCALE_CANADA = "en-CA";
export const LOCALE_AUSTRALIA = "en-AU";
export const LOCALE_FRANCE = "fr-FR";
export const LOCALES_EU: string[] = [
    "de-AT", // Austria
    "nl-BE", // Belgium
    "fr-BE", // Belgium
    "bg", // Bulgarian
    "de", // German
    "da", // Denmark,
    "et", // Estonia,
    "es-ES", // Spain,
    "sv-FI", // Finland,
    LOCALE_FRANCE, // France,
    "el", // Greece,
    "hr", // Croatia,
    "hu", // Hungary,
    "en-IE", // Ireland,
    "it-IT", // Italy
    "lt", // Lithuania
    "de-LU", // Luxembourg
    "fr-LU", // Luxembourg
    "lv", // Latvia
    "mt", // Malta
    "nl-NL", // Netherlands
    "pl", // Poland
    "pt-PT", // Portugal,
    "ro", // Romania,
    "sv-SE", // Sweden,
    "sl", // Slovenia,
    "sk" // Slovakia,
];

export const languageToMomentLocale = (lang: string): string => {
    const MOMENT_LOCALE = {
        pt: "pt-br",
        zh: "zh-cn"
    };

    return MOMENT_LOCALE[lang] || lang;
};

export const LOCALE_MAP: {[key: string]: any} = {
    US: "en-US",
    TR: "tr-TR",
    JP: "ja-JP",
    GB: "en-GB",
    CA: "en-CA",
    EU: "en-EU",
    AU: "en-AU",
    BR: "pt-BR",
    DEFAULT: "en-US"
};

export class Locale {
    id?: number;
    language: string;
    label: string;
    locale: string;
    description: string;
    shortDateFormat?: string;
    shortDateFormatDateFns?: string;
    longDateFormat?: string;
    longDateFormatDateFns?: string;
    checked?: boolean;

    static languageToLocale(lang: string): string {
        return SUPPORTED_LANGUAGES[lang]
            ? SUPPORTED_LANGUAGES[lang].locale
            : SUPPORTED_LANGUAGES[LANGUAGE_DEFAULT].locale;
    }

    static getShortDateFormat(lang: string): string {
        return SUPPORTED_LANGUAGES[lang] && SUPPORTED_LANGUAGES[lang].shortDateFormat
            ? SUPPORTED_LANGUAGES[lang].shortDateFormat
            : SUPPORTED_LANGUAGES[LANGUAGE_DEFAULT].shortDateFormat;
    }

    static getShortDateFormatDateFns(lang: string): string {
        return SUPPORTED_LANGUAGES[lang] && SUPPORTED_LANGUAGES[lang].shortDateFormatDateFns
            ? SUPPORTED_LANGUAGES[lang].shortDateFormatDateFns
            : SUPPORTED_LANGUAGES[LANGUAGE_DEFAULT].shortDateFormatDateFns;
    }

    static getLongDateFormat(lang: string): string {
        return SUPPORTED_LANGUAGES[lang] && SUPPORTED_LANGUAGES[lang].longDateFormat
            ? SUPPORTED_LANGUAGES[lang].longDateFormat
            : SUPPORTED_LANGUAGES[LANGUAGE_DEFAULT].longDateFormat;
    }

    static getLongDateFormatDateFns(lang: string): string {
        return SUPPORTED_LANGUAGES[lang] && SUPPORTED_LANGUAGES[lang].longDateFormatDateFns
            ? SUPPORTED_LANGUAGES[lang].longDateFormatDateFns
            : SUPPORTED_LANGUAGES[LANGUAGE_DEFAULT].longDateFormatDateFns;
    }

    static getLanguageLabel(lang: string): string {
        return SUPPORTED_LANGUAGES[lang]
            ? SUPPORTED_LANGUAGES[lang].label
            : SUPPORTED_LANGUAGES[LANGUAGE_DEFAULT].label;
    }

    static getShortLanguageLabel(lang: string): string {
        return SUPPORTED_LANGUAGES[lang]
            ? SUPPORTED_LANGUAGES[lang].language
            : SUPPORTED_LANGUAGES[LANGUAGE_DEFAULT].language;
    }
}

export const getSupportedLanguagesForSearch = (): string[] => {
    return Object.values(SUPPORTED_LANGUAGES).map((lang) => lang.language);
};

export const isRomanceLanguage = (lang: string): boolean => {
    return Object.values(ROMANCE_LANGUAGES).includes(lang);
};

export const getLocaleFromGeolocation = (geolocation: string) => {
    if (!LOCALE_MAP[geolocation]) {
        if ((isEuCountry(geolocation) || isEuInfluenceZoneCountry(geolocation))) {
            return LOCALE_MAP.EU;
        }
        if (isGbInfluenceZoneCountry(geolocation)) {
            return LOCALE_MAP.GB;
        }
    }
    return LOCALE_MAP[geolocation] || LOCALE_MAP.DEFAULT;
};
