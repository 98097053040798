export const RESPONSE_KEY = {
    IDENTITY_INVALIDACCESSTOKEN: "IDENTITY_INVALIDACCESSTOKEN",
    IDENTITY_INVALIDANONYMOUS: "IDENTITY_INVALIDANONYMOUS",
    IDENTITY_IDENTITYNOTFOUND: "IDENTITY_IDENTITYNOTFOUND",
    IDENTITY_IDENTITYFORACCOUNTNOTFOUND: "IDENTITY_IDENTITYFORACCOUNTNOTFOUND",
    IDENTITY_EMAILPASSWORDINCORRECT: "IDENTITY_EMAILPASSWORDINCORRECT",
    IDENTITY_INVALIDARRIVALID: "IDENTITY_INVALIDARRIVALID",
    IDENTITY_MISTAKENIDENTITYARRIVAL: "IDENTITY_MISTAKENIDENTITYARRIVAL",
    IDENTITY_INVALIDROLE: "IDENTITY_INVALIDROLE",
    IDENTITY_ACCOUNTEXISTS: "IDENTITY_ACCOUNTEXISTS",
    UNAUTHORIZED_WARNING: "UNAUTHORIZED_WARNING",
    UNAUTHORIZED_FATAL: "UNAUTHORIZED_FATAL",
    INVALID_ENDPOINT: "INVALID_ENDPOINT",
    TUTOR_CANNOTSCHEDULENEW: "TUTOR_CANNOTSCHEDULENEW",
    TUTOR_CANNOTSCHEDULENEW_CONFLICTSKYPEIDANDPHONENUMBER: "TUTOR_CANNOTSCHEDULENEW_CONFLICTSKYPEIDANDPHONENUMBER",
    TUTOR_CANNOTSCHEDULENEW_CONFLICTPHONENUMBER: "TUTOR_CANNOTSCHEDULENEW_CONFLICTPHONENUMBER",
    TUTOR_CANNOTSCHEDULENEW_CONFLICTSKYPEID: "TUTOR_CANNOTSCHEDULENEW_CONFLICTSKYPEID",
    TUTOR_CANNOTSCHEDULENEW_CONFLICTSCHEDULE: "TUTOR_CANNOTSCHEDULENEW_CONFLICTSCHEDULE",
    TUTOR_CANNOTSCHEDULENEW_LATESTLTSTATEBLOCKEDFROMSCHEDULING: "TUTOR_CANNOTSCHEDULENEW_LATESTLTSTATEBLOCKEDFROMSCHEDULING",
    TUTOR_CANNOTSCHEDULE_NOTELIGIBLE: "TUTOR_CANNOTSCHEDULE_NOTELIGIBLE",
    TUTOR_CANNOTSCHEDULE_MISSINGSKYPE: "TUTOR_CANNOTSCHEDULE_MISSINGSKYPE",
    TUTOR_CANNOTRESCHEDULE_NOTINSCHEDULEDSTATE: "TUTOR_CANNOTRESCHEDULE_NOTINSCHEDULEDSTATE",
    TUTOR_CANNOTSCHEDULENEW_ONGOINGSCHEDULING: "TUTOR_CANNOTSCHEDULENEW_ONGOINGSCHEDULING",
    TUTOR_CANNOTCANCEL_ADMIN: "TUTOR_CANNOTCANCEL_ADMIN",
    TUTOR_INVALIDSESSIONDATE: "TUTOR_INVALIDSESSIONDATE",
    TUTOR_NONUNIQUESKYPE: "TUTOR_NONUNIQUESKYPE",
    TUTOR_NONUNIQUEPHONE: "TUTOR_NONUNIQUEPHONE",
    TUTOR_TOOMANYMISSED: "TUTOR_TOOMANYMISSED",
    TUTOR_TOOLATETORESCHEDULE: "TUTOR_TOOLATETORESCHEDULE",
    TUTOR_EXPIREDSUBSCRIPTION: "TUTOR_EXPIREDSUBSCRIPTION",
    TUTOR_SCHEDULENOTNEW: "TUTOR_SCHEDULENOTNEW",
    TUTOR_TIMESLOTNOTAVAILABLE: "TUTOR_TIMESLOTNOTAVAILABLE",
    TUTOR_INVALIDSTATETRANSITION: "TUTOR_INVALIDSTATETRANSITION",
    TUTOR_CANNOTCANCEL_PASTCANCELTHRESHOLD: "TUTOR_CANNOTCANCEL_PASTCANCELTHRESHOLD",
    TUTOR_TOOMANYSESSIONCHANGES: "TUTOR_TOOMANYSESSIONCHANGES",
    TUTOR_CANNOTSCHEDULEFL_CONFLICTDEVICEID: "TUTOR_CANNOTSCHEDULEFL_CONFLICTDEVICEID",
    TUTOR_NOSESSIONSFOUND: "TUTOR_NOSESSIONSFOUND",
    INVALID_SKYPE_ID: "INVALID_SKYPE_ID",
    ACCOUNT_EMAIL_TAKEN: "ACCOUNT_EMAIL_TAKEN",
    POSTOFFICE_SKYPEIDCONFLICTINACCOUNTSESSIONS: "POSTOFFICE_SKYPEIDCONFLICTINACCOUNTSESSIONS",
    EARLIEST_TIMESLOT_START_SCHEDULE_ALLOWED_MINUTES: "EARLIEST_TIMESLOT_START_SCHEDULE_ALLOWED_MINUTES",
    REPORTCARD_REPORT_INCOMPLETE: "REPORT_INCOMPLETE",
    PURCHASE_LOCKOUT: "PURCHASE_LOCKOUT",
    PURCHASE_REJECTED_INVALID_CARDNO: "PURCHASE_REJECTED_INVALID_CARDNO",
    PURCHASE_REJECTED_INVALID_EXPDATE: "PURCHASE_REJECTED_INVALID_EXPDATE",
    PURCHASE_REJECTED_UNSUPPORTED_CARDTYPE: "PURCHASE_REJECTED_UNSUPPORTED_CARDTYPE",
    PURCHASE_REJECTED_CARD_DECLINED: "PURCHASE_REJECTED_CARD_DECLINED",
    PURCHASE_REJECTED_INSUFFICIENT_FUNDS: "PURCHASE_REJECTED_INSUFFICIENT_FUNDS",
    PURCHASE_REJECTED_SECURITY_CODE: "PURCHASE_REJECTED_SECURITY_CODE",
    PURCHASE_REJECTED_FRAUD_SERVICE: "PURCHASE_REJECTED_FRAUD_SERVICE",
    PURCHASE_REJECTED_EC_SESSION_EXPIRED: "PURCHASE_REJECTED_EC_SESSION_EXPIRED",
    PURCHASE_REJECTED_CANNOT_COMPLETE: "PURCHASE_REJECTED_CANNOT_COMPLETE",
    PURCHASE_REJECTED_EC_NEEDS_NEW_FUNDING_SOURCE: "PURCHASE_REJECTED_EC_NEEDS_NEW_FUNDING_SOURCE",
    PURCHASE_REJECTED_TRY_AGAIN_LATER: "PURCHASE_REJECTED_TRY_AGAIN_LATER",
    PURCHASE_ERROR: "PURCHASE_ERROR",
    PURCHASE_ERROR_UNKNOWN_CARDTYPE: "PURCHASE_ERROR_UNKNOWN_CARDTYPE",
    ACCESS_CODE_USED: "ACCESS_CODE_USED",
    ACCESS_CODE_USED_BY_ACCOUNT: "ACCESS_CODE_USED_BY_ACCOUNT",
    ACCESS_CODE_INVALID: "ACCESS_CODE_INVALID",
    ACCESS_CODE_EXPIRED: "ACCESS_CODE_EXPIRED",
    ACCESS_CODE_INCORRECT_PRODUCTID: "ACCESS_CODE_INCORRECT_PRODUCTID",
    PURCHASE_REJECTED_IYZICO_3D_REQUIRED: "PURCHASE_REJECTED_IYZICO_3D_REQUIRED"
};

export class Response {

}
