import { includes } from "lodash-es";
import { ConversationType } from "../../../model/types/chat/conversation";
export interface CONVERSATION_DQ_EVENT_TYPES_SET {
    startEvent: string;
    answerEvent: string;
    draftEvent: string;
    completeEvent: string;
}

export class EventTypes {
    static readonly START_ACTIVITY_ASSESSMENT_DIAGNOSTIC = "StartActivityAssessmentDiagnostic";
    static readonly COMPLETE_ACTIVITY_ASSESSMENT_DIAGNOSTIC = "CompleteActivityAssessmentDiagnostic";
    static readonly START_ACTIVITY_ASSESSMENT_VOCABULARY = "StartActivityAssessmentVocabulary";
    static readonly COMPLETE_ACTIVITY_ASSSESMENT_VOCABULARY = "CompleteActivityAssessmentVocabulary";
    static readonly START_ACTIVITY_READING = "StartActivityReading";
    static readonly COMPLETE_ACTIVITY_READING = "CompleteActivityReading";
    static readonly START_ACTIVITY_READING_TEST = "StartActivityReadingTest";
    static readonly COMPLETE_ACTIVITY_READING_TEST = "CompleteActivityReadingTest";
    static readonly START_ACTIVITY_VOCABULARY_QUIZ_COURSE = "StartActivityVocabularyQuizCourse";
    static readonly COMPLETE_ACTIVITY_VOCABULARY_QUIZ_COURSE = "CompleteActivityVocabularyQuizCourse";
    static readonly START_ACTIVITY_VOCABULARY_QUIZ_DIALOG = "StartActivityVocabularyQuizDialog";
    static readonly COMPLETE_ACTIVITY_VOCABULARY_QUIZ_DIALOG = "CompleteActivityVocabularyQuizDialog";
    static readonly START_ACTIVITY_WATCH = "StartActivityWatch";
    static readonly START_ACTIVITY_LEARN = "StartActivityLearn";
    static readonly START_ACTIVITY_SPEAK = "StartActivitySpeak";
    static readonly COMPLETE_ACTIVITY_WATCH = "CompleteActivityWatch";
    static readonly COMPLETE_ACTIVITY_LEARN = "CompleteActivityLearn";
    static readonly COMPLETE_ACTIVITY_SPEAK = "CompleteActivitySpeak";
    static readonly COMPLETE_ACTIVITY_GO_LIVE = "CompleteActivityGoLive";
    static readonly COMPLETE_ACTIVITY_LEVEL_TEST = "CompleteActivityLevelTest";
    static readonly COMPLETE_ACTIVITY_SIMPLE_PRONUNCIATION = "CompleteActivitySimplePronunciation";
    static readonly DIALOG_LINE_WATCH = "DialogLineWatch";
    static readonly DIALOG_LINE_SPEAK = "DialogLineSpeak";
    static readonly VIEWED_WORD = "ViewedWord";
    static readonly TYPED_WORD = "TypedWord";
    static readonly STUDIED_WORD = "StudiedWord";
    static readonly LEARNED_WORD = "LearnedWord";
    static readonly QUIZZED_WORD = "QuizzedWord";
    static readonly RECORDED_WORD = "RecordedWord";
    static readonly HIDDEN_RECORDED_WORD = "HiddenRecordedWord";
    static readonly MY_CLASS_COUNTER = "MyClassCounter";
    static readonly START_CLIPLIST = "StartCliplist";
    static readonly COMPLETE_CLIPLIST = "CompleteCliplist";
    static readonly DIALOG_LINE_WATCH_CLIPLIST = "DialogLineWatchCliplist";
    static readonly DIALOG_LINE_SPEAK_CLIPLIST = "DialogLineSpeakCliplist";
    static readonly START_QUIZ = "StartQuiz";
    static readonly COMPLETE_QUIZ = "CompleteQuiz";
    static readonly FAVORITE_DIALOG = "FavoriteDialog";
    static readonly START_WORD = "StartWord";
    static readonly COMPLETE_WORD = "CompleteWord";
    static readonly FAVORITE_WORD = "FavoriteWord";
    static readonly KNOWN_WORD = "KnownWord";
    static readonly MATCH_WORD = "MatchWord";
    static readonly REVERSE_MATCH_WORD = "ReverseMatchWord";
    static readonly REVERSE_MATCH_WORD_TYPE = "ReverseMatchWordType";
    static readonly CLASS_PROGRESS = "ClassProgress";
    static readonly SHARE = "Share";
    static readonly RECORDING_SESSION = "RecordingSession";
    static readonly WATCH_COMPREHENSION_CHOICE = "WatchComprehensionChoice";
    static readonly ADAPTIVE_QUIZ_WORD = "AdaptiveQuizWord";
    static readonly ADAPTIVE_QUIZ_TEST_WORD = "AdaptiveQuizTestWord";
    static readonly TYPED_QUIZ_WORD = "TypedQuizWord";
    static readonly SPOKEN_QUIZ_WORD = "SpokenQuizWord";
    static readonly CHOSEN_QUIZ_WORD = "ChosenQuizWord";
    static readonly CHOSEN_DEFINITION = "ChosenDefinition";
    static readonly SKIPPED_QUIZ_WORD = "SkippedQuizWord";
    static readonly PRONUNCIATION_WORD = "PronunciationWord";
    static readonly SPOKEN_PRONUNCIATION_WORD = "SpokenPronunciationWord";
    static readonly CHOSEN_WORD_DEFINITION = "ChosenWordDefinition";
    static readonly START_DISCUSSION = "StartDiscussion";
    static readonly DISCUSSION_QUESTION_ANSWER = "DiscussionQuestionAnswer";
    static readonly DISCUSSION_QUESTION_DRAFT = "DiscussionQuestionDraft";
    static readonly COMPLETE_DISCUSSION = "CompleteDiscussion";
    static readonly ACCOUNT_SENTENCE_GROUP_PROGRESS = "AccountSentenceGroupProgress";
    static readonly START_ROLE_PLAY_CHAT = "StartRolePlayChat";
    static readonly COMPLETE_ROLE_PLAY_CHAT = "CompleteRolePlayChat";
    static readonly COMPLETE_ACTIVITY_LINK = "CompleteActivityLink";

    static readonly START_OPEN_RESPONSE_CHAT = "StartOpenResponseChat";
    static readonly OPEN_RESPONSE_ANSWER = "OpenResponseAnswer";
    static readonly OPEN_RESPONSE_DRAFT = "OpenResponseDraft";
    static readonly COMPLETE_OPEN_RESPONSE_CHAT = "CompleteOpenResponseChat";

    static readonly DISCUSSION_QUESTION_EVENT_TYPES: { [conversationTypeId: number]: CONVERSATION_DQ_EVENT_TYPES_SET } = {
        [ConversationType.CONVERSATION_TYPE_OPEN_RESPONSE]: {
            startEvent: EventTypes.START_OPEN_RESPONSE_CHAT,
            answerEvent: EventTypes.OPEN_RESPONSE_ANSWER,
            draftEvent: EventTypes.OPEN_RESPONSE_DRAFT,
            completeEvent: EventTypes.COMPLETE_OPEN_RESPONSE_CHAT
        },
        [ConversationType.CONVERSATION_TYPE_VIDEO_CHAT]: {
            startEvent: EventTypes.START_DISCUSSION,
            answerEvent: EventTypes.DISCUSSION_QUESTION_ANSWER,
            draftEvent: EventTypes.DISCUSSION_QUESTION_DRAFT,
            completeEvent: EventTypes.COMPLETE_DISCUSSION
        }
    };

    static isCompletionEvent(type: string): boolean {
        if (!type) {
            return false;
        }

        return includes([
            EventTypes.COMPLETE_ACTIVITY_ASSESSMENT_DIAGNOSTIC,
            EventTypes.COMPLETE_ACTIVITY_ASSSESMENT_VOCABULARY,
            EventTypes.COMPLETE_ACTIVITY_GO_LIVE,
            EventTypes.COMPLETE_ACTIVITY_LEARN,
            EventTypes.COMPLETE_ACTIVITY_LEVEL_TEST,
            EventTypes.COMPLETE_ACTIVITY_READING,
            EventTypes.COMPLETE_ACTIVITY_READING_TEST,
            EventTypes.COMPLETE_ACTIVITY_SPEAK,
            EventTypes.COMPLETE_ACTIVITY_VOCABULARY_QUIZ_COURSE,
            EventTypes.COMPLETE_ACTIVITY_VOCABULARY_QUIZ_DIALOG,
            EventTypes.COMPLETE_ACTIVITY_WATCH,
            EventTypes.COMPLETE_CLIPLIST,
            EventTypes.COMPLETE_QUIZ,
            EventTypes.COMPLETE_ACTIVITY_SIMPLE_PRONUNCIATION,
            EventTypes.COMPLETE_ACTIVITY_LINK
        ], type);
    }

    static isStartEvent(type: string): boolean {
        if (!type) {
            return false;
        }

        return includes([
            EventTypes.START_ACTIVITY_VOCABULARY_QUIZ_DIALOG,
            EventTypes.START_ACTIVITY_VOCABULARY_QUIZ_COURSE,
            EventTypes.START_ACTIVITY_ASSESSMENT_DIAGNOSTIC,
            EventTypes.START_ACTIVITY_ASSESSMENT_VOCABULARY,
            EventTypes.START_ACTIVITY_LEARN,
            EventTypes.START_ACTIVITY_READING,
            EventTypes.START_ACTIVITY_READING_TEST,
            EventTypes.START_ACTIVITY_SPEAK,
            EventTypes.START_ACTIVITY_WATCH,
            EventTypes.START_CLIPLIST,
            EventTypes.START_QUIZ,
            EventTypes.START_WORD
        ], type);
    }

    static isWordCorrect(type: string): boolean {
        if (!type) {
            return false;
        }

        return type == EventTypes.COMPLETE_WORD;
    }

    static isTypedWordCorrect(type: string, correct: boolean): boolean {
        if (!type) {
            return false;
        }

        return correct && type == EventTypes.TYPED_WORD;
    }

    static isLearnedWordEvent(type: string): boolean {
        if (!type) {
            return false;
        }

        return type == EventTypes.LEARNED_WORD;
    }

    static isQuizzedWordEvent(type: string): boolean {
        if (!type) {
            return false;
        }

        return type == EventTypes.QUIZZED_WORD;
    }

    static isWatchComprehensionChoiceEvent(type: string): boolean {
        if (!type) {
            return false;
        }

        return type == EventTypes.WATCH_COMPREHENSION_CHOICE;
    }

    static isWordEvent(type: string): boolean {
        if (!type) {
            return false;
        }

        return includes([
            EventTypes.TYPED_WORD,
            EventTypes.STUDIED_WORD,
            EventTypes.LEARNED_WORD,
            EventTypes.QUIZZED_WORD,
            EventTypes.RECORDED_WORD,
            EventTypes.HIDDEN_RECORDED_WORD,
            EventTypes.START_WORD,
            EventTypes.COMPLETE_WORD,
            EventTypes.FAVORITE_WORD,
            EventTypes.KNOWN_WORD,
            EventTypes.MATCH_WORD,
            EventTypes.REVERSE_MATCH_WORD,
            EventTypes.REVERSE_MATCH_WORD_TYPE
        ], type);
    }

    static isDynamicLearnSpeakEvent(type: string): boolean {
        if (!type) {
            return false;
        }

        return includes([
            EventTypes.LEARNED_WORD,
            EventTypes.DIALOG_LINE_SPEAK,
            EventTypes.DIALOG_LINE_SPEAK_CLIPLIST,
            EventTypes.COMPLETE_ACTIVITY_WATCH
        ], type);
    }

    static isCompleteActivityEvent(type: string): boolean {
        return includes([
            EventTypes.COMPLETE_ACTIVITY_GO_LIVE,
            EventTypes.COMPLETE_ACTIVITY_LEARN,
            EventTypes.COMPLETE_ACTIVITY_LEVEL_TEST,
            EventTypes.COMPLETE_ACTIVITY_SPEAK,
            EventTypes.COMPLETE_ACTIVITY_VOCABULARY_QUIZ_COURSE,
            EventTypes.COMPLETE_ACTIVITY_VOCABULARY_QUIZ_DIALOG,
            EventTypes.COMPLETE_ACTIVITY_WATCH,
            EventTypes.COMPLETE_CLIPLIST,
            EventTypes.COMPLETE_QUIZ,
            EventTypes.COMPLETE_DISCUSSION
        ], type);
    }

    static isDialogLineSpoken(type: string): boolean {
        if (!type) {
            return false;
        }

        return includes([
            EventTypes.DIALOG_LINE_SPEAK,
            EventTypes.DIALOG_LINE_SPEAK_CLIPLIST
        ], type);
    }
}
