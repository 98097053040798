import { BREAKPOINT_SM } from "../../pwa-v2-landing-app/helpers/device-breakpoints";

export namespace GlobalNotification {
    const defaultNotificationWidth = 320;

    export enum GenericNotificationType {
        DEFAULT = "default",
    }

    // Custom notifications
    export enum CustomNotificationType {
        DAILY_GOAL_COMPLETION = "dailyGoalCompletion",
        LOADING = "loading",
        REDIRECTING = "redirecting",
    }

    export type NotificationType = GenericNotificationType | CustomNotificationType;

    export enum DisplayPosition {
        TOP_LEFT = "TOP_LEFT",
        TOP = "TOP",
        TOP_RIGHT = "TOP_RIGHT",
        BOTTOM_RIGHT = "BOTTOM_RIGHT",
        BOTTOM = "BOTTOM",
        BOTTOM_LEFT = "BOTTOM_LEFT",
    }

    export class NotificationOptions {
        position: GlobalNotification.DisplayPosition = GlobalNotification.DisplayPosition.TOP;
        verticalGapInPx: number = 13;
        horizontalGapInPx: number = 13;
        timeoutMs: number = 4000;
        backgroundColor: string = "#fff";
        textColor: string = "#000";
        widthPx: number = defaultNotificationWidth;
        padding: string = "0";
        shouldOpenInsideModal: boolean = false;
    }

    export class BaseNotification {
        protected type: NotificationType = GlobalNotification.GenericNotificationType.DEFAULT;
        protected options: GlobalNotification.NotificationOptions = new GlobalNotification.NotificationOptions();

        constructor(
            type: GlobalNotification.NotificationType,
            options: Partial<GlobalNotification.NotificationOptions> = {}
        ) {
            this.type = type;
            this.setOptions(options);
        }

        getType(): GlobalNotification.NotificationType {
            return this.type;
        }

        getOptions(): GlobalNotification.NotificationOptions {
            return this.options;
        }

        protected setOptions(options: Partial<GlobalNotification.NotificationOptions>): void {
            if (options) {
                this.options = {...this.options, ...options};
                if (window && (window.innerWidth <= BREAKPOINT_SM) && options.widthPx > defaultNotificationWidth) {
                    options.widthPx = defaultNotificationWidth;
                }
            }
        }
    }
}
