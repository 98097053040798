export namespace GoogleAds {

    export enum ConversionType {
        REGISTER = "register",
        PURCHASE = "purchase",
        FREE_LESSON = "freeLesson",
        REGISTER_KO = "registerKo",
        PURCHASE_KO = "purchaseKo",
    }

    export interface EventConversionParams {
        send_to?: string; // Google sendToId inside config file
        value?: number; // Conversion value. Purchase price should be sent
        currency?: string; // Sets the currency of the conversion value
        event_callback?: () => void; // A callback function which is triggered after an event is being fired.
        transaction_id?: number | string; // Transaction id for tracking the purchases
        conversionType?: ConversionType;
    }

}



