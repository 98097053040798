import { NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";

export const MODAL_SIZE_SM: string = "sm";
export const MODAL_SIZE_LG: string = "lg";

export const DEFAULT_MODAL_OPTIONS = {
    container: ".ec-pwa-v2-landing-app",
    centered: true
};

// @INFO: This modal options supports the mobile devices and becomes fullscreen when the device breakpoins is lower than large.
export const DEFAULT_MODAL_OPTIONS_HYBRID = {
    ...DEFAULT_MODAL_OPTIONS,
    windowClass: "ec-modal",
    size: MODAL_SIZE_LG,
    centered: false,
    backdrop: "static"
} as NgbModalOptions;

