// [1] Doc: https://developers.google.com/analytics/devguides/collection/analyticsjs/creating-trackers
// [2] Doc: https://developers.google.com/analytics/devguides/collection/analyticsjs/events
import { EventAction } from "../../events/actions";

export namespace AnalyticsGoogle {
    export enum NameSpace {
        DEFAULT = "default",
    }

    export enum Command {
        SEND = "send",
        CREATE = "create",
        SET = "set",
        EVENT = "event",
        CONFIG = "config",
    }

    export enum CommandMethod {
        TRANSPORT = "transport_type",
        EVENT = "event",
        SOCIAL = "social",
        TIMING_COMPLETE = "timing_complete",
        PAGE_VIEW = "page_view"
    }

    export enum TransportMechanism {
        BEACON = "beacon",
    }

    export interface TimingCommandMethodFields {
        timingCategory: string; // A string for categorizing all user timing variables into logical groups (e.g. 'JS Dependencies').
        timingVar: string; // A string to identify the variable being recorded (e.g. 'load').
        timingValue: number; // The number of milliseconds in elapsed time to report to Google Analytics (e.g. 20).
        timingLabel: string; // A string that can be used to add flexibility in visualizing user timings in the reports (e.g. 'Google CDN').
    }

    export enum TimingLabel {
        DEFAULT = "default",
    }

    export interface EventParams {
        eventCategory?: string; // Typically the object that was interacted with (e.g. 'Video')
        eventAction?: EventAction | string; // The type of interaction (e.g. 'play')
        eventLabel?: string; // Useful for categorizing events (e.g. 'Fall Campaign')
        eventValue?: number; // A numeric value associated with the event (e.g. 42)
        nonInteraction?: boolean;
        trackOnce?: boolean;
        accountId?: number;
    }

    export interface TrackParams extends EventParams {
        eventValuesOther?: { [key: string]: any };
    }
}
