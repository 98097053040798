import { ROUTE_PARTNER_TURKISH_AIRLINES } from "../../../routes/routes.partners";

import { PartnerConfig } from "./partner-config";

export const THY_CONFIG: PartnerConfig = {
    route: ROUTE_PARTNER_TURKISH_AIRLINES,
    styleClassName: "partner-turkish-airlines",
    disableHeaderRightNavs: true,
    disableAnonCourseAccess: true,
    disableBrowseCoursesLink: true,
    disableBrowseVideosLink: true,
    accountRegistrationTagId: 59,
    levelSelectorEnabled: true,
    redirectToMyClassOnLogin: false
};


