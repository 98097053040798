import { AbstractEventFactory } from "./abstract";
import { ReportCardEvent } from "../../event-factory.service";
import { DateUtil } from "../../../../core/date-util";
import { clone, isEmpty } from "lodash-es";

export class GenericEventFactory extends AbstractEventFactory {

    public createEvent(eventParams: any): ReportCardEvent | undefined {
        if (isEmpty(eventParams)) {
            return undefined;
        }

        let currentTime = new Date();
        let params = clone(eventParams);

        params.type = eventParams.type;
        params.eventTime = DateUtil.getAdjustedBsonDate(undefined, true);
        params.accountID = this.accountId;
        params.activitySessionID = this.generateSessionId(eventParams.activityID, currentTime);

        return params;
    }

}
