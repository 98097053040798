import { TrackerCustomDimensions, TrackerName } from "../tracker";
import { TrackerLogger } from "../tracker.logger";

declare const window: any;

export class GoogleTagManagerTracker extends TrackerLogger {
    constructor(private clientId: string,
                private isProductionMode: boolean = false,
                private customDimensions?: TrackerCustomDimensions,
                private namespace?: string) {
        super(TrackerName.GA, isProductionMode);
    }

    init(isProductionMode: boolean = false): void {
        if (!isProductionMode) {
            this.loggingEnabled = true;
        }
        this.setTrackingScript();
    }

    /* eslint-disable */
    private setTrackingScript() {
        "use strict";
        this.insertScript(window, document, "script", "dataLayer", this.clientId);
        var noscript = document.createElement("noscript");
        var iframe = document.createElement("iframe");
        iframe.setAttribute("src", `https://www.googletagmanager.com/ns.html?id=${this.clientId}`);
        iframe.setAttribute("height", "0");
        iframe.setAttribute("width", "0");
        iframe.setAttribute("style", "display:none;visibility:hidden");
        noscript.appendChild(iframe);
        document.body.appendChild(noscript);
    }

    private insertScript(w,d,s,l,i): void {
        w[l] = w[l] || [];
        w[l].push({
            'gtm.start': new Date().getTime(),
            event: 'gtm.js'
        });
        const f = d.getElementsByTagName(s)[0];
        const j = d.createElement(s);
        const dl = l !== 'dataLayer' ? '&l=' + l : '';
        j.async = true;
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        f.parentNode.insertBefore(j, f);
    }
    /* eslint-enable */
}
