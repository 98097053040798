import { TrackerLogger } from "../tracker.logger";
import { Tracker, TrackerName } from "../tracker";

declare var a8sales: any;

export namespace AnalyticsA8Fly {

    export interface EventParams {
        orderNumber: number;
        currencyCode: string;
        itemCode: number;
        totalOrderPrice: number;
    }

    export interface ConversionParams {
        programId: string;
        orderNumber: number;
        currencyCode: string;
        itemCode: number;
        totalOrderPrice: number;
    }
}

export class A8FlyTracker extends TrackerLogger implements Tracker {

    constructor(private config: any,
                private isProductionMode: boolean = false) {
        super(TrackerName.A8FLY, isProductionMode);
    }

    isEnabled(): boolean {
        return typeof a8sales !== "undefined";
    }

    trackConversion(params: AnalyticsA8Fly.ConversionParams): void {
        if (!this.isEnabled()) {
            return;
        }

        a8sales({
            "pid": params?.programId,
            "order_number": params?.orderNumber?.toString(),
            "currency": params?.currencyCode,
            "items": [
                {
                    "code": params?.itemCode?.toString(),
                    "price": params?.totalOrderPrice,
                    "quantity": 1
                }
            ]
        });
    }

    /* eslint-disable */
    init(): void {
        "use strict";
        this.insertScript(window, document, "script", `//statics.a8.net/a8sales/a8sales.js`);
    }

    private insertScript(i, s, o, g): void {
        var a, m;
        a = s.createElement(o);
        m = s.getElementsByTagName(o)[0];
        a.async = 1;
        a.src = g;
        m.parentNode.insertBefore(a, m);
    }
    /* eslint-enable */

    trackPageView(): void {
    }

    trackEvent(): void {
    }
}
