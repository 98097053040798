import { AccountIdentity } from "../../identity.service";
import { Logger } from "../../logger/logger";

export abstract class InstrumentationReporter {
    protected accountInfo: Record<string, any> = {};
    protected logger = new Logger();

    isEnabled(): boolean {
        return false;
    }

    sendEvent(eventName: string,
              additionalInfo?: Record<string, any>,
              duration?: number): void {
    }

    reportError(error, additionalInfo?: Record<string, any>): void {
    }

    setCustomAttribute(key: string, value: string): void {
    }

    setPageName(name: string): void {
    }

    getAccountInfo(): object {
        return this.accountInfo;
    }

    setAccountInfo(accountIdentity?: AccountIdentity): void {
        this.accountInfo = {
            accountID: accountIdentity ? accountIdentity.accountID : 0,
            partnerID: accountIdentity ? accountIdentity.primaryPartnerID : 0
        };
    }
}
