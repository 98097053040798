import { GEOLOCATION, isEuCountry, isEuInfluenceZoneCountry, isGbInfluenceZoneCountry } from "./geolocation";
import {
    PURCHASE_PROCESSOR_CIELO, PURCHASE_PROCESSOR_CREDIT_CARD,
    PURCHASE_PROCESSOR_INIPAY,
    PURCHASE_PROCESSOR_STRIPE,
    PURCHASE_PROCESSOR_VNPAY
} from "../payment-app/payment-app.config";
import { get, replace } from "lodash-es";

export const CURRENCIES: {[key: string]: string} = {
    USD: "USD",
    EUR: "EUR",
    TRY: "TRY",
    JPY: "JPY",
    GBP: "GBP",
    CAD: "CAD",
    AUD: "AUD",
    BRL: "BRL",
    VND: "VND",
    KRW: "KRW",
    PLN: "PLN"
};

export const CURRENCY_MAP: {[key: string]: any} = {
    [GEOLOCATION.US]: {
        locale: "en-US",
        currency: CURRENCIES.USD,
        currencySymbol: "$"
    },
    [GEOLOCATION.TR]: {
        locale: "tr-TR",
        currency: CURRENCIES.TRY,
        currencySymbol: "₺",
        currencyDisplay: "TL"
    },
    [GEOLOCATION.JP]: {
        locale: "ja-JP",
        currency: CURRENCIES.JPY,
        currencySymbol: "￥",
        strictFractionDigits: 0
    },
    [GEOLOCATION.GB]: {
        locale: "en-GB",
        currency: CURRENCIES.GBP,
        currencySymbol: "£",
        strictFractionDigits: 0
    },
    [GEOLOCATION.CA]: {
        locale: "en-CA",
        currency: CURRENCIES.CAD,
        currencySymbol: "$"
    },
    [GEOLOCATION.EU]: {
        locale: "en-EU",
        currency: CURRENCIES.EUR,
        currencySymbol: "€"
    },
    [GEOLOCATION.FR]: {
        locale: "fr-FR",
        currency: CURRENCIES.EUR,
        currencySymbol: "€"
    },
    [GEOLOCATION.BE]: {
        locale: "fr-BE",
        currency: CURRENCIES.EUR,
        currencySymbol: "€"
    },
    [GEOLOCATION.MC]: {
        locale: "fr-MC",
        currency: CURRENCIES.EUR,
        currencySymbol: "€"
    },
    [GEOLOCATION.CH]: {
        locale: "fr-CH",
        currency: CURRENCIES.EUR,
        currencySymbol: "€"
    },
    [GEOLOCATION.LU]: {
        locale: "fr-LU",
        currency: CURRENCIES.EUR,
        currencySymbol: "€"
    },
    [GEOLOCATION.AU]: {
        locale: "en-AU",
        currency: CURRENCIES.AUD,
        currencySymbol: "$"
    },
    [GEOLOCATION.BR]: {
        locale: "pt-BR",
        currency: CURRENCIES.BRL,
        currencySymbol: "B$"
    },
    [GEOLOCATION.PL]: {
        locale: "pl-PL",
        currency: CURRENCIES.PLN,
        currencySymbol: "zł"
    },
    [GEOLOCATION.VN]: {
        locale: "vi-VN",
        currency: CURRENCIES.VND,
        currencySymbol: "₫"
    },
    [GEOLOCATION.DEFAULT]: {
        locale: "en-US",
        currency: CURRENCIES.USD,
        currencySymbol: "$"
    }
};

export const PURCHASE_PROCESSOR_CURRENCY_MAP: {[key: string]: any} = {
    [PURCHASE_PROCESSOR_VNPAY]: {
        locale: "vi-VN",
        currency: CURRENCIES.VND,
        strictFractionDigits: 0
    },
    [PURCHASE_PROCESSOR_INIPAY]: {
        locale: "ko-KR",
        currency: CURRENCIES.KRW,
        currencySymbol: "₩",
        strictFractionDigits: 0
    },
    [PURCHASE_PROCESSOR_CIELO]: {
        ...CURRENCY_MAP[GEOLOCATION.BR],
        strictFractionDigits: 0
    }
};
export const getCurrencyMapByGeolocation = (
    geolocation: string,
    purchaseProcessor?: string
): any => {
    if (purchaseProcessor && PURCHASE_PROCESSOR_CURRENCY_MAP[purchaseProcessor]) {
        return PURCHASE_PROCESSOR_CURRENCY_MAP[purchaseProcessor];
    }

    if (geolocation === GEOLOCATION.BR && purchaseProcessor !== PURCHASE_PROCESSOR_STRIPE) {
        return CURRENCY_MAP[GEOLOCATION.US];
    }

    if (geolocation === GEOLOCATION.PL && purchaseProcessor !== PURCHASE_PROCESSOR_STRIPE) {
        return CURRENCY_MAP[GEOLOCATION.US];
    }

    if (geolocation === GEOLOCATION.VN && purchaseProcessor === PURCHASE_PROCESSOR_CREDIT_CARD) {
        return CURRENCY_MAP[GEOLOCATION.US];
    }

    if (!CURRENCY_MAP[geolocation]) {
        if ((isEuCountry(geolocation) || isEuInfluenceZoneCountry(geolocation))) {
            return CURRENCY_MAP[GEOLOCATION.EU];
        }
        if (isGbInfluenceZoneCountry(geolocation)) {
            return CURRENCY_MAP[GEOLOCATION.GB];
        }
    }
    return CURRENCY_MAP[geolocation] || CURRENCY_MAP[GEOLOCATION.DEFAULT];
};

export const getFormattedPrice = (
    price: number,
    decimal: number,
    geolocation: string,
    purchaseProcessor?: string): string => {
    const currencyMap = getCurrencyMapByGeolocation(geolocation, purchaseProcessor);
    const fractionDigits = get(currencyMap, "strictFractionDigits", decimal);
    const currencySymbol = get(currencyMap, "currencySymbol");
    const currencyDisplay = get(currencyMap, "currencyDisplay");
    const options = {
        style: "currency",
        currency: currencyMap.currency,
        minimumFractionDigits: fractionDigits
    };
    const intNumberFormatInstance = new Intl.NumberFormat(currencyMap.locale, options);
    const formattedPrice = intNumberFormatInstance.format(price);
    return currencyDisplay
        ? (replace(formattedPrice, currencySymbol, "") + ` ${currencyDisplay}`)
        : formattedPrice;
};


