import { NgbDate, NgbDateStruct, NgbTimeStruct } from "@ng-bootstrap/ng-bootstrap";
import { format, OptionsWithTZ, utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import {
    LANGUAGE_CHINESE,
    LANGUAGE_DEFAULT,
    LANGUAGE_FRENCH,
    LANGUAGE_GERMAN,
    LANGUAGE_HEBREW,
    LANGUAGE_JAPANESE,
    LANGUAGE_KOREAN,
    LANGUAGE_PORTUGUESE,
    LANGUAGE_RUSSIAN,
    LANGUAGE_SPANISH,
    LANGUAGE_TURKISH,
    LANGUAGE_VIETNAMESE,
    Locale as EcLocale
} from "./locale";
import { isUndefined, range, reduce, sum, toNumber, toString } from "lodash-es";
import {
    addDays,
    addMonths,
    differenceInDays,
    isAfter,
    isBefore,
    setHours,
    setMinutes,
    setSeconds,
    startOfDay,
    subDays,
    subMonths
} from "date-fns";

import {  ReportSettings } from "../model/types/class-content";
import { ngbDateToDate } from "./datetime/ngb-date-to-date";
import { REPORT_CURRICULUM_TYPE } from "../model/types/class-report";

// @FIXME: here be dragons, avoid using functions here unless you REALLY know what you're doing
// a lot of these functions were made without a basic understanding on how dates work behind the scenes
// and will do a lot of unnecessary conversion between internal date and display date (zoned date)

export interface ClassDateRange {
    fromDate: number;
    toDate: number;
}

export interface DateFnsParseOptions {
    time?: TimeOptions;
    setTimeToNow?: boolean;
    setTimeToBeginningOfDay?: boolean;
    setTimeToEndOfDay?: boolean;
}

export interface TimeOptions {
    hour?: number;
    minute?: number;
    second?: number;
}

export interface TimezoneDetails {
    timeZone: string;
    timeZoneOffsetHours: number;
    timeZoneOffsetMinutes: number;
    timeZoneOffsetSign: string;
}

export interface DateDetails {
    day: number;
    month: number;
    year: number;
}

export interface DateFnsParseOutput {
    date: Date;
    hour: number;
    minute: number;
    second: number;
}

// CONSTANTS
export const BSON_FORMAT = "yyyy-MM-dd'T'HH:mm:ss'.000Z'";
export const TIMEZONE_UTC = "Etc/UTC";
const TIME_SEPARATOR = ":";

// ---------------------------------------------------------------------------------------------------------------------
// 0. => GENERIC FUNCTIONS
export const getTimezoneDetails = (): TimezoneDetails => {
    const timeZone = DateFnsConfig.getTimezone();
    const timeZoneOffsetMinutes = (new Date()).getTimezoneOffset();
    return {
        timeZone,
        timeZoneOffsetHours: Math.round(timeZoneOffsetMinutes / 60),
        timeZoneOffsetMinutes,
        timeZoneOffsetSign: timeZoneOffsetMinutes < 0 ? "+" : "-"
    };
};

export const convertLocalDateToConfigDate = (localDate: Date): Date => {
    const utcDate = zonedTimeToUtc(localDate, DateFnsConfig.getLocalTimezone());
    return utcToZonedTime(utcDate, DateFnsConfig.getTimezone());
};

export class DateFnsConfig {
    private static language: string;
    private static locale?: Locale;
    private static timezone?: string;

    static setTimezone(timezone: string): void {
        DateFnsConfig.timezone = timezone;
    }

    static getTimezone(): string {
        return DateFnsConfig.timezone;
    }

    static getLocalTimezone(): string {
        return Intl?.DateTimeFormat().resolvedOptions().timeZone ?? TIMEZONE_UTC;
    }

    static async setLanguage(lang?: string): Promise<void> {
        DateFnsConfig.language = lang || LANGUAGE_DEFAULT;
        await DateFnsConfig.setLocaleByLanguage(DateFnsConfig.language);
    }

    static getLanguage(): string {
        return DateFnsConfig.language;
    }

    static async setLocaleByLanguage(lang?: string): Promise<void> {
        switch (lang) {
            case LANGUAGE_JAPANESE: {
                let {default: locale} = await import("date-fns/esm/locale/ja");
                DateFnsConfig.locale = locale;
                break;
            }
            case LANGUAGE_TURKISH: {
                let {default: locale} = await import("date-fns/esm/locale/tr");
                DateFnsConfig.locale = locale;
                break;
            }
            case LANGUAGE_FRENCH: {
                let {default: locale} = await import("date-fns/esm/locale/fr");
                DateFnsConfig.locale = locale;
                break;
            }
            case LANGUAGE_KOREAN: {
                let {default: locale} = await import("date-fns/esm/locale/ko");
                DateFnsConfig.locale = locale;
                break;
            }
            case LANGUAGE_SPANISH: {
                let {default: locale} = await import("date-fns/esm/locale/es");
                DateFnsConfig.locale = locale;
                break;
            }
            case LANGUAGE_RUSSIAN: {
                let {default: locale} = await import("date-fns/esm/locale/ru");
                DateFnsConfig.locale = locale;
                break;
            }
            case LANGUAGE_PORTUGUESE: {
                let {default: locale} = await import("date-fns/esm/locale/pt-BR");
                DateFnsConfig.locale = locale;
                break;
            }
            case LANGUAGE_VIETNAMESE: {
                let {default: locale} = await import("date-fns/esm/locale/vi");
                DateFnsConfig.locale = locale;
                break;
            }
            case LANGUAGE_CHINESE: {
                let {default: locale} = await import("date-fns/esm/locale/zh-CN");
                DateFnsConfig.locale = locale;
                break;
            }
            case LANGUAGE_HEBREW: {
                let {default: locale} = await import("date-fns/esm/locale/he");
                DateFnsConfig.locale = locale;
                break;
            }
            case LANGUAGE_GERMAN: {
                let {default: locale} = await import("date-fns/esm/locale/de");
                DateFnsConfig.locale = locale;
                break;
            }
            default: {
                let {default: locale} = await import("date-fns/esm/locale/en-US");
                DateFnsConfig.locale = locale;
                break;
            }
        }
    }

    static getLocale(): Locale {
        return DateFnsConfig.locale;
    }
}

export const getHoursOfDay = (hoursInDay: number = 24): number[] => {
    return range(1, hoursInDay + 1);
};

export const getIsoWeekdays = (daysInWeek: number = 7): number[] => {
    return range(1, daysInWeek + 1);
};

export const jsWeekdayToIsoWeekday = (jsWeekDay: number): number => {
    return (jsWeekDay % 7) + 1;
};

export const isoWeekdayToJsWeekday = (isoWeekday: number): number => {
    return (isoWeekday - 1) % 7;
};

export const getSafeValString = (timeVal: number): string => {
    return toString(timeVal <= 9 ? `0${timeVal}` : timeVal);
};

export const getTimeFromParseOptions = (options: Partial<DateFnsParseOptions>): TimeOptions => {
    const now = convertLocalDateToConfigDate(new Date());
    // Hour
    const hour = (
        options?.setTimeToNow
            ? now.getHours()
            : options?.setTimeToBeginningOfDay
                ? 0
                : options?.setTimeToEndOfDay
                    ? 23
                    : options?.time?.hour) || 0;
    // Minute
    const minute = (options?.setTimeToNow
        ? now.getMinutes()
        : options?.setTimeToBeginningOfDay
            ? 0
            : options?.setTimeToEndOfDay
                ? 59
                : options?.time?.minute) || 0;
    // Second
    const second = (options?.setTimeToNow
        ? now.getSeconds()
        : options?.setTimeToBeginningOfDay
            ? 0
            : options?.setTimeToEndOfDay
                ? 59
                : options?.time?.second) || 0;
    return {
        hour,
        minute,
        second
    };
};

export const dateToNgbTimeStruct = (options: Partial<DateFnsParseOptions>, dateInput?: Date): NgbTimeStruct => {
    const {hour, minute, second} = getTimeFromParseOptions(options);
    return {
        hour: hour || dateInput.getHours(),
        minute: minute || dateInput.getMinutes(),
        second: second || dateInput.getSeconds()
    };
};

// ---------------------------------------------------------------------------------------------------------------------
// 1. => PARSE FUNCTIONS

// 1.1. => Parse [NgbDateStruct] input with given [options]
export const parseNgbDateStruct = (
    dateInput: NgbDateStruct,
    options?: Partial<DateFnsParseOptions>
): DateFnsParseOutput => {
    const {hour, minute, second} = getTimeFromParseOptions(options);
    return {
        date: new Date(dateInput.year, dateInput.month - 1, dateInput.day, hour, minute, second),
        hour,
        minute,
        second
    };
};

// 1.2. => Parse [Date] input with given [options]
export const parseDateInput = (
    dateInput: Date,
    options?: Partial<DateFnsParseOptions>
): Date => {
    if (!options) {
        return dateInput;
    }
    const {hour, minute, second} = dateToNgbTimeStruct(options, dateInput);
    return setSeconds(setMinutes(setHours(dateInput, hour), minute), second);
};

// ---------------------------------------------------------------------------------------------------------------------
// 2. => CONVERSION FUNCTIONS

// 2.1. => Convert [Date] to [BSON]
// BSON needs to be in UTC format and timezone
export const convertDateToBson = (
    dateInput: Date,
    options?: Partial<DateFnsParseOptions>
): string => {
    const date = parseDateInput(dateInput, options);
    return date?.toISOString();
};

// 2.2. => Convert [NgbDateStruct] to [BSON]
export const convertNgbDateStructToBson = (
    dateInput: NgbDateStruct,
    options?: Partial<DateFnsParseOptions>
): string => {
    const timeZone = DateFnsConfig.getTimezone();
    const {date} = parseNgbDateStruct(dateInput, options);
    return zonedTimeToUtc(date, timeZone).toISOString();
};

// 2.3. => Convert [Date] to [NgbDateStruct], returns local day,month,year
export const convertDateToNgbDateStruct = (dateInput: Date, options?: Partial<DateFnsParseOptions>): NgbDateStruct => {
    const timeZone = DateFnsConfig.getTimezone();
    const localTime = utcToZonedTime(parseDateInput(dateInput, options), timeZone);
    return {day: localTime.getDate(), month: localTime.getMonth() + 1, year: localTime.getFullYear()};
};

// 2.4. => Convert [Date] to [NgbTimeStruct]
export const convertDateToNgbTimeStruct = (dateInput: Date, options?: Partial<DateFnsParseOptions>): NgbTimeStruct => {
    const localTime = utcToZonedTime(parseDateInput(dateInput, options), DateFnsConfig.getTimezone());
    return {
        hour: toNumber(localTime.getHours()),
        minute: toNumber(localTime.getMinutes()),
        second: toNumber(localTime.getSeconds())
    };
};

// 2.5. => Convert [NgbTimeStruct] to [Display String], joins the time with given separator string)
export const convertNgbTimeStructToString = (ngbTimeStruct: NgbTimeStruct, separator = TIME_SEPARATOR) => {
    return [ngbTimeStruct.hour, ngbTimeStruct.minute, ngbTimeStruct.second]
        .map((val) => getSafeValString(val))
        .join(separator);
};

// 2.6. => Convert [NgbTimeStruct] to [Seconds]
export const convertNgbTimeStructToSeconds = (ngbTimeStruct: NgbTimeStruct): number => {
    return sum([
        ngbTimeStruct.hour * 3600,
        ngbTimeStruct.minute * 60,
        ngbTimeStruct.second
    ]);
};

// 2.7. => Convert [NgbDateStruct] to [Given Format], defaults to [LongDate String], returns local time string
export const convertNgbDateStructToGivenFormat = (
    dateInput: NgbDateStruct,
    outputFormat: string,
    options?: Partial<DateFnsParseOptions>
): string => {
    const timeZone = DateFnsConfig.getTimezone();
    const time = parseNgbDateStruct(dateInput, options).date;
    const lang = DateFnsConfig.getLanguage();
    return format(
        time,
        outputFormat || EcLocale.getLongDateFormatDateFns(lang),
        {timeZone: timeZone, locale: DateFnsConfig.getLocale()}
    );
};

export const dateStructToDate = (dateStruct: NgbDateStruct, timeStruct?: Partial<NgbTimeStruct>): Date => {
    return convertLocalDateToConfigDate(ngbDateToDate(dateStruct, timeStruct));
};

export const fnsFormat = (
    dateInput: Date,
    outputFormat: string,
    options?: OptionsWithTZ
): string => {
    if (!options) {
        options = {};
    }
    options.timeZone = options.timeZone || DateFnsConfig.getTimezone();
    options.locale = options.locale || DateFnsConfig.getLocale();
    const localTime = utcToZonedTime(dateInput, options.timeZone);
    return format(localTime, outputFormat, options);
};

// 2.8. => Convert [Date] to [Given Format], defaults to [LongDate String], returns local time string
export const convertDateToGivenFormat = (
    dateInput: Date,
    outputFormat?: string,
    options?: Partial<DateFnsParseOptions & { timeZone: string }>
): string => {
    const timeZone = options?.timeZone || DateFnsConfig.getTimezone();
    const localTime = utcToZonedTime(parseDateInput(dateInput, options), timeZone);
    const lang = DateFnsConfig.getLanguage();
    return format(
        localTime,
        outputFormat || EcLocale.getLongDateFormatDateFns(lang),
        {timeZone: timeZone, locale: DateFnsConfig.getLocale()}
    );
};

// ---------------------------------------------------------------------------------------------------------------------
// 3. => COMPARISON FUNCTIONS
// Not needed mostly for date-fns. Please use isAfter, isBefore etc from date-fns directly. Date-fns uses plain JS date.
// Therefore no need to introduce comparison functions for now. Compare it directly inside the component/service/etc.


// ---------------------------------------------------------------------------------------------------------------------
// 4. => OTHER FUNCTIONS
// These are commonly used module specific date-helper-functions

// 4.1 => Convert [ReportSettings] to [ReportingPeriod]
export interface ReportingPeriod {
    endDateUtc: string;
    endDate: Date;
    startDateUtc: string;
    startDate: Date;
    reportType: string;
}
export const generateDefaultReportingPeriod = (
    reportSettings: Partial<ReportSettings>
): ReportingPeriod => {
    const nowLocal = new Date();
    const timezoneOffset = nowLocal.getTimezoneOffset(); // minutes
    const createResponse = (endDate: Date, startDate: Date): ReportingPeriod => {
        const response = {
            endDateUtc: endDate.toUTCString(),
            endDate: endDate,
            startDateUtc: startDate.toUTCString(),
            startDate: startDate,
            reportType: reportSettings.reportType
        };
        return response;
    };
    if (reportSettings.reportType == REPORT_CURRICULUM_TYPE.MONTHLY) {
        const reportDayOfMonthEndOfDay = (isUndefined(reportSettings.reportDayOfMonth) ? 1 : reportSettings.reportDayOfMonth) - 1;
        const year = nowLocal.getUTCFullYear();
        let month = nowLocal.getUTCMonth();
        let endDate = new Date(Date.UTC(year, month, reportDayOfMonthEndOfDay, 23, 59, 59, 999));
        // Corrections
        if (isBefore(endDate, new Date())) {
            endDate = addMonths(endDate, 1);
        }
        if (isAfter(subMonths(endDate, 1), new Date())) {
            endDate = subMonths(endDate, 1);
        }
        const startDate = subMonths(endDate, 1);
        return createResponse(endDate, startDate);
    } else {
        // 0 -> Sunday, 1 -> Monday
        const reportDayOfWeek = reportSettings.reportDayOfWeek - 1;
        const WEEK_DAYS_COUNT = 7;

        const findEndDate = (date?) => {
            const d = date ? addDays(date, 1) : subDays(new Date(), 1);
            const endDate = new Date(Date.UTC(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate(), 23, 59, 59, 999));
            if (endDate.getDay() == reportDayOfWeek) {
                return new Date(endDate);
            }
            return findEndDate(endDate);
        };
        let endDate = findEndDate();
        // Corrections
        if (timezoneOffset < 0) {
            endDate = addDays(endDate, 1);
        }
        if (isBefore(endDate, new Date())) {
            endDate = addDays(endDate, WEEK_DAYS_COUNT);
        }
        if (isAfter(subDays(endDate, WEEK_DAYS_COUNT), new Date())) {
            endDate = subDays(endDate, WEEK_DAYS_COUNT);
        }
        const startDate = subDays(endDate, WEEK_DAYS_COUNT);
        return createResponse(endDate, startDate);
    }
};

export const convertSecondsToHms = (sec: number) => {
    let h = Math.floor(sec / 3600);
    let m = Math.floor(sec % 3600 / 60);
    let s = Math.floor(sec % 3600 % 60);

    let hDisplay = h > 9 ? h : `0${h}`;
    let mDisplay = m > 9 ? m : `0${m}`;
    let sDisplay = s > 9 ? s : `0${s}`;
    return `${hDisplay}:${mDisplay}:${sDisplay}`;
};

export const convertSecondsToMs = (sec: number) => {
    let m = Math.floor(sec % 3600 / 60);
    let s = Math.floor(sec % 3600 % 60);

    let mDisplay = m > 9 ? m : `0${m}`;
    let sDisplay = s > 9 ? s : `0${s}`;
    return `${mDisplay}:${sDisplay}`;
};

export const ngbDateToUtcDate = (ngbDate: NgbDate): Date => {
    return new Date(Date.UTC(ngbDate.year, ngbDate.month - 1, ngbDate.day));
};

// Use 1 for January, 2 for February, etc.
export const daysInMonth = (year: number, month: number) => {
    return new Date(year, month, 0).getDate();
};

export const convertSecondsToMinutes = (seconds: number): number => {
    const SECONDS_60 = 60;
    return Math.floor(seconds / SECONDS_60);
};

export const generateDates = (startDate: Date, endDate: Date): Date[] => {
    let arrayOfDates = [];
    const numOfDays = differenceInDays(endDate, startDate);
    for (let i = 0; i < numOfDays; i++) {
        arrayOfDates.push(addDays(startOfDay(startDate), i + 1));
    }
    return arrayOfDates;
};

export const generateTimes = (hourStep: number = 1, minuteStep: number = 30): NgbTimeStruct[] => {
    const second = 60;
    const hour = 24;
    return reduce(range(0, Math.ceil(hour / hourStep)), (result, hour) => {
        range(0, Math.ceil(second / minuteStep))
            .forEach((second) => {
                result.push({
                    hour: hour * hourStep,
                    minute: second * minuteStep
                });
            });
        return result;
    }, []);
};

export const getTimeDisplay = (time: number): string => {
    const padding = 2;
    return time.toString().padStart(padding, "0");
};

