import { SessionCallTypeId } from "./session-call-type";

export enum SessionCallChannel {
    SKYPE = "skype",
    ZOOM = "zoom",
    PHONE = "phone",
    WEB_RTC = "webrtc",
    CHAT = "chat",
    CHAT_WIZARD = "chatWizard",
    CHAT_WIZARD_MIMI = "chatWizardMimi",
    TEAMS = "teams",
    AGORA = "agora"
}


export const isCallChannelSkype = (callChannel: string): boolean => {
    return callChannel == SessionCallChannel.SKYPE;
};

export const isCallChannelZoom = (callChannel: string): boolean => {
    return callChannel == SessionCallChannel.ZOOM;
};

export const isCallChannelPhone = (callChannel: string): boolean => {
    return callChannel == SessionCallChannel.PHONE;
};

export const isCallChannelWebrtc = (callChannel: string): boolean => {
    return callChannel?.toLowerCase() == SessionCallChannel.WEB_RTC;
};

export const isCallChannelChatWizard = (callChannel: string): boolean => {
    return callChannel == SessionCallChannel.CHAT_WIZARD;
};

export const isCallChannelChatWizardMimi = (callChannel: string): boolean => {
    return callChannel == SessionCallChannel.CHAT_WIZARD_MIMI;
};

export const isCallChannelMicrosoftTeams = (callChannel: string): boolean => {
    return callChannel == SessionCallChannel.TEAMS;
};

export const isCallChannelAgora = (callChannel: string): boolean => {
    return callChannel == SessionCallChannel.AGORA;
};

export const getChannelBySessionCallTypeId = (sessionCallTypeId: number): SessionCallChannel | undefined => {
    switch (sessionCallTypeId) {
        case SessionCallTypeId.SKYPE:
            return SessionCallChannel.SKYPE;
        case SessionCallTypeId.ZOOM:
            return SessionCallChannel.ZOOM;
        case SessionCallTypeId.PHONE:
            return SessionCallChannel.PHONE;
        case SessionCallTypeId.WEB_RTC:
            return SessionCallChannel.WEB_RTC;
        case SessionCallTypeId.CHAT_WIZARD:
        case SessionCallTypeId.CHAT_WIZARD_MIMI:
            return SessionCallChannel.CHAT_WIZARD_MIMI;
        case SessionCallTypeId.AGORA:
            return SessionCallChannel.AGORA;
        default:
            return undefined;
    }
};

