import { ROUTE_PARTNER_BERLITZ } from "../../../routes/routes.partners";

import { PartnerConfig } from "./partner-config";

export const BERLITZ_CONFIG: PartnerConfig = {
    route: ROUTE_PARTNER_BERLITZ,
    styleClassName: "partner-berlitz",
    disableFooterToggling: true,
    // @TODO: Fix accountRegistrationTagId here
    //accountRegistrationTagId: 59,
    courseIds: [
        102, 297, 1855, 1856,
        79, 94, 135, 1851,
        88, 203, 1963, 2675,
        29, 35, 134, 183,
        25, 41, 80, 1233,
        34, 81, 122, 2672,
        1351, 1385, 1939, 3479
    ],
    levelSelectorEnabled: true
};

export const PARTNER_BERLITZ_CUSTOM_PAGE_SIZE = 28;
