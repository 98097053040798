import { Injectable } from "@angular/core";
import { ConnectionFactoryService, getHeaderDataFromResponse } from "../../core/connection-factory.service";
import { Observable } from "rxjs";
import { MyEnglish } from "../types/my-english";
import { VocabAssessmentActivity } from "../types/content/activity";
import { map as rxJsMap, map } from "rxjs/operators";
import { BrowseSettings } from "../content/browse.settings";
import { Course } from "../types/course";
import { CourseV1 } from "../types/course-v1";
import { StorageCache } from "../../core/storage-cache";
import { ApiResponse } from "../types/api-response";
import { SessionV4 } from "../types/tutor/session-v4";
import { CourseV3Response } from "../types/bridge/course";

@Injectable({providedIn: "root"})
export class MyEnglishModelService {

    constructor(private connection: ConnectionFactoryService) {
    }

    getList(parameters: object): Observable<MyEnglish[]> {
        return this.connection
            .service("bridge")
            .setPath("/commerce/my/english")
            .get(parameters);
    }

    getDialogCount(browseSettings: BrowseSettings): Observable<number> {
        const params = {
            difficulty: browseSettings.difficulty,
            goalID: browseSettings.goalID,
            progress: browseSettings.progressFilter,
            classID: browseSettings.groupId ? undefined : browseSettings.classId,
            groupID: browseSettings.groupId ? browseSettings.groupId : undefined,
            filter: browseSettings.filter ? browseSettings.filter : undefined
        };

        return this.connection
            .service("bridge")
            .setPath("/commerce/my/english/video/count")
            .get(params, undefined, ConnectionFactoryService.SERVICE_VERSION.v2)
            .pipe(
                map((response) => {
                    if (!response || response.status) {
                        return 0;
                    }
                    return response;
                })
            );
    }

    getDialogs(browseSettings: BrowseSettings, page: number): Observable<number[]> {
        const params = {
            accountID: browseSettings.accountID,
            difficulty: browseSettings.difficulty,
            goalID: browseSettings.goalID,
            progress: browseSettings.progressFilter,
            pageSize: browseSettings.pageSize,
            sortBy: browseSettings.sortBy,
            classID: browseSettings.groupId ? undefined : browseSettings.classId,
            groupID: browseSettings.groupId ? browseSettings.groupId : undefined,
            page: page,
            filter: browseSettings.filter ? browseSettings.filter : undefined
        };

        return this.connection
            .service("bridge")
            .setPath("/commerce/my/english/video")
            .get(params, undefined, ConnectionFactoryService.SERVICE_VERSION.v2)
            .pipe(
                map((response) => {
                    if (!response || response.status) {
                        return [];
                    }
                    return response;
                })
            );
    }

    getCourseCount(browseSettings: BrowseSettings): Observable<number> {
        const params = {
            difficulty: browseSettings.difficulty,
            goalID: browseSettings.goalID,
            progress: browseSettings.progressFilter,
            classID: browseSettings.groupId ? undefined : browseSettings.classId,
            groupID: browseSettings.groupId ? browseSettings.groupId : undefined,
            pageSize: browseSettings.pageSize,
            includeOnboarding: browseSettings.includeOnboarding
        };

        return this.connection
            .service("bridge")
            .setPath("/commerce/my/english/course/count")
            .get(params, undefined, ConnectionFactoryService.SERVICE_VERSION.v2)
            .pipe(
                map((response) => {
                    if (!response || response.status) {
                        return 0;
                    }
                    return response;
                })
            );
    }

    getCourses(browseSettings: BrowseSettings, page: number): Observable<number[]> {
        const params = {
            difficulty: browseSettings.difficulty,
            goalID: browseSettings.goalID,
            progress: browseSettings.progressFilter,
            pageSize: browseSettings.pageSize,
            sortBy: browseSettings.sortBy,
            classID: browseSettings.groupId ? undefined : browseSettings.classId,
            groupID: browseSettings.groupId ? browseSettings.groupId : undefined,
            page: page,
            includeOnboarding: browseSettings.includeOnboarding
        };

        return this.connection
            .service("bridge")
            .setPath("/commerce/my/english/course")
            .get(params, undefined, ConnectionFactoryService.SERVICE_VERSION.v2)
            .pipe(
                map((response) => {
                    if (!response || response.status) {
                        return [];
                    }
                    return response;
                })
            );
    }

    getCoursesV3(browseSettings: BrowseSettings, page: number, accountId?: number): Observable<CourseV3Response> {
        const params = {
            accountId: accountId,
            difficulty: browseSettings.difficulty,
            goalID: browseSettings.goalID,
            progress: browseSettings.progressFilter,
            pageSize: browseSettings.pageSize,
            sortBy: browseSettings.sortBy,
            classID: browseSettings.groupId ? undefined : browseSettings.classId,
            groupID: browseSettings.groupId ? browseSettings.groupId : undefined,
            page: page,
            includeOnboarding: browseSettings.includeOnboarding
        };

        return this.connection
            .service("bridge")
            .setPath("/commerce/my/english/course")
            .get(params, undefined, ConnectionFactoryService.SERVICE_VERSION.v3);
    }

    getActivities(browseSettings: BrowseSettings, page: number): Observable<VocabAssessmentActivity[]> {
        const params = {
            difficulty: browseSettings.difficulty,
            goalID: browseSettings.goalID,
            progress: browseSettings.progressFilter,
            pageSize: browseSettings.pageSize,
            sortBy: browseSettings.sortBy,
            classID: browseSettings.groupId ? undefined : browseSettings.classId,
            groupID: browseSettings.groupId ? browseSettings.groupId : undefined,
            page: page
        };

        return this.connection
            .service("bridge")
            .setPath("/commerce/my/english/activity")
            .get(params)
            .pipe(
                map((response) => {
                    if (!response || response.status) {
                        return [];
                    }
                    return response;
                })
            );
    }

    getActivitiesCount(browseSettings: BrowseSettings): Observable<number> {
        const params = {
            difficulty: browseSettings.difficulty,
            goalID: browseSettings.goalID,
            progress: browseSettings.progressFilter,
            classID: browseSettings.groupId ? undefined : browseSettings.classId,
            groupID: browseSettings.groupId ? browseSettings.groupId : undefined,
            pageSize: browseSettings.pageSize
        };

        return this.connection
            .service("bridge")
            .setPath("/commerce/my/english/activity/count")
            .get(params)
            .pipe(
                map((response) => {
                    if (!response || response.status) {
                        return 0;
                    }
                    return response;
                })
            );
    }
}

