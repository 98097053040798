export const EVENT_TYPE_CLICK = "click";

export const NOTIFICATION_PERMISSION_DENIED = "denied";
export const NOTIFICATION_PERMISSION_GRANTED = "granted";

export type Permission = "denied" | "granted" | "default";

export interface PushNotificationResult {
    notification?: any;
    event?: any;
}

export interface PushNotification {
    body?: string;
    icon?: string;
    tag?: string;
    data?: any;
    renotify?: boolean;
    silent?: boolean;
    sound?: string;
    noscreen?: boolean;
    sticky?: boolean;
    dir?: string;
    lang?: string;
    vibrate?: number[];
    requireInteraction?: boolean;
}
