import { ROUTE_PARTNER_VINUNI } from "../../../routes/routes.partners";
import { ROUTE_MY_ENGLISH } from "../../../routes/routes";
import { PartnerConfig } from "./partner-config";

export const VINUNI_CONFIG: PartnerConfig = {
    route: ROUTE_PARTNER_VINUNI,
    styleClassName: "partner-vinuni",
    accessCodeLinkOnSkip: ROUTE_MY_ENGLISH,
    accessCodeLinkOnSuccess: ROUTE_MY_ENGLISH,
    accessCodeRedirectionDisabled: false,
    accessCodeImageLink: require("global-assets/partners/partner-vinuni-background.webp")
};
