import { Injectable } from "@angular/core";
import { ConnectionFactoryService } from "../../core/connection-factory.service";
import { Observable } from "rxjs";
import { AccountDailyGoal, DailyGoal } from "../types/daily-goal";
import { MemoryCache } from "../../core/memory-cache";

@Injectable()
export class DailyGoalModelService {
    private dailyGoalsCache = new MemoryCache<DailyGoal[]>();

    constructor(private connection: ConnectionFactoryService) {
    }

    getDailyGoals(language?: string): Observable<DailyGoal[]> {
        return this.dailyGoalsCache.getCache({ language: language || "en" }, () => {
            return this.connection
                .service("bridge")
                .setPath("/identity/dailygoal")
                .get({ language });
        });
    }

    getDailyGoal(accountId: number): Observable<AccountDailyGoal> {
        return this.connection
            .service("bridge")
            .setPath(`/identity/dailygoal/account/${accountId}`)
            .get();
    }

    deleteDailyGoal(accountId: number): Observable<AccountDailyGoal> {
        return this.connection
            .service("bridge")
            .setPath(`/identity/dailygoal/account/${accountId}`)
            .delete();
    }

    updateDailyGoal(accountId: number, dailyGoalId: number): Observable<AccountDailyGoal> {
        return this.connection
            .service("bridge")
            .setPath(`/identity/dailygoal/account/${accountId}/dailygoal/${dailyGoalId}`)
            .put();
    }

}
