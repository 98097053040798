import { LOCALE_ID, StaticProvider, TRANSLATIONS, TRANSLATIONS_FORMAT } from "@angular/core";
import { LANGUAGE_DEFAULT } from "./core/locale";

declare var PRODUCTION: boolean;

export default (): Promise<StaticProvider[]> => {
    if (PRODUCTION) {
        return Promise.resolve([]);
    }

    if (!PRODUCTION) {
        let getTranslationsFile = (locale): Promise<any> => {
            return new Promise((resolve) => {
                require.ensure([], () => {
                    const xtbFile = require(`../locale/messages.${locale}.xtb`);
                    resolve(xtbFile);
                });
            });
        };

        let getConfigLang = () => {
            if (typeof (<any>window).ECSDK_CONFIG_LANG !== "undefined") {
                return (<any>window).ECSDK_CONFIG_LANG;
            }
            if (typeof (<any>window).CONFIG_LANG !== "undefined") {
                return (<any>window).CONFIG_LANG;
            }
            return LANGUAGE_DEFAULT;
        };

        // Get the locale id from the global
        const locale = getConfigLang();
        // return no providers if fail to get translation file for locale
        const noProviders: StaticProvider[] = [];
        // No locale or U.S. English: no translation providers
        if (!locale || locale === "en-US" || locale == LANGUAGE_DEFAULT) {
            return Promise.resolve(noProviders);
        }

        return getTranslationsFile(locale)
            .then((translations) => {
                return [
                    {provide: TRANSLATIONS_FORMAT, useValue: "xtb"},
                    {provide: TRANSLATIONS, useValue: translations?.default ?? ""},
                    {provide: LOCALE_ID, useValue: locale}
                ];
            })
            .catch((e) => {
                console.error("error in loading translations", e);
                return noProviders;
            });
    }
};
