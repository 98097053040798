import { ROUTE_PARTNER_TELELANGUE } from "../../../routes/routes.partners";

import { PartnerConfig } from "./partner-config";

export const TELELANGUE_CONFIG: PartnerConfig = {
    route: ROUTE_PARTNER_TELELANGUE,
    styleClassName: "partner-telelangue",
    disableBrowseCoursesLink: true,
    disableAnonVideoAccess: true, // no video access without logging in
    showBannerComponents: true, // banner components (hero-image and WLS description section)
    disableAnonCourseAccess: true,
    courseIds: [
        5114, 5115, 5102, 5117,
        5118, 5119, 5120, 5121,
        5122, 5130, 5131, 5132,
        5133, 5134, 5135, 5136,
        5137, 5138, 5139, 5140,
        5141, 5142, 5143, 5144,
        5145, 5146, 5147, 5148
    ],
    languagesAllowed: ["en", "fr"],
    levelSelectorEnabled: true,
    partnerTermsAndConditionsLink: "https://www.telelangue.com/mentions-legales/",
    partnerPrivacyPolicyLink: "https://www.telelangue.com/politique-de-confidentialite/",
    disableFooter: true
};

export const PARTNER_TELELANGUE_CUSTOM_PAGE_SIZE = 28;
