import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { CommonModule } from "@angular/common";

type LoadingRingSize = "small" | "medium" | "large";

@Component({
    standalone: true,
    imports: [CommonModule],
    selector: "ec-loading-ring",
    templateUrl: "loading-ring.component.html",
    styleUrls: ["loading-ring.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoadingRingComponent {
    @Input() themeClass: string;
    @Input() size: LoadingRingSize = "medium";

    getThemeClass(): string {
        return `border-${this.themeClass}`;
    }

    getSizeClass(): string {
        return this.size;
    }

    getSpinnerCircles(): any[] {
        return new Array(3);
    }
}
