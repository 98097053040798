import { Logger } from "../../../../core/logger/logger";
import * as EventValidators from "../validator/index";
import { ReportCardEvent } from "../../event-factory.service";
import { Activity } from "../../../../model/types/content/activity";
import { DateUtil } from "../../../../core/date-util";
import { clone, isEmpty } from "lodash-es";

export interface EventTypeInfo {
    eventType: string;
    validator: EventValidators.Validator;
}

export abstract class AbstractEventFactory {
    static DEFINED_ACTIVITY_IDS = [
        Activity.ACTIVITY_CLIPLIST_NAMED_WATCH,
        Activity.ACTIVITY_CLIPLIST_NAMED_LEARN,
        Activity.ACTIVITY_CLIPLIST_NAMED_SPEAK,
        Activity.ACTIVITY_CLIPLIST_PRON_WATCH,
        Activity.ACTIVITY_CLIPLIST_PRON_SPEAK,
        Activity.ACTIVITY_CLIPLIST_VOCAB_WATCH,
        Activity.ACTIVITY_CLIPLIST_VOCAB_LEARN,
        Activity.ACTIVITY_CLIPLIST_VOCAB_SPEAK,
        Activity.ACTIVITY_TYPE_ID_WATCH,
        Activity.ACTIVITY_TYPE_ID_LEARN,
        Activity.ACTIVITY_TYPE_ID_SPEAK,
        Activity.ACTIVITY_TYPE_ID_QUIZ,
        Activity.ACTIVITY_TYPE_ID_COURSE_QUIZ,
        Activity.ACTIVITY_TYPE_ID_QUIZ_WORD,
        Activity.ACTIVITY_CLIPLIST_DYNAMIC
    ];

    protected eventTypeLookup: Record<number, EventTypeInfo> = {};
    protected logger = new Logger();
    protected sessionKey: string;

    constructor(protected accountId: number = 0) {
    }

    setSessionKey(timestamp: number): void {
        this.sessionKey = [this.accountId, timestamp].join("");
    }

    protected generateSessionId(activityId: number, date: Date): string {
        if (!this.sessionKey) {
            this.setSessionKey(date.getTime());
        }
        return [activityId, this.sessionKey].join("");
    }

    protected getEventValidator(activityTypeId?: number): EventValidators.Validator | undefined {
        if (!activityTypeId) {
            return undefined;
        }
        if (activityTypeId in this.eventTypeLookup) {
            return this.eventTypeLookup[activityTypeId].validator;
        }

        this.logger.error("eventFactory", "invalid activity type ID");
        return undefined;
    }

    protected getEventType(activityTypeId?: number): string | undefined {
        if (!activityTypeId) {
            return undefined;
        }
        if (activityTypeId in this.eventTypeLookup) {
            return this.eventTypeLookup[activityTypeId].eventType;
        }

        this.logger.error("eventFactory", "invalid activity type ID");
        return undefined;
    }

    protected getValidator(eventParams): EventValidators.Validator {
        if (!eventParams.activityTypeID) {
            throw "missing activityTypeID";
        }

        let eventValidator = this.getEventValidator(eventParams.activityTypeID);
        if (!eventValidator) {
            throw "no validator found for activityID: " + eventParams.activityTypeID;
        }
        return eventValidator;
    }

    protected isValid(eventParams): boolean {
        if (isEmpty(eventParams)) {
            this.logger.error("eventFactory", "invalid event param type", 0, eventParams);
            return false;
        }

        try {
            let eventValidator = this.getValidator(eventParams);
            eventValidator.validate(eventParams);
        } catch (e) {
            this.logger.error("eventFactory", e, 0, eventParams);
            return false;
        }

        return true;
    }

    createEvent(eventParams: any): ReportCardEvent | undefined {
        if (isEmpty(eventParams)) {
            return undefined;
        }

        let currentTime = new Date();
        let params = clone(eventParams);

        params.type = this.getEventType(eventParams.activityTypeID) || eventParams.type;
        params.eventTime = DateUtil.getAdjustedBsonDate(undefined, true);
        params.accountID = this.accountId;
        params.activitySessionID = this.generateSessionId(eventParams.activityID, currentTime);

        if (!this.isValid(params)) {
            return undefined;
        }

        return params;
    }
}
