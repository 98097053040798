import { Injectable } from "@angular/core";
import { ConnectionFactoryService, getHeaderDataFromResponse } from "../../core/connection-factory.service";
import { Observable, of } from "rxjs";
import { StorageCache } from "../../core/storage-cache";
import { ProgressQueueService } from "../../common-app/progress-app/progress-queue.service";
import { ClassGoalSummary } from "../types/class-goal-summary";
import {
    ClassReport,
    ClassReportAccountSentence,
    ClassReportAverageGrades,
    ClassReportAverageWeekly,
    ClassReportComprehensionQuiz,
    ClassReportCourseUnitCount,
    ClassReportCourseUnitProgress
} from "../types/class-report";
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { DateUtil } from "../../core/date-util";
import { AssessmentProgress } from "../types/reportcard/assessment-progress";
import { classReportServicesRetryStrategy } from "../../core/retry-helper";
import { ClassTestExamReport } from "../types/reportcard/class-test-exam-report";
import { Logger } from "../../core/logger/logger";
import { map, mergeMap, retryWhen } from "rxjs/operators";
import { ClassBandScore } from "../types/band-score";
import { endOfDay } from "date-fns";
import { assign } from "lodash-es";
import { ApiResponse } from "../types/api-response";

declare var window: any;

@Injectable()
export class ClassReportModelService {
    static readonly REPORT_MIN_DATE = "2016-09-01";
    static readonly MAX_MONTH_RANGE: number = 12;

    private reportCache = new StorageCache<ClassReport[]>("ClassReportCache");
    private reportCacheCounts = new StorageCache<number>("ClassReportCountCache");
    private reportCacheAverageGrades = new StorageCache<ClassReportAverageGrades>("ClassReportAverageGrades");
    private goalSummaryCache = new StorageCache<ClassGoalSummary>("GoalSummaryCache");
    private assessmentProgressCache = new StorageCache<AssessmentProgress[]>("AssessmentProgressCache");
    private assessmentProgressCountCache = new StorageCache<number>("AssessmentProgressCountCache");
    private comprehensionQuizProgressCache = new StorageCache<ClassReportComprehensionQuiz>("ComprehensionQuizProgressCache");
    private wordListProgressCache = new StorageCache<ClassReport[]>("WordListProgressCache");
    private wordListCountsCache = new StorageCache<number>("WordListCountsCache");
    private levelTestScoresCache = new StorageCache<ClassBandScore>("LevelTestScoreCache");
    private logger = new Logger();

    constructor(private progressQueueService: ProgressQueueService,
                private connection: ConnectionFactoryService) {
        window.classModelService = {
            service: this
        };

        this.progressQueueService.subscribe(ProgressQueueService.EVENT_ADDED, () => {
            this.reportCache.destroy();
            this.reportCacheCounts.destroy();
            this.goalSummaryCache.destroy();
        });
    }

    getRawReport(classId: number, options: object = {}, useDefaultRetry: boolean = true): Observable<ClassReport[]> {
        let report$ = this.connection
            .service("reportcard")
            .setPath("/report/class/" + classId.toString())
            .get(options, undefined, ConnectionFactoryService.SERVICE_VERSION.v2);
        if (useDefaultRetry) {
            report$.pipe(
                retryWhen(classReportServicesRetryStrategy())
            );
        }
        return report$;
    }

    getReport(classId: number,
              options: object = {},
              expiration: number = ConnectionFactoryService.CACHE_LIFETIME.progress,
              useDefaultRetry: boolean = true): Observable<ClassReport[]> {

        return this.reportCache.getCache(assign({}, options, {classID: classId}), () => {
            return this.getRawReport(classId, options, useDefaultRetry);
        }, expiration);
    }

    getRawReportCount(classId: number, options: object = {}, useDefaultRetry: boolean = true): Observable<number> {
        let reportCount$ = this.connection
            .service("reportcard")
            .setPath(`/report/class/${classId}/count`)
            .get(options, undefined, ConnectionFactoryService.SERVICE_VERSION.v2);
        if (useDefaultRetry) {
            return reportCount$.pipe(
                retryWhen(classReportServicesRetryStrategy())
            );
        }
        return reportCount$;
    }

    getReportCount(classId: number,
                   options: object = {},
                   expiration: number = ConnectionFactoryService.CACHE_LIFETIME.reportcard,
                   useDefaultRetry: boolean = true): Observable<number> {

        return this.reportCacheCounts.getCache(assign({}, options, {classID: classId}), () => {
            return this.getRawReportCount(classId, options, useDefaultRetry);
        }, expiration);
    }

    getAverageGrades(classId: number, params: object, accountIds: number[], expiration: number = ConnectionFactoryService.CACHE_LIFETIME.reportcard): Observable<ClassReportAverageGrades> {
        let cacheKey = {...params, classId, accountIds: accountIds.join(",")};
        return this.reportCacheAverageGrades.getCache(
            cacheKey,
            () => this.getRawAverageGrades(classId, params, accountIds),
            expiration
        );
    }

    getRawAverageGrades(classId: number, params: object, accountIds: number[]): Observable<ClassReportAverageGrades> {
        return this.connection
            .service("reportcard")
            .setPath(`/report/class/${classId}/accounts/averagegrade`)
            .post(params, accountIds, ConnectionFactoryService.SERVICE_VERSION.v1);
    }

    getRawClassRanking(classId: number, options: object = {}): Observable<any> {
        assign(options, {classId: classId}, {});
        return this.connection
            .service("base")
            .setPath("/classranking")
            .get(options);
    }

    getClassRanking(classId: number,
                    options: object = {},
                    expiration: number = ConnectionFactoryService.CACHE_LIFETIME.reportcard): Observable<ClassReport[]> {
        assign(options, {classId: classId}, {});

        return this.reportCache.getCache(options, () => {
            return this.getRawClassRanking(classId, options);
        }, expiration);
    }

    getRawGoalSummary(classId: number, options: object = {}, useDefaultRetry: boolean = true): Observable<ClassGoalSummary> {
        let reportGoal$ = this.connection
            .service("reportcard")
            .setPath("/report/class/" + classId.toString() + "/goalsummary")
            .get(options, undefined, ConnectionFactoryService.SERVICE_VERSION.v2);
        if (useDefaultRetry) {
            return reportGoal$.pipe(
                retryWhen(classReportServicesRetryStrategy())
            );
        }
        return reportGoal$;
    }

    getGoalSummary(classId: number,
                   accountId: number,
                   options: object = {},
                   expiration: number = ConnectionFactoryService.CACHE_LIFETIME.reportcard,
                   useDefaultRetry: boolean = true): Observable<ClassGoalSummary> {
        assign(options, {classId: classId, accountId: accountId}, {});
        return this.goalSummaryCache.getCache(options, () => {
            return this.getRawGoalSummary(classId, options, useDefaultRetry);
        }, expiration);
    }

    // @TODO Implement caching for course report
    getCourseReport(classId: number,
                    courseId: number,
                    options: object = {},
                    expiration: number = ConnectionFactoryService.CACHE_LIFETIME.reportcard): Observable<ClassReport[]> {
        return this.reportCache.getCache({...options, courseReport: "courseReport"}, () => {
            return this.getRawCourseReport(classId, courseId, options).pipe(
                map((response) => {
                    return response?.data || [];
                })
            );
        }, expiration);
    }

    getRawCourseReport(classId: number, courseId: number, options: object = {}): Observable<ApiResponse<ClassReport[]>> {
        return this.connection
            .service("reportcard")
            .setPath(`/report/class/${classId.toString()}/course/${courseId.toString()}`)
            .setFullResponse(true)
            .get(options, undefined, ConnectionFactoryService.SERVICE_VERSION.v2, { observe: "response" })
            .pipe(
                map((response) => ({
                    data: response.body,
                    status: response.status,
                    headers: getHeaderDataFromResponse(response)
                }) as ApiResponse<ClassReport[]>)
            );
    }

    getRawCourseUnitReport(courseId: number, options: object = {}): Observable<ApiResponse<ClassReportCourseUnitCount[]>> {
        return this.connection
            .service("reportcard")
            .setPath(`/report/course/${courseId.toString()}/unit`)
            .setFullResponse(true)
            .get(options, undefined, ConnectionFactoryService.SERVICE_VERSION.v2, { observe: "response" })
            .pipe(
                map((response) => ({
                    data: response.body,
                    status: response.status,
                    headers: getHeaderDataFromResponse(response)
                }) as ApiResponse<ClassReportCourseUnitCount[]>)
            );
    }

    getRawCourseUnitProgressReport(courseId: number, options: object = {}): Observable<ApiResponse<ClassReportCourseUnitProgress[]>> {
        return this.connection
            .service("reportcard")
            .setPath(`/report/course/${courseId.toString()}/progress/v1`)
            .setFullResponse(true)
            .get(options, undefined, ConnectionFactoryService.SERVICE_VERSION.v2, { observe: "response" })
            .pipe(
                map((response) => ({
                    data: response.body,
                    status: response.status,
                    headers: getHeaderDataFromResponse(response)
                }) as ApiResponse<ClassReportCourseUnitProgress[]>)
            );
    }

    getRawAssessmentProgressByActivityId(classId: number, activityId: number, params?: object): Observable<AssessmentProgress[]> {
        return this.connection
            .service("reportcard")
            .setPath(`/report/class/${classId}/activity/${activityId}/assessment`)
            .get(params);
    }

    getAssessmentProgressByActivityId(
        classId: number,
        activityId: number,
        params?: { [key: string]: any },
        expiration: number = ConnectionFactoryService.CACHE_LIFETIME.reportcard): Observable<AssessmentProgress[]> {
        const options = {classId, activityId, page: params.page, pageSize: params.pageSize};
        return this.assessmentProgressCache.getCache(options, () => {
            return this.getRawAssessmentProgressByActivityId(classId, activityId, params);
        }, expiration);
    }

    getRawAssessmentProgressCountByActivityId(classId: number, activityId: number): Observable<number> {
        return this.connection
            .service("reportcard")
            .setPath(`/report/class/${classId}/activity/${activityId}/assessment/count`)
            .get();
    }

    getAssessmentProgressCountByActivityId(
        classId: number,
        activityId: number,
        expiration: number = ConnectionFactoryService.CACHE_LIFETIME.reportcard): Observable<number> {
        const options = {classId: classId, activityId: activityId};
        return this.assessmentProgressCountCache.getCache(options, () => {
            return this.getRawAssessmentProgressCountByActivityId(classId, activityId);
        }, expiration);
    }

    getClassTestReportByTestId(classId: number, classTestId: number, params: object = {}): Observable<ClassTestExamReport | undefined> {
        if (!classId || !classTestId) {
            return of(undefined);
        }
        return this.connection
            .service("reportcard")
            .setPath(`/report/quiz/class/${classId}/test/${classTestId}`)
            .get(params);
    }

    getComprehensionQuizProgress(
        classId: number,
        params: object = {},
        expiration: number = ConnectionFactoryService.CACHE_LIFETIME.reportcard
    ): Observable<ClassReportComprehensionQuiz> {
        const options = assign({}, {classId: classId}, params);
        return this.comprehensionQuizProgressCache.getCache(options, () => {
            return this.getRawComprehensionQuizProgress(classId, params);
        }, expiration);
    }

    getRawComprehensionQuizProgress(classId: number, params: object = {}): Observable<ClassReportComprehensionQuiz> {
        return this.connection
            .service("reportcard")
            .setPath(`/report/class/${classId}/comprehensionquiz`)
            .get(params, undefined, ConnectionFactoryService.SERVICE_VERSION.v1)
            .pipe(
                retryWhen(classReportServicesRetryStrategy())
            );
    }

    getWordListReport(
        classId: number,
        params: object = {},
        expiration: number = ConnectionFactoryService.CACHE_LIFETIME.reportcard
    ): Observable<ClassReport[]> {
        const options = assign({}, {classId: classId}, params);
        return this.wordListProgressCache.getCache(options, () => {
            return this.getRawWordListReport(classId, params);
        }, expiration);
    }

    getRawWordListReport(classId: number, params: object = {}): Observable<ClassReport[]> {
        return this.connection
            .service("reportcard")
            .setPath(`/report/class/${classId}/wordlist`)
            .get(params, undefined, ConnectionFactoryService.SERVICE_VERSION.v1)
            .pipe(
                retryWhen(classReportServicesRetryStrategy())
            );
    }

    getWordListCounts(classId: number,
                      options: object = {},
                      expiration: number = ConnectionFactoryService.CACHE_LIFETIME.reportcard): Observable<number> {
        return this.wordListCountsCache.getCache(assign({}, options, {classID: classId}), () => {
            return this.getRawWordListCounts(classId, options);
        }, expiration);
    }

    getRawWordListCounts(classId: number, options: object = {}): Observable<number> {
        return this.connection
            .service("reportcard")
            .setPath(`/report/class/${classId}/wordlist/count`)
            .get(options, undefined, ConnectionFactoryService.SERVICE_VERSION.v2)
            .pipe(
                retryWhen(classReportServicesRetryStrategy(6))
            );
    }

    getWordListProgressReport(
        classId: number,
        params: object = {},
        accountIds: number[],
        expiration: number = ConnectionFactoryService.CACHE_LIFETIME.reportcard
    ): Observable<ClassReport[]> {
        const options = assign({}, {classId: classId, accountIds: accountIds.join()}, params);
        return this.wordListProgressCache.getCache(options, () => {
            return this.getRawWordListProgressReport(classId, params, accountIds);
        }, expiration);
    }

    getRawWordListProgressReport(classId: number, params: object = {}, accountIds: number[]): Observable<ClassReport[]> {
        return this.connection
            .service("reportcard")
            .setPath(`/report/class/${classId}/wordlist/progress`)
            .post(params, accountIds, ConnectionFactoryService.SERVICE_VERSION.v1)
            .pipe(
                retryWhen(classReportServicesRetryStrategy())
            );
    }

    getLevelTestScores(classId: number, params: object = {}, expiration: number = ConnectionFactoryService.CACHE_LIFETIME.reportcard): Observable<ClassBandScore> {
        const options = assign({}, {classId: classId}, params);
        return this.levelTestScoresCache.getCache(options, () => {
            return this.getRawLevelTestScores(classId, params);
        }, expiration);
    }

    getRawLevelTestScores(classId: number, params: object = {}): Observable<ClassBandScore> {
        return this.connection
            .service("reportcard")
            .setPath(`/report/class/${classId}/leveltest/score`)
            .get(params, undefined, ConnectionFactoryService.SERVICE_VERSION.v1)
            .pipe(
                retryWhen(classReportServicesRetryStrategy())
            );
    }

    getRawReportAverageWeekly(classId: number, options: object = {}, useDefaultRetry: boolean = true): Observable<ClassReportAverageWeekly[]> {
        let reportWeekly$ = this.connection
            .service("reportcard")
            .setPath(`/report/class/${classId}/periodic`)
            .get(options, undefined, ConnectionFactoryService.SERVICE_VERSION.v2);
        if (useDefaultRetry) {
            return reportWeekly$.pipe(
                retryWhen(classReportServicesRetryStrategy())
            );
        }
        return reportWeekly$;
    }

    getRawReportSentences(classId: number, accountId: number, options: object): Observable<ClassReportAccountSentence[]> {
        return this.connection
            .service("reportcard")
            .setPath(`/report/class/${classId}/sentences/account/${accountId}`)
            .get(options)
            .pipe(
                mergeMap((response) => {
                    return of(response?.accountSentenceProgressList ?? []);
                }),
                retryWhen(classReportServicesRetryStrategy())
            );
    }

    deleteAllReportCache(): void {
        this.reportCache.destroy();
        this.reportCacheCounts.destroy();
        this.reportCacheAverageGrades.destroy();
        this.goalSummaryCache.destroy();
        this.assessmentProgressCache.destroy();
        this.assessmentProgressCountCache.destroy();
        this.wordListProgressCache.destroy();
        this.wordListCountsCache.destroy();
        this.levelTestScoresCache.destroy();
        this.logger.log("Reports cache deleted (All)");
    }

    deleteReportCache(): void {
        this.reportCache.destroy();
    }

    deleteReportCountCache(): void {
        this.reportCacheCounts.destroy();
    }

    deleteGoalSummaryCache(options: object = {}): void {
        this.goalSummaryCache.deleteCache(options);
    }

    static getMinReportingDate(): NgbDateStruct {
        return DateUtil.dateToDateStruct(endOfDay(new Date(ClassReportModelService.REPORT_MIN_DATE)));
    }

}
