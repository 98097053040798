import { Injectable } from "@angular/core";
import { ConnectionFactoryService } from "../../core/connection-factory.service";
import { Observable, of } from "rxjs";
import { finalize } from "rxjs/operators";
import { Feature, FeatureKnob, FeatureKnobVersion } from "../types/feature";
import { ActiveFeature } from "../types/active-feature";
import { MemoryCache } from "../../core/memory-cache";
import { StorageCache } from "../../core/storage-cache";
import { isEmpty, isUndefined } from "lodash-es";

declare var EC_FEATURES;

@Injectable()
export class FeatureModelService {
    private featureAccountCache = new MemoryCache<Feature[]>();
    private featuresCache = new StorageCache<ActiveFeature>("FeatureModelService");
    constructor(private connection: ConnectionFactoryService) {
    }

    getFeatures(): Observable<ActiveFeature> {
        if ((<any>window).EC_FEATURES) {
            return of(EC_FEATURES).pipe(finalize(() => {
                EC_FEATURES = undefined;
            }));
        }

        return this.connection
            .service("base")
            .setPath("/api/features")
            .get();
    }

    getFeaturesStorageCache(): Observable<ActiveFeature> {
        let cacheKey = {
            key: "getFeaturesStorageCache"
        };
        return this.featuresCache.getCache(cacheKey, () => {
            return this.getFeatures();
        }, ConnectionFactoryService.CACHE_LIFETIME.bridge);
    }

    getFeaturesByKey(key: string, activeOnly: boolean = false): Observable<FeatureKnob[]> {
        let endpoint = "/identity/feature/editor";
        let params = {
            page: 0,
            pageSize: 10,
            activeOnly: activeOnly,
            sortBy: "lastModified",
            sortDesc: true,
            featureKey: key
        };

        return this.connection
            .service("bridge")
            .setPath(endpoint)
            .get(params);
    }

    cloneVersion(key: string, currentVersion: string, newVersion: string): Observable<FeatureKnobVersion> {
        let endpoint = "/identity/feature/editor/version";
        let params = {
            key: key,
            newVersionName: newVersion,
            versionNameToClone: currentVersion
        };

        return this.connection
            .service("bridge")
            .setPath(endpoint)
            .post(params);
    }

    updateVersion(feature: FeatureKnobVersion): Observable<FeatureKnobVersion> {
        let endpoint = "/identity/feature/editor/version";

        return this.connection
            .service("bridge")
            .setPath(endpoint)
            .put({}, feature);
    }

    getFeatureByParams(params: object): Observable<Feature[]> {
        if (isUndefined(params) || isEmpty(params)) {
            return of([]);
        }
        return this.connection
            .service("bridge")
            .setPath(`/identity/feature`)
            .get(params);
    }

    getFeatureAccount(accountId: number, params: object = {}): Observable<Feature[]> {
        return this.connection
            .service("bridge")
            .setPath(`/identity/feature/account/${accountId}`)
            .get(params);
    }

    getFeatureAccountMemoryCache(accountId: number, params: object = {}): Observable<Feature[]> {
        const cacheKey = {
            key: "getFeatureAccountMemoryCache",
            accountId: accountId
        };
        return this.featureAccountCache.getCache(cacheKey, () => {
            return this.getFeatureAccount(accountId, params);
        });
    }

}
