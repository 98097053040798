import { NgbDateStruct, NgbTimeStruct } from "@ng-bootstrap/ng-bootstrap";

export function ngbDateToDate(ngbDate: NgbDateStruct, ngbTime?: Partial<NgbTimeStruct>): Date {
    return new Date(
        ngbDate.year,
        ngbDate.month - 1,
        ngbDate.day,
        ngbTime?.hour ?? 0,
        ngbTime?.minute ?? 0,
        ngbTime?.second ?? 0
    );
}
