import { Injectable, NgZone } from "@angular/core";
import * as EventFactory from "./event-factory/factory";
import { ConnectionFactoryService } from "../../core/connection-factory.service";
import { GlobalSettingService } from "../../core/global-setting.service";
import { DateUtil } from "../../core/date-util";
import { padStart } from "lodash-es";
import { catchError, map, tap } from "rxjs/operators";
import { Observable, of } from "rxjs";

export * from "./event-factory/factory";

export interface ReportCardEvent {
    type: string;
    eventTime: Date | string;
    activityTypeID?: number;
    accountID?: number;
    dialogID?: number;
    dialogLineID?: number;
    activitySessionID?: string;
    markAs?: boolean;
    contentId?: number;
    contentTypeId?: number;
    contentFacetId?: number;
    completed?: boolean;
    correct?: boolean | number;
    previouslyEncountered?: boolean;
    activityID?: number;
    dialogId?: number;
}

export interface CompleteActivityEvent extends ReportCardEvent {
    activityID: number;
}

export interface CompleteVocabularyQuizEvent extends CompleteActivityEvent {
    correct: number;
    total: number;
}

export interface DialogLineEvent extends ReportCardEvent {
    dialogLineID: number;
    progress: number;
    sessionTypeID: number;
    sessionLineTimeKey: number;
    lineCount: number;
}

export interface DialogLineWatchEvent extends DialogLineEvent {
    activityID: number;
}

export interface DialogLineWatchCliplistEvent extends DialogLineEvent {
    dialogID: number;
}

export interface DialogLineSpeakEvent extends DialogLineEvent {
    activityID: number;
    sessionTimeKey: string;
    xmlURL: string;
    pointsLine: number;
    pointsTotal: number;
    grade: string;
    score: number;
}

export interface DialogLineSpeakCliplistEvent extends DialogLineEvent {
    dialogID: number;
    sessionTimeKey: string;
    xmlURL: string;
    pointsLine: number;
    pointsTotal: number;
}

export interface StartActivityEvent extends ReportCardEvent {
    activityID: number;
}

export interface StartActivitySpeakEvent extends StartActivityEvent {
    sessionTypeID: number;
    sessionTimeKey: string;
}

export interface WordEvent extends ReportCardEvent {
    word: EventWord;
    activityID?: number;
}

export interface LearnedWordEvent extends WordEvent {
    activityID: number;
}

export interface WordQuestionEvent extends WordEvent {
    exampleDialogLineID: number;
    userResponseTime: number;
}

export interface DialogWordInteractiveEvent extends WordEvent {
    correct?: boolean;
}

export interface TypedWordEvent extends DialogWordInteractiveEvent {
    typedWord: string;
}

export interface QuizzedWordEvent extends DialogWordInteractiveEvent {
    sessionTimeKey: string;
    selectedWord: EventWord;
}

export interface MarkWordEvent extends WordEvent {
    markAs: boolean;
}

export interface EventWord {
    wordHeadID: number;
    wordRootID?: number;
    wordRootDefinitionID?: number;
    wordInstanceID?: number;
    wordID?: number;
    sharedMeaningID?: number;
    wordFamilyID?:number;
}

export const buildSessionTimeKey = () => {
    let date = new Date();

    return padStart(String(date.getFullYear()), 2, "0")
        + padStart(String(date.getUTCMonth() + 1), 2, "0")
        + padStart(String(date.getUTCDate()), 2, "0")
        + padStart(String(date.getUTCHours()), 2, "0")
        + padStart(String(date.getUTCMinutes()), 2, "0")
        + padStart(String(date.getUTCSeconds()), 2, "0")
        + padStart(String(date.getUTCMilliseconds()), 3, "0");
};

export const TYPE_START: string = "start";
export const TYPE_COMPLETE: string = "complete";
export const TYPE_DIALOGLINE: string = "dialog-line";
export const TYPE_WORD: string = "word";
export const TYPE_GENERIC: string = "generic";

@Injectable({
    providedIn: "root"
})
export class EventFactoryService {
    constructor(protected zone: NgZone,
                protected connectionFactory: ConnectionFactoryService,
                protected globalSettingService: GlobalSettingService) {
    }

    private sessionKey: number;

    generateSessionKey(): number {
        this.sessionKey = new Date().getTime();
        return this.sessionKey;
    }

    fetchServerDate(): Observable<number> {
        const serverTimeDifference = DateUtil.getServerTimeDifference();
        if (serverTimeDifference) {
            return of(serverTimeDifference);
        }

        return this.connectionFactory
            .generateServerDate()
            .pipe(
                catchError(() => of(undefined)),
                tap((serverDate) => {
                    if (!serverDate) {
                        return;
                    }
                    let serverTimeDifference = Date.parse(serverDate) - new Date().getTime();
                    DateUtil.setServerTimeDifference(serverTimeDifference);
                }),
                map(() => DateUtil.getServerTimeDifference())
            );
    }

    getFactory(eventType: string, accountId: number = 0): EventFactory.AbstractEventFactory {
        const eventFactoryInstance = this.getInstance(eventType, accountId);
        eventFactoryInstance.setSessionKey(this.sessionKey ?? this.generateSessionKey());

        return eventFactoryInstance;
    }

    protected getInstance(eventType: string, accountId: number = 0): EventFactory.AbstractEventFactory {
        switch (eventType) {
            case TYPE_START:
                return new EventFactory.StartEventFactory(accountId);
            case TYPE_COMPLETE:
                return new EventFactory.CompleteEventFactory(accountId);
            case TYPE_DIALOGLINE:
                return new EventFactory.DialogLineEventFactory(accountId);
            case TYPE_WORD:
                return new EventFactory.WordEventFactory(accountId);
            case TYPE_GENERIC:
            default:
                return new EventFactory.GenericEventFactory(accountId);
        }
    }

    buildSessionTimeKey(): string {
        return buildSessionTimeKey();
    }
}
