export const PARTNER_ABBR_ENGLISHCENTRAL = "EC";
export const PARTNER_ABBR_LANGRICH = "LR";

export class PartnerParams {
    active?: boolean;
    embeddedIframe?: boolean;
    excludePartnerIDs?: string;
    name?: string;
    restrictContent?: boolean;
}

export class Partner {
    active: boolean;
    additive: boolean;
    china: boolean;
    dateCreated: string;
    embeddedIframe: boolean;
    name: string;
    nativeLanguage: string;
    partnerID: number;
    restrictToExclusiveContent: boolean;
    siteLanguage: string;
    isSelected?: boolean;
    jsdkKey?: string;

    static readonly PARTNER_ID_ENGLISHCENTRAL = 2;
    static readonly PARTNER_ID_ENGLISHCENTRAL_ANDROID = 55;
    static readonly PARTNER_ID_ENGLISHCENTRAL_IOS = 56;
    static readonly PARTNER_ID_ENGLISHCENTRAL_VIETNAM = 19600;
    static readonly PARTNER_ID_LANGRICH = 82;
    static readonly PARTNER_ID_VNPT = 158;
    static readonly PARTNER_ID_UNICESUMAR = 148;
    static readonly PARTNER_ID_UOL = 70;
    static readonly PARTNER_ID_TURKISH_AIRLINES = 95;
    static readonly PARTNER_ID_UNIVALI = 99;
    static readonly PARTNER_ID_GLOBED = 9010;
    static readonly PARTNER_ID_GLOBED_2 = 9014;
    static readonly PARTNER_ID_YOELGEVA = 892;
    static readonly PARTNER_ID_YOELGEVA_MOE = 7001;
    static readonly PARTNER_ID_ROCKFELLER = 981;
    static readonly PARTNER_ID_CASATHOMASJEFERSON = 74;
    static readonly PARTNER_ID_SHADOW = 9000;
    static readonly PARTNER_ID_UPF = 7002;
    static readonly PARTNER_ID_BOMJESUS = 5002;
    static readonly PARTNER_ID_CATHO = 97;
    static readonly PARTNER_ID_SOL = 150;
    static readonly PARTNER_ID_TELELANGUE = 9140;
    static readonly PARTNER_ID_TELELANGUE_DIRECT = 15800;
    static readonly PARTNER_ID_AFYA = 9127;
    static readonly PARTNER_ID_SHGM = 9128;
    static readonly PARTNER_ID_BERLITZ = 9150;
    static readonly PARTNER_ID_FGW = 9170;
    static readonly PARTNER_ID_VIDEOENGLISH = 9160;
    static readonly PARTNER_ID_BRAZCUBAS = 9180;
    static readonly PARTNER_ID_BERNOULLI = 9190;
    static readonly PARTNER_ID_DESCOMPLICA = 9401;
    static readonly PARTNER_ID_TAI = 9500;
    static readonly PARTNER_ID_CLASSI = 1026;
    static readonly PARTNER_ID_ESTACIO = 94;
    static readonly PARTNER_ID_VIETTEL = 11400;
    static readonly PARTNER_ID_SEIBIDO = 91;
    static readonly PARTNER_ID_REVELO = 15600;
    static readonly PARTNER_ID_JAL = 15900;
    static readonly PARTNER_ID_NECTAR = 16100;
    static readonly PARTNER_ID_SATSI = 16200;
    static readonly PARTNER_ID_SPICUS = 3;
    static readonly PARTNER_ID_JAPAN = 10000;
    static readonly PARTNER_ID_BEFREE = 16700;
    static readonly PARTNER_ID_GCT = 17100;
    static readonly PARTNER_ID_SEIBIDO_COURSEONLY = 17700;
    static readonly PARTNER_ID_MEXCBT_TEST = 9998;
    static readonly PARTNER_ID_SEEDPR = 17900;
    static readonly PARTNER_ID_IGROUP_VIETNAM = 18100;
    static readonly PARTNER_ID_NORTHSUN = 18200;
    static readonly PARTNER_ID_EDITORA_FUTURA = 18300;
    static readonly PARTNER_ID_VINUNI = 18500;
    static readonly PARTNER_ID_TOPCV = 18700;
    static readonly PARTNER_ID_VLT_SITE = 18800;
    static readonly PARTNER_ID_BAZA_VNPT = 18600;
    static readonly PARTNER_ID_SEMED_SALVADOR = 19400;
    static readonly PARTNER_ID_PARANA = 19500;

    static readonly PARTNER_NAME_ENGLISHCENTRAL = "EnglishCentral";

    static isSOL(partnerId: number): boolean {
        return partnerId == Partner.PARTNER_ID_SOL;
    }

    static isNorthSun(partnerId: number): boolean {
        return partnerId == Partner.PARTNER_ID_NORTHSUN;
    }

    static isTelelangue(partnerId: number): boolean {
        return partnerId == Partner.PARTNER_ID_TELELANGUE;
    }
}
