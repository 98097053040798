import { Injectable } from "@angular/core";
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanActivateChild,
    CanLoad,
    Route,
    Router,
    RouterStateSnapshot,
    UrlSegment
} from "@angular/router";
import { IdentityService } from "../../core/identity.service";

@Injectable({providedIn: "root"})
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
    private redirectionUrl: string[];

    constructor(private router: Router,
                private identityService: IdentityService) {
    }

    getRedirectionuUrl(): string[] {
        return this.redirectionUrl;
    }

    setRedirectionUrl(redirectionUrl: string[]): void {
        this.redirectionUrl = redirectionUrl;
    }

    resetRedirectionUrl(): void {
        this.redirectionUrl = undefined;
    }

    canLoad(route: Route, segments: UrlSegment[]): boolean {
        if (!this.isLoggedIn()) {
            let redirectUrl = segments.map(segment => segment.path).join("/");

            this.router.navigate(this.getRedirectionuUrl(), {
                queryParamsHandling: "merge",
                queryParams: {
                    redirectUrl: redirectUrl
                }
            });

            this.resetRedirectionUrl();
            return false;
        }

        this.resetRedirectionUrl();
        return true;
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): boolean {
        return this.isLoggedIn();
    }

    canActivateChild(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): boolean {
        return this.canActivate(route, state);
    }

    isLoggedIn(): boolean {
        return this.identityService.hasIdentity();
    }
}
