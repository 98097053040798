import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import {
    ActivatedRoute,
    ActivatedRouteSnapshot,
    CanActivateChild,
    Router,
    RouterStateSnapshot,
    UrlTree
} from "@angular/router";
import { RegWallNamespace, RegWallService } from "../../../activity-app/shared-activity/reg-wall.service";
import { AuthTypes } from "../authentication-view/authentication-types";
import { ROUTE_AUTHENTICATION, ROUTE_VIDEO } from "../../routes/routes";

@Injectable({ providedIn: "root" })
export class CanActivateChildVideoView implements CanActivateChild {

    constructor(private router: Router, private route: ActivatedRoute, private regWallService: RegWallService) {
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return new Promise(resolve => {
            this.regWallService.isAccessAllowed(RegWallNamespace.DIALOG, +childRoute.params.dialogId)
                .then((accessAllowed) => {
                    if (!accessAllowed) {
                        this.router.navigate([ROUTE_AUTHENTICATION, AuthTypes.REGISTER], {
                            queryParamsHandling: "merge",
                            queryParams: {
                                redirectUrl: `${ROUTE_VIDEO}/${childRoute.params.dialogId}`
                            }
                        });
                    }
                    resolve(accessAllowed);
                    return accessAllowed;
                })
                .then((shouldSetAccessLog) => {
                    if (shouldSetAccessLog) {
                        return this.regWallService.setAccessLog(RegWallNamespace.DIALOG, childRoute.params.dialogId);
                    }
                });
        });
    }
}
