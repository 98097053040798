import { Injectable } from "@angular/core";
import { fromEvent, Observable } from "rxjs";
import { filter } from "rxjs/operators";

@Injectable({
    providedIn: "root"
})
export class DomEventService {

    static readonly EVENT_CLICK: string = "click";
    static readonly EVENT_BLUR: string = "blur";

    click(element: any): Observable<Event> {
        return fromEvent(element, DomEventService.EVENT_CLICK);
    }

    clickOutside(outsideOfEl: Element) {
        return this.click(document)
            .pipe(
                filter((event: any) => {
                    return ! outsideOfEl.contains(event.target);
                })
            );
    }

    clickInside(outsideOfEl: Element) {
        return this.click(document)
            .pipe(
                filter((event: any) => {
                    return outsideOfEl.contains(event.target);
                })
            );
    }

    blur(element: any): Observable<Event> {
        return fromEvent(element, DomEventService.EVENT_BLUR);
    }

    focus(element: any): Observable<Event> {
        return fromEvent(element, DomEventService.EVENT_BLUR);
    }
}
