import {
    Compiler,
    ComponentFactory,
    ComponentFactoryResolver,
    ComponentRef,
    Injectable,
    Injector,
    NgModuleFactory,
    Type,
    ViewContainerRef
} from "@angular/core";
import { Logger } from "./logger/logger";

@Injectable({providedIn: "root"})
export class LazyLoaderService {
    private logger = new Logger();
    private locked: string[] = [];

    constructor(private compiler: Compiler,
                private injector: Injector,
                private factoryResolver: ComponentFactoryResolver) {
    }

    async load(viewContainerRef: ViewContainerRef,
               lazyComponent: Type<any>,
               lazyModule?: NgModuleFactory<any> | Type<any>): Promise<ComponentRef<any>> {
        if (!viewContainerRef) {
            this.logger.log(viewContainerRef, lazyComponent, lazyModule);
            return Promise.reject("cannot instantiate due to missing containerRef");
        }

        const currentKey = this.createKey(viewContainerRef, lazyComponent, lazyModule);
        if (this.locked.includes(currentKey)) {
            return;
        }

        this.locked.push(currentKey);
        viewContainerRef.clear();
        const componentFactory = await this.createComponent(lazyComponent, lazyModule);
        this.locked = this.locked.filter(key => key != currentKey);

        return viewContainerRef.createComponent(componentFactory);
    }

    async createComponent(lazyComponent: Type<any>,
                          lazyModule?: NgModuleFactory<any> | Type<any>): Promise<ComponentFactory<any>> {
        if (!lazyModule) {
            return this.factoryResolver.resolveComponentFactory(lazyComponent);
        }
        const moduleFactory = lazyModule instanceof NgModuleFactory
            ? lazyModule
            : (await this.compiler.compileModuleAsync(lazyModule));
        const moduleRef = moduleFactory.create(this.injector);
        return moduleRef.componentFactoryResolver.resolveComponentFactory(lazyComponent);
    }

    private createKey(viewContainerRef: ViewContainerRef,
                      lazyComponent: Type<any>,
                      lazyModule?: NgModuleFactory<any> | Type<any>): string {
        return [
            viewContainerRef?.element?.nativeElement?.parentNode ?? "",
            lazyComponent.name ?? "",
            lazyModule ? lazyModule["name"] : ""
        ].join("_");
    }
}
