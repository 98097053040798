import { GEOLOCATION } from "../../../geolocation";

// [1] Doc: https://developers.facebook.com/docs/facebook-pixel/reference#standard-events
// [2] Doc: https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking
export namespace AnalyticsFacebook {
    export const WHITE_LISTED_COUNTRIES = [GEOLOCATION.US, GEOLOCATION.TR, GEOLOCATION.JP, GEOLOCATION.FR];

    export enum Command {
        INIT = "init",
        TRACK = "track",
    }

    export enum ContentType {
        PRODUCT = "Product",
        SESSION = "Session",
    }

    export enum EventName {
        ADD_PAYMENT_INFO = "AddPaymentInfo", // A person clicks on a save billing information button.
        COMPLETE_REGISTRATION = "CompleteRegistration", // A person submits a completed subscription or signup form.
        INITIATE_CHECKOUT = "InitiateCheckout", // InitiateCheckout: A person clicks on a checkout button.
        LEAD = "Lead", // A person clicks on pricing.
        PAGE_VIEW = "PageView", // A person lands on your website pages.
        PURCHASE = "Purchase", // A person has finished the purchase or checkout flow and lands on thank you or confirmation page.
        START_TRIAL = "StartTrial", // A person selects a free week of your game.
        SUBSCRIBE = "Subscribe", // A person subscribes to your streaming service.
        VIEW_CONTENT = "ViewContent", // A person lands on a product details page.
    }

    export interface EventParams {
        content_category?: string;
        content_ids?: (string | number)[];
        content_name?: string;
        content_type?: string;
        contents?: object[];
        currency?: string;
        num_items?: number;
        predicted_ltv?: number;
        search_string?: string;
        status?: boolean;
        value?: number;
    }

    export interface TrackParams {
        eventName: EventName;
        eventParams?: EventParams;
        command?: Command;
    }
}

