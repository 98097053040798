import { Injectable } from "@angular/core";
import { forkJoin, Observable, of, Subscription } from "rxjs";
import { AccountDailyGoal, DailyGoalSetting } from "../../model/types/daily-goal";
import { catchError, map as rxJsMap, mergeMap, share, take, tap } from "rxjs/operators";
import { IdentityService } from "../../core/identity.service";
import { DailyGoalModelService } from "../../model/identity/daily-goal-model.service";
import { AccountProgressModelService } from "../../model/reportcard/account-progress-model.service";
import { EventTypes } from "../../common-app/progress-app/event-factory/event-types";
import { UserSettings } from "./activity-setting.service";
import { ActivityProgress, SpokenDialogLineProgressDetail } from "../../model/types/reportcard/activity-progress";
import { Activity } from "../../model/types/content/activity";
import { Emitter } from "../../core/emitters/emitter";
import { ReferenceModelService } from "../../model/content/reference-model.service";
import { ExperiencePointsReference } from "../../model/types/experience-points-reference";
import { GlobalNotification } from "../../model/types/notification";
import { DateUtil } from "../../core/date-util";
import { GlobalNotificationAppService } from "../../global-notification-app/global-notification-app.service";
import { AuthenticationData } from "../../common-app/authentication-adapters/authentication-data";
import { ReportCardEvent } from "../../common-app/progress-app/event-factory.service";
import { find, includes, isArray, isEmpty, isNumber, isUndefined, map } from "lodash-es";
import { Logger } from "../../core/logger/logger";
import { FeatureService } from "../../core/feature.service";
import { generateBooleanFromUnknown } from "../../core/utility-functions";

const dailyGoalNotificationOptions = {
    timeoutMs: 60000,
    shouldOpenInsideModal: true
};

@Injectable()
export class DailyGoalProgressService {
    private emitter = new Emitter();
    private logger = new Logger();
    static DAILY_POINT_GENERATING_EVENT_TYPES: string[] = [
        EventTypes.COMPLETE_ACTIVITY_WATCH,
        EventTypes.WATCH_COMPREHENSION_CHOICE,
        EventTypes.QUIZZED_WORD,
        EventTypes.LEARNED_WORD,
        EventTypes.TYPED_QUIZ_WORD,
        EventTypes.CHOSEN_QUIZ_WORD,
        EventTypes.SPOKEN_QUIZ_WORD,
        EventTypes.DIALOG_LINE_SPEAK,
        EventTypes.DIALOG_LINE_SPEAK_CLIPLIST,
        EventTypes.COMPLETE_ACTIVITY_GO_LIVE
    ];
    static SERVICE_RESET_BUFFER: number = 1500;
    static DEBUG_MODE_ENABLED: boolean = true;
    static EVENT_STATE_HYDRATE: string = "onEventStateHydate";

    // Data
    private accountDailyGoal: AccountDailyGoal;
    private accountPointsToday: number;
    private accountPointsTodayAccumulated: number;
    private experiencePointsReference: ExperiencePointsReference;

    // Observables
    private accountDailyGoalObservable: Observable<AccountDailyGoal>;
    private accountPointsTodayObservable: Observable<any>;

    // Event dictionary
    private eventDictionary: { [eventKey: string]: number } = {};

    // State
    private dailyGoalNotificationDisabled: boolean = false;
    private dailyGoalCompletionSentToServices: boolean = false;
    private initialized: boolean = false;
    private timerToReset: any;

    constructor(
        private identityService: IdentityService,
        private dailyGoalModelService: DailyGoalModelService,
        private accountProgressModelService: AccountProgressModelService,
        private referenceModelService: ReferenceModelService,
        private globalNotificationAppService: GlobalNotificationAppService,
        private featureService: FeatureService
    ) {
    }

    static generateEventKey(eventData: any): string {
        let key = `${eventData?.accountID}_${eventData?.activityID ?? 0}_${eventData?.activityTypeID ?? 0}`;
        const {type: eventType} = eventData;
        if ((eventType === EventTypes.LEARNED_WORD)
            || (eventType === EventTypes.QUIZZED_WORD)
            || (eventType === EventTypes.TYPED_QUIZ_WORD)
            || (eventType === EventTypes.CHOSEN_QUIZ_WORD)
            || (eventType === EventTypes.SPOKEN_QUIZ_WORD)
        ) {
            const wordRootId = eventData?.word?.wordRootID;
            const quizStepId = eventData?.quizStepId;
            if (wordRootId) {
                key += `_${wordRootId}`;
            }
            if (quizStepId) {
                key += `_${quizStepId}`;
            }
        }

        if (eventType === EventTypes.COMPLETE_ACTIVITY_WATCH) {
            key += `_${eventData?.type || "NoEventType"}`;
            const dialogId = eventData?.dialogID;
            if (dialogId) {
                key += `_${dialogId}`;
            }
        }
        if (eventType === EventTypes.WATCH_COMPREHENSION_CHOICE) {
            key += `_${eventData?.type || "NoEventType"}`;
            const questionId = eventData?.questionID;
            if (questionId) {
                key += `_${questionId}`;
            }
        }
        if ((eventType === EventTypes.DIALOG_LINE_SPEAK)
            || (eventType === EventTypes.DIALOG_LINE_SPEAK_CLIPLIST)
        ) {
            key += `_${eventData?.type || "NoEventType"}`;
            const dialogLineId = eventData?.dialogLineID;
            if (dialogLineId) {
                key += `_${dialogLineId}`;
            }
        }
        return key;
    }

    initialize(
        shouldHydrateDailyGoal: boolean = true,
        shouldHydrateAccountPointsToday: boolean = true,
        authenticationData?: AuthenticationData
    ): Observable<[AccountDailyGoal, number, any]> {
        const accountId = authenticationData?.accountID ?? this.identityService.getAccountId();
        return forkJoin([
            this.fetchAccountDailyGoal(accountId, shouldHydrateDailyGoal),
            this.fetchAccountPointsToday(accountId, shouldHydrateAccountPointsToday),
            this.fetchExperiencePointsReference()
        ]).pipe(
            mergeMap((response) => {
                if (this.isDailyGoalCompleted()) {
                    return this.sendDailyGoalCompletionToServices().pipe(rxJsMap(() => response));
                }
                return of(response);
            }),
            tap(() => {
                this.initializeData();
                if (!this.isInitialized()) {
                    this.initializeTimerToReset();
                }
                this.logger.log("Daily goal state service initialized...");
                this.initialized = true;
            }));
    }

    private initializeData(): void {
        this.logger.log("Daily goal state service is initializing its data...");
        this.dailyGoalNotificationDisabled = this.accountPointsTodayAccumulated > this.getAccountDailyGoalPerDay();
        this.logger.log(`Daily goal notification is ${this.dailyGoalNotificationDisabled ? "disabled" : "enabled"}...`);
        // If initialized before but somehow dailyGoal is changed, checkDailyGoalCompletion again
        if (this.isInitialized()) {
            this.checkDailyGoalCompletion(false);
        }
    }

    // Timer resets the counter when user passes to next day
    private initializeTimerToReset(): void {
        if (this.timerToReset && window) {
            clearTimeout(this.timerToReset);
        }
        const now = new Date();
        const dayTurn = Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate() + 1, 0, 0, 0, 0);
        const difference = dayTurn - now.getTime(); // Service will hydrate itself 1.5s after midnight
        this.timerToReset = setTimeout(() => {
            this.hydrateState(false).pipe(take(1)).subscribe();
        }, difference + DailyGoalProgressService.SERVICE_RESET_BUFFER);
    }

    fetchExperiencePointsReference(): Observable<ExperiencePointsReference[]> {
        return this.referenceModelService
            .getCachedReference({types: "experiencePoints"})
            .pipe(
                catchError(() => of({})),
                tap((experiencePointsReference) => {
                    this.experiencePointsReference = experiencePointsReference ? experiencePointsReference[0] : {};
                })
            );
    }

    fetchAccountDailyGoal(accountId: number, shouldHydrate: boolean = false): Observable<AccountDailyGoal> {
        if (!isEmpty(this.accountDailyGoal) && !shouldHydrate) {
            return of(this.accountDailyGoal);
        }
        if (!isUndefined(this.accountDailyGoalObservable)) {
            return this.accountDailyGoalObservable;
        }
        this.accountDailyGoalObservable = this.dailyGoalModelService
            .getDailyGoal(accountId)
            .pipe(
                share(),
                catchError(() => of(undefined)),
                mergeMap((accountDailyGoal?: AccountDailyGoal) => {
                    // If user has no daily goal, set it to default before the component loads
                    if (!accountDailyGoal) {
                        const defaultGoalSetting = this.featureService.getFeature("defaultGoalSetting");
                        return this.dailyGoalModelService.updateDailyGoal(
                            accountId,
                            isNumber(defaultGoalSetting) ? defaultGoalSetting : DailyGoalSetting.INTERESTED
                        ).pipe(catchError(() => of(undefined)));
                    }
                    return of(accountDailyGoal);
                }),
                tap((accountDailyGoal: AccountDailyGoal) => {
                    this.accountDailyGoal = accountDailyGoal;
                    this.logger.log(`User's daily goal is ${accountDailyGoal?.pointsPerDay}xp(s)...`);
                    this.accountDailyGoalObservable = undefined;
                })
            );
        return this.accountDailyGoalObservable;
    }

    private fetchAccountPointsToday(accountId: number, shouldHydrate: boolean = false): Observable<number> {
        if (!isEmpty(this.accountPointsToday) && !shouldHydrate) {
            return of(this.accountPointsToday);
        }
        if (!isUndefined(this.accountPointsTodayObservable)) {
            return this.accountPointsTodayObservable;
        }
        this.accountPointsTodayObservable = this.accountProgressModelService
            .getAccountPointsToday(
                accountId,
                {timeZone: this.identityService.getTimezone()}
            )
            .pipe(
                catchError(() => of(0)),
                tap((accountPointsToday) => {
                    this.accountPointsToday = accountPointsToday || 0;
                    this.accountPointsTodayAccumulated = this.accountPointsToday;
                    this.logger.log(`User starts with ${this.accountPointsToday}xp(s) today...`);
                    this.accountPointsTodayObservable = undefined;
                })
            );
        return this.accountPointsTodayObservable;
    }

    private getPointFromEventData(
        eventData: ReportCardEvent,
        activitySettings?: UserSettings,
        previousActivityProgress?: ActivityProgress[]
    ): number {
        const eventType = eventData?.type;
        const activityTypeId = eventData?.activityTypeID;
        if (eventType === EventTypes.COMPLETE_ACTIVITY_WATCH) {
            const isWatchCompletedBefore = eventData?.completed;
            if (isWatchCompletedBefore) {
                return 0;
            }
            return this.experiencePointsReference?.watch;
        }
        if (eventType === EventTypes.WATCH_COMPREHENSION_CHOICE) {
            const isCorrect = eventData?.correct ?? false;
            if (isCorrect) {
                return this.experiencePointsReference?.watch;
            }
        }
        if ((eventType === EventTypes.LEARNED_WORD)
            || (eventType === EventTypes.QUIZZED_WORD)) {
            return this.experiencePointsReference?.word;
        }
        // VB Events
        if ((eventType === EventTypes.TYPED_QUIZ_WORD)
            || (eventType === EventTypes.CHOSEN_QUIZ_WORD)
            || (eventType === EventTypes.SPOKEN_QUIZ_WORD)) {
            const isCorrect = eventData?.correct ?? false;
            const isPreviouslyEncountered = eventData?.previouslyEncountered ?? false;
            if (isCorrect) {
                const isQuizActivity = Activity.isQuizActivity(activityTypeId);
                if (isQuizActivity && isPreviouslyEncountered) {
                    this.logger.log("Word is previously encountered xp is not granted...");
                    return 0;
                }
                return this.experiencePointsReference?.word;
            }
        }
        if ((eventType === EventTypes.DIALOG_LINE_SPEAK) || (eventType === EventTypes.DIALOG_LINE_SPEAK_CLIPLIST)) {
            if (previousActivityProgress) {
                const spokenDialogLineId = eventData?.dialogLineID;
                const speakActivity = find(
                    previousActivityProgress,
                    (activity) => Activity.isSpeakActivity(activity.activityTypeID)
                );
                if (speakActivity && speakActivity.spokenDialogLines) {
                    const isSpokenBefore = find(
                        speakActivity.spokenDialogLines,
                        (spokenDialogLineProgressDetail: SpokenDialogLineProgressDetail) => {
                            return spokenDialogLineProgressDetail.dialogLineID == spokenDialogLineId;
                        }
                    );
                    if (isSpokenBefore) {
                        this.logger.log("Line spoken before. Points are not updated...");
                        return 0;
                    }
                }
            }
            const rejectionCode = eventData["rejectionCode"];
            if (rejectionCode == 0) {
                return this.experiencePointsReference?.line;
            }
            return 0;
        }
        if (eventType === EventTypes.COMPLETE_ACTIVITY_GO_LIVE) {
            return this.experiencePointsReference?.goLive;
        }
        if (eventType === EventTypes.COMPLETE_ACTIVITY_LEVEL_TEST) {
            return this.experiencePointsReference?.levelTest;
        }
        return 0;
    }

    getAccountDailyGoal(): AccountDailyGoal | undefined {
        return this.accountDailyGoal;
    }

    getAccountPointsToday(): number {
        return this.accountPointsToday || 0;
    }

    getAccountPointsTodayAccumulated(): number {
        return this.accountPointsTodayAccumulated || 0;
    }

    getAccountDailyGoalPerDay(): number {
        return this.accountDailyGoal?.pointsPerDay ?? 0;
    }

    setAccountDailyGoal(accountDailyGoal: AccountDailyGoal): void {
        this.accountDailyGoal = accountDailyGoal;
        this.logger.log(`Account daily goal is set to ${this.accountDailyGoal?.pointsPerDay}xp`);
        this.initializeData();
    }

    isInitialized(): boolean {
        return this.initialized;
    }

    isDailyGoalCompleted(): boolean {
        return this.getAccountPointsTodayAccumulated() >= this.getAccountDailyGoalPerDay();
    }

    updateAccountPointsToday(
        events: any,
        activitySettings?: UserSettings,
        previousActivityProgress?: ActivityProgress[]
    ): void {
        // CompleteActivityWatch => 10 => accountID_activityID_activityTypeID_type (IF this.isComprehensionQuizProgressEnabled) 30 points
        // CompleteActivityGoLive => 50 => accountID_activityID_activityTypeID_type
        // CompleteActivityLevelTest => 50 => accountID_activityID_activityTypeID_type
        // WatchComprehensionChoice => 10 => accountID_activityID_activityTypeID_type_questionID
        // LearnedWord || QuizzedWord || TypedQuizWord || ChosenQuizWord || SpokenQuizWord => 10 => accountID_activityID_activityTypeID_type - add wordRootID to the key from eventData.word.wordRootID
        // DialogLineSpeak || DialogLineSpeak => 10 => accountID_activityID_activityTypeID_type (Give the XP if eventData.rejectionCode === 0) (of if eventData.refectionCode !== 6)
        if (isEmpty(events) || this.identityService.isAnonymous()) {
            return;
        }
        const eventsArr = isArray(events) ? events : [events];
        map(eventsArr, (eventData) => {
            const eventType = eventData?.type;
            const isEventValidForUpdatingPoints = includes(
                DailyGoalProgressService.DAILY_POINT_GENERATING_EVENT_TYPES,
                eventType
            );
            if (!eventType || !isEventValidForUpdatingPoints) {
                return;
            }
            let generatedEventKey = DailyGoalProgressService.generateEventKey(eventData);

            // If event is already sent do not add points to the account bucket
            if (this.eventDictionary[generatedEventKey]) {
                return;
            }
            const gainedPoint = this.getPointFromEventData(eventData, activitySettings, previousActivityProgress);
            if (gainedPoint) {
                this.eventDictionary[generatedEventKey] = gainedPoint;
                this.accountPointsTodayAccumulated += gainedPoint;
                this.logger.log("Daily goal service state updating...");
                if (DailyGoalProgressService.DEBUG_MODE_ENABLED) {
                    this.logger.log("*********");
                    this.logger.log({
                        generatedEventKey,
                        gainedPoint,
                        accountDailyGoalPerDay: this.getAccountDailyGoalPerDay(),
                        accountPointsToday: this.getAccountPointsToday(),
                        accountPointsTodayAccumulated: this.accountPointsTodayAccumulated
                    });
                    this.logger.log(this.eventDictionary);
                    this.logger.log("*********");
                }
                this.checkDailyGoalCompletion(true);
            }
        });
    }

    private checkDailyGoalCompletion(shouldShownScreenNotification: boolean = true): void {
        if (this.isDailyGoalCompleted() && generateBooleanFromUnknown(this.featureService.getFeature("isDailyGoalPopupEnabled"))) {
            this.sendDailyGoalNotification(shouldShownScreenNotification);
        }
    }

    private sendDailyGoalNotification(shouldShowScreenNotification: boolean): void {
        if (this.dailyGoalNotificationDisabled) {
            this.logger.log("Daily goal notification is disabled. Can not send notification neither to service nor to screen");
            return;
        }
        this.logger.log("Daily goal notification is being sent...");
        this.dailyGoalNotificationDisabled = true;
        if (shouldShowScreenNotification) {
            this.sendDailyGoalScreenNotification();
        }
        this.sendDailyGoalCompletionToServices().pipe(take(1)).subscribe();
    }

    sendDailyGoalScreenNotification(): void {
        this.logger.log("Daily goal screen notification is triggered...");
        const notificationInstance = new GlobalNotification.BaseNotification(
            GlobalNotification.CustomNotificationType.DAILY_GOAL_COMPLETION,
            dailyGoalNotificationOptions
        );
        this.globalNotificationAppService.send(notificationInstance);
    }

    sendDailyGoalCompletionToServices(): Observable<void> {
        if (!this.dailyGoalCompletionSentToServices) {
            this.logger.log("Daily goal completion event sent to services...");
            const params = {
                timeZone: this.identityService.getTimezone(),
                date: DateUtil.getBsonDate()
            };
            return this.accountProgressModelService
                .sendDailyGoalCompletion(params)
                .pipe(
                    catchError(() => of(undefined)),
                    tap(() => this.dailyGoalCompletionSentToServices = true)
                );
        }
        this.logger.log("Daily goal completion is not sent since it was sent within this same day...");
        return of(undefined);
    }

    hydrateState(isResettedManually: boolean = true): Observable<[AccountDailyGoal, number, any]> {
        if (DailyGoalProgressService.DEBUG_MODE_ENABLED) {
            const debugMessage = "Daily-goal-progress.service is hydrating is state...";
            if (isResettedManually) {
                this.logger.log(debugMessage);
            } else {
                this.logger.log(`Day is turned to the next one. ${debugMessage}`);
            }
        }
        this.initialized = false;
        this.dailyGoalNotificationDisabled = false;
        this.dailyGoalCompletionSentToServices = false;
        this.accountPointsToday = undefined;
        this.accountPointsTodayAccumulated = undefined;
        return this.initialize(false, true)
            .pipe(tap(() => {
                this.publish(DailyGoalProgressService.EVENT_STATE_HYDRATE);
                this.initializeTimerToReset();
            }));
    }

    subscribe(eventName: string, successFn: (data?) => void, errorFn?: (e?) => void): Subscription {
        return this.emitter.subscribe(eventName, successFn, errorFn);
    }

    publish(eventName: string, data?: any): void {
        this.emitter.publish(eventName, data);
    }

    getObservable(eventName: string): Observable<any> {
        return this.emitter.getObservable(eventName).asObservable();
    }
}
