import { TrackerLogger } from "../tracker.logger";
import { TrackConversionParams, Tracker, TrackerName } from "../tracker";
import { isUndefined } from "lodash-es";

declare var criteo_q: any;

export const enum CriteoConversionType {
    REGISTRATION_CART = "REGISTRATION_CART",
    PURCHASE_COMPLETION = "PURCHASE_COMPLETION",
}

const CRITEO_ACCOUNT_ID = "86887";
const CRITEO_ITEM_ID = "80001";

const CRITEO_REGISTRATION_CART = [
    { event: "setAccount", account: CRITEO_ACCOUNT_ID },
    { event: "setSiteType", type: "d" },
    { event: "viewBasket", item: [{ id: CRITEO_ITEM_ID, price: 1, quantity: 1}]}
];

const CRITEO_PURCHASE_COMPLETION = [
    { event: "setAccount", account: CRITEO_ACCOUNT_ID },
    { event: "setSiteType", type: "d" },
    { event: "trackTransaction" , id: Math.floor(Math.random() * 99999999999),
        item: [ { id: CRITEO_ITEM_ID, price: 1, quantity: 1 } ] }
];

export const ConversionList = {
    [CriteoConversionType.REGISTRATION_CART]: CRITEO_REGISTRATION_CART,
    [CriteoConversionType.PURCHASE_COMPLETION]: CRITEO_PURCHASE_COMPLETION
};

export class CriteoTracker extends TrackerLogger implements Tracker {

    constructor(private config: any,
                private isProductionMode: boolean = false) {
        super(TrackerName.CRITEO, isProductionMode);
    }

    isEnabled(): boolean {
        return typeof criteo_q !== "undefined";
    }

    trackConversion(params: TrackConversionParams): void {
        if (!this.isEnabled() || isUndefined(ConversionList[params?.conversionKey])) {
            return;
        }

        criteo_q = criteo_q || [];
        criteo_q.push(ConversionList[params?.conversionKey]);
    }

    /* eslint-disable */
    init(): void {
        "use strict";
        this.insertScript(window, document, "script", `//static.criteo.net/js/ld/ld.js`);
        (<any>window).criteo_q = (<any>window).criteo_q || []; (<any>window).criteo_q.push( { event: "setAccount", account: this.config?.id }, { event: "setSiteType", type: "d" }, { event: "viewItem", item: CRITEO_ITEM_ID } );
    }

    private insertScript(i, s, o, g): void {
        var a, m;
        a = s.createElement(o);
        m = s.getElementsByTagName(o)[0];
        a.async = 1;
        a.src = g;
        m.parentNode.insertBefore(a, m);
    }
    /* eslint-enable */

    trackPageView(): void {
    }

    trackEvent(): void {
    }
}
