import { ROUTE_PARTNER_SATSI } from "../../../routes/routes.partners";
import { ROUTE_MY_ENGLISH } from "../../../routes/routes";
import { PartnerConfig } from "./partner-config";

export const SATSI_CONFIG: PartnerConfig = {
    route: ROUTE_PARTNER_SATSI,
    styleClassName: "partner-satsi",
    accessCodeLinkOnSkip: ROUTE_MY_ENGLISH,
    accessCodeLinkOnSuccess: ROUTE_MY_ENGLISH,
    accessCodeRedirectionDisabled: false,
    accessCodeImageLink: require("global-assets/partners/partner-satsi-background.jpg")
};
