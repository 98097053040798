import { Injectable, NgZone } from "@angular/core";
import { Observable, Subscription } from "rxjs";
import { Emitter } from "./emitters/emitter";
import { TutorAccountModelService } from "../model/tutor/tutor-account-model.service";
import { IdentityService } from "./identity.service";
import { AccountLessonEligibility, LessonEligibility } from "../model/types/account-lesson-eligibility";
import { Logger } from "./logger/logger";
import { FeatureService } from "./feature.service";
import { ProgressQueueService } from "../common-app/progress-app/progress-queue.service";
import { has, isEmpty, isUndefined, map, pick, sumBy, throttle } from "lodash-es";
import { EligibilityService } from "./eligibility.service";
import { SessionType } from "../model/types/tutor/session-type";
import { SessionUtils } from "../model/types/tutor/session-utils";

@Injectable()
export class TutorAccountStateService {
    static TUTOR_STATE_INITIALIZED: string = "tutorStateInitialized";
    static STATUS_SCHEDULED: string = "Scheduled";
    static EVENT_SESSION_SUCCESS: string = "SessionSuccess";

    private emitter = new Emitter(true);
    private initialized: boolean = false;
    private eligibility: AccountLessonEligibility;
    private accountId: number;
    private timezone: string;
    private logger = new Logger();

    constructor(private identityService: IdentityService,
                private featureService: FeatureService,
                private progressQueueService: ProgressQueueService,
                private zone: NgZone,
                private tutorAccountModelService: TutorAccountModelService,
                private eligibilityService: EligibilityService
    ) {
        this.initialize = throttle(this.initialize.bind(this), 2000);
        this.initialize();
        this.progressQueueService.subscribe(ProgressQueueService.EVENT_COMPLETION_SENT, () => {
            this.initialize();
        });

        this.identityService.subscribe(IdentityService.EVENT_IDENTITY_UPDATE, (accountId) => {
            this.accountId = accountId;
            this.initialize();
        });
    }

    getEligibilityObject(): AccountLessonEligibility {
        return this.eligibility;
    }

    initialize(): void {
        if (this.identityService.isAnonymous()) {
            return;
        }

        this.eligibilityService
            .initializeEligibility()
            .subscribe((eligibility) => {
                this.featureService.generateFeatures();

                this.zone.run(() => {
                    this.eligibility = eligibility;
                    this.initialized = true;
                    this.publish(TutorAccountStateService.TUTOR_STATE_INITIALIZED, eligibility);
                });
            }, (error) => this.logger.error(error));
    }

    isInitialized(): boolean {
        return this.initialized;
    }

    private isEligible(sessionType: string): boolean {
        if (sessionType == SessionType.TYPE_LT && !this.isLevelTestEnabled()) {
            return false;
        }

        if (sessionType == SessionType.TYPE_GL && !this.isGoLiveEnabled()) {
            return false;
        }

        let lessonEligibility = this.getEligibility(sessionType);
        if (!lessonEligibility) {
            return false;
        }

        return lessonEligibility.isEligible;
    }

    private getEligibility(sessionType: string): LessonEligibility | undefined {
        if (!this.eligibility
            || !this.eligibility.lessonEligibility
            || !has(this.eligibility.lessonEligibility, sessionType)) {
            return undefined;
        }

        return this.eligibility.lessonEligibility[sessionType];
    }

    isSubscriptionBasic(): boolean {
        if (!this.eligibility) {
            return !this.identityService.isPremium();
        }
        return this.eligibility.subscription.toLowerCase() == AccountLessonEligibility.SUBSCRIPTION_BASIC.toLowerCase();
    }

    isSubscriptionCourseOnly(): boolean {
        if (!this.eligibility) {
            return !this.identityService.isPremium();
        }
        return this.eligibility.subscription.toLowerCase() == AccountLessonEligibility.SUBSCRIPTION_COURSEONLY.toLowerCase();
    }

    isSubscriptionAcademic(): boolean {
        if (!this.eligibility) {
            return !this.identityService.isAcademic();
        }
        return this.eligibility.subscription.toLowerCase() == AccountLessonEligibility.SUBSCRIPTION_ACADEMIC.toLowerCase();
    }

    isSubscriptionPremium(): boolean {
        if (!this.eligibility) {
            return this.identityService.isPremium();
        }
        return this.eligibility.subscription.toLowerCase() == AccountLessonEligibility.SUBSCRIPTION_PREMIUM.toLowerCase();
    }

    isSubscriptionPlatinum(): boolean {
        if (!this.eligibility) {
            return this.identityService.isPlatinum();
        }
        return this.eligibility.subscription.toLowerCase() == AccountLessonEligibility.SUBSCRIPTION_PLATINUM.toLowerCase();
    }

    isLevelTestEligible(): boolean {
        return this.isEligible(SessionType.TYPE_LT);
    }

    isGoLiveEligible(): boolean {
        return this.isEligible(SessionType.TYPE_GL);
    }

    isLevelUpTestEligible(): boolean {
        return this.isEligible(SessionType.TYPE_LUT);
    }

    isOpenTalkEligible(): boolean {
        return this.isEligible(SessionType.TYPE_OT);
    }

    isLevelTestEnabled(): boolean {
        // BC-53820 will be hard-coded for VNPT release. Will be removed once
        // feature is updated to support multiple criteria-match for a partner.
        if (this.featureService.getFeature("isCustomFreeLesson")) {
            return !this.featureService.getFeature("isPartnerFreeLessonDisabled");
        }
        return this.featureService.getFeature("isLevelTestEnabled");
    }

    isGoLiveEnabled(): boolean {
        return this.featureService.getFeature("isGoLiveEnabled");
    }

    isGoLiveInsufficientPoints(): boolean {
        let eligibility = this.getEligibility(SessionType.TYPE_GL);
        if (!eligibility) {
            return true;
        }
        return eligibility.eligibilityReasons == LessonEligibility.REASON_INSUFFICIENT_EXP;
    }

    isMaxLessonsReached(): boolean {
        let eligibility = this.getEligibility(SessionType.TYPE_GL);
        if (!eligibility) {
            return true;
        }
        return eligibility.eligibilityReasons == LessonEligibility.REASON_MAXLESSONSREACHED;
    }

    isGoLiveIncompleteDialog(): boolean {
        let eligibility = this.getEligibility(SessionType.TYPE_GL);
        if (!eligibility) {
            return true;
        }
        return eligibility.eligibilityReasons == LessonEligibility.REASON_NO_COMPLETED_DIALOGS;
    }

    isGoLiveAccountIsBasic(): boolean {
        let eligibility = this.getEligibility(SessionType.TYPE_GL);
        if (!eligibility) {
            return true;
        }
        return eligibility.eligibilityReasons == LessonEligibility.REASON_ACCOUNT_IS_BASIC;
    }

    hasXtraLessonCredits(sessionType: string): boolean {
        let lessonEligibility = this.getEligibility(sessionType);

        return this.isEligible(sessionType) &&
            lessonEligibility.isEligible &&
            lessonEligibility.xtraCredits > 0;
    }

    canUseXtraLessonCredits(sessionType: string): boolean {
        return (this.hasXtraLessonCredits(sessionType) && this.getGivenLimitLeftBySessionType(sessionType) == 0) ||
            (this.hasXtraLessonCredits(sessionType) && this.getDailyLimitLeftBySessionType(sessionType) == 0);
    }

    hasSchedule(type: string): boolean {
        let eligibilityObject: LessonEligibility | undefined = this.getEligibility(type);

        if (!isUndefined(eligibilityObject)) {
            return eligibilityObject.status == TutorAccountStateService.STATUS_SCHEDULED;
        }

        return false;
    }

    getRemainingLessonCount(): number {
        let eligibilityObj = this.getEligibilityObject();
        return isUndefined(eligibilityObj) ? 0 : eligibilityObj.totalLessonsAvailable;
    }

    getXtraLessonCreditsCount(): number {
        let eligibilityObj = this.getEligibilityObject();
        if (isUndefined(eligibilityObj)) {
            return 0;
        }
        return sumBy(map(pick(eligibilityObj.lessonEligibility, ["GL", "LT", "LUT"]), (eligibility) => {
            return isUndefined(eligibility.xtraCredits) ? 0 : eligibility.xtraCredits;
        }));
    }

    getGoLiveRequiredPoints(): number {
        let eligibility = this.getEligibility(SessionType.TYPE_GL);
        if (!eligibility) {
            return 0;
        }
        return eligibility.requiredExperiencePoints;
    }

    getGoLiveAvailable(): number {
        let eligibility = this.getEligibilityObject();
        if (!eligibility) {
            return 0;
        }
        return eligibility.goLiveAvailable;
    }

    getGoLiveCredits(): number {
        let eligibility = this.getEligibility(SessionType.TYPE_GL);
        if (!has(eligibility, "credits")) {
            return 0;
        }

        return eligibility.credits;
    }

    getLevelTestCredits(): number {
        let eligibility = this.getEligibility(SessionType.TYPE_LT);
        if (!has(eligibility, "credits")) {
            return 0;
        }

        return eligibility.credits;
    }

    isLevelTestCompleted(): boolean {
        let eligibility = this.getEligibility(SessionType.TYPE_LT);
        if (!has(eligibility, "status")) {
            return false;
        }

        return eligibility.status === LessonEligibility.STATUS_COMPLETED;
    }

    getGivenLimitLeft(): number {
        let eligibility = this.getEligibility(SessionType.TYPE_GL);
        if (!has(eligibility, "givenLimitLeft")) {
            return 0;
        }

        return eligibility.givenLimitLeft;
    }

    getGivenLimitLeftBySessionType(sessionType: string): number {
        let eligibility = this.getEligibility(sessionType);
        if (!has(eligibility, "givenLimitLeft")) {
            return 0;
        }

        return eligibility.givenLimitLeft;
    }

    getDailyLimitLeftBySessionType(sessionType: string): number {
        let eligibility = this.getEligibility(sessionType);
        if (!has(eligibility, "dailyLimitLeft")) {
            return 0;
        }

        return eligibility.dailyLimitLeft;
    }

    getRenewalLimitLeft(): number {
        let eligibility = this.getEligibility(SessionType.TYPE_GL);
        if (!has(eligibility, "renewalLimitLeft")) {
            return 0;
        }

        return eligibility.renewalLimitLeft;
    }

    isOneGoLiveAtATime(): boolean {
        let eligibility = this.getEligibility(SessionType.TYPE_GL);
        if (!eligibility) {
            return false;
        }
        return eligibility.eligibilityReasons == LessonEligibility.REASON_ONLYONEGLATATIME;
    }

    isOnlyOneLt(): boolean {
        let eligibility = this.getEligibility(SessionType.TYPE_LT);
        if (!eligibility) {
            return false;
        }
        return eligibility.eligibilityReasons == LessonEligibility.REASON_ONLYONELT;
    }

    isScheduledLt(): boolean {
        let eligibility = this.getEligibility(SessionType.TYPE_LT);
        if (!eligibility) {
            return false;
        }
        return eligibility.status == LessonEligibility.STATUS_SCHEDULED;
    }

    getDateLimitRefresh(): string {
        let eligibility = this.getEligibility(SessionType.TYPE_GL);
        if (!eligibility) {
            return "";
        }
        return SessionUtils.getLocalizedDateFormatByTimestamp(eligibility.dateLimitRefresh, this.identityService.getIdentity(), false);
    }

    isDailyLessonReached(): boolean {
        let eligibility = this.getEligibility(SessionType.TYPE_GL);
        if (!eligibility) {
            return false;
        }
        return eligibility.eligibilityReasons == LessonEligibility.REASON_NODAILYLESSONSAVAILABLE;
    }

    setEligibility(eligibility: AccountLessonEligibility): void {
        if (isUndefined(this.eligibility) || isEmpty(this.eligibility)) {
            this.eligibility = eligibility;
        }
    }

    isPayingUser(): boolean {
        return this.featureService.getFeature("isPayingUser");
    }

    getObservable(eventName: string): Observable<any> {
        return this.emitter.getObservable(eventName);
    }

    subscribe(eventName: string, successFn: (data?) => void, errorFn?: (e?) => void): Subscription {
        return this.emitter.subscribe(eventName, successFn, errorFn);
    }

    publish(eventName: string, data?: any): void {
        this.emitter.publish(eventName, data);
    }

    destroy(): void {
        this.emitter.destroy();
    }
}
