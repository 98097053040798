import {
    LANGUAGE_DEFAULT,
    LANGUAGE_JAPANESE_YOUNG_LEARNERS,
    SUBDOMAIN_DEFAULT,
    SUBDOMAIN_JAPANESE_YOUNG_LEARNERS
} from "./core/locale";
import { assign, first } from "lodash-es";

declare var CONFIG: any;
declare var CONFIG_LANG: string;
declare var BUILD_LANG: string;
declare var ECSDK_USE_FULL_PATH: string;
declare var ECSDK_CONFIG_LANG: string;
declare var PUBLIC_PATH: string;

const getInitLanguage = (): string => {
    if (typeof ECSDK_CONFIG_LANG !== "undefined") {
        return ECSDK_CONFIG_LANG;
    }

    if (typeof CONFIG_LANG !== "undefined") {
        return CONFIG_LANG;
    }

    let defaultLanguage = navigator && navigator.language ? first(navigator.language.split("-")) : undefined;
    return defaultLanguage || BUILD_LANG;
};

export const getEcSubdomainByLanguage = (language: string): string => {
    switch (language) {
        case LANGUAGE_JAPANESE_YOUNG_LEARNERS:
            return SUBDOMAIN_JAPANESE_YOUNG_LEARNERS;
        case LANGUAGE_DEFAULT:
            return SUBDOMAIN_DEFAULT;
        default:
            return language;
    }
};

export const getSiteUrl = (lang: string = LANGUAGE_DEFAULT, isChina: boolean = false): string => {
    let siteUrl = isChina ? (CONFIG || {})?.siteUrl?.china : (CONFIG || {})?.siteUrl?.global;
    return siteUrl.replace("[LANG]", getEcSubdomainByLanguage(lang));
};

export const replaceSiteUrl = (url: string, lang: string = LANGUAGE_DEFAULT, isChina: boolean = false): string => {
    const baseUrl = (isChina ? (CONFIG || {})?.siteUrl?.china : (CONFIG || {})?.siteUrl?.global).replace("https://", "");
    const siteUrl = baseUrl.replace("[LANG]", "www");
    const langUrl = baseUrl.replace("[LANG]", getEcSubdomainByLanguage(lang));

    return url.replace(siteUrl, langUrl);
};

export const getGlobalConfig = () => {
    return assign({
        lang: getInitLanguage(),
        publicPath: PUBLIC_PATH,
        useFullPath: typeof ECSDK_USE_FULL_PATH !== "undefined" ? ECSDK_USE_FULL_PATH : false
    }, CONFIG || {});
};
