import { ROUTE_PARTNER_SOL } from "../../../routes/routes.partners";
import { PartnerConfig } from "./partner-config";

export const SOL_CONFIG: PartnerConfig = {
    route: ROUTE_PARTNER_SOL,
    styleClassName: "partner-sol",
    disableHeaderRightNavs: true,
    disableBrowseVideosLink: true,
    disableBrowseCoursesLink: true,
    disableAnonCourseAccess: true,
    accountRegistrationTagId: 150,
    accessCodeRedirectionDisabled: false,
    authenticationImageLink: "none",
    authenticationLogoImageLink: "https://cdns.englishcentral.com/images/ec3.4/partner/spoton.png"
};

