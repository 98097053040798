import { DialogGolive } from "../../../model/types/dialog-golive";
import { Timeslot } from "../../../model/types/timeslot";
import { DEFAULT_MODAL_OPTIONS } from "../../helpers/modal-options-default";
import { NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { Session } from "../../../model/types/session";
import { EventEmitter } from "@angular/core";
import { ActivityV1 } from "../../../model/types/content/activity-v1";
import { CourseV1 } from "../../../model/types/course-v1";
import { CourseUnitV1 } from "../../../model/types/course-unit-v1";
import { SessionV4 } from "../../../model/types/tutor/session-v4";
import { SessionV3 } from "../../../model/types/tutor/session-v3";

// Component props
export interface PreSelectedProps {
    isReschedule?: boolean;
    isBookAgain?: boolean;
    preSelectedCourse?: CourseV1;
    preSelectedCourseUnit?: CourseUnitV1;
    preSelectedDialogId?: number;
    preSelectedSession?: SessionV4;
    preSelectedActivity?: ActivityV1;
    preSelectedSessionType?: string;
    preSelectedTutorId?: number;
}

// Topic selector
export class SchedulerTopic {
    type: string;
    sessionTypeId: number;
    data?: DialogGolive;
    activity?: ActivityV1;
    course?: CourseV1;
    courseUnit?: CourseUnitV1;

    constructor(sessionTypeId: number,
                type?: string,
                data?: DialogGolive,
                activity?: ActivityV1) {
        this.type = type;
        this.sessionTypeId = sessionTypeId;
        this.data = data;
        this.activity = activity;
    }
}

export enum CommunicationToolSelectionOption {
    NOT_ENABLED = 0,
    ENABLED = 1,
    ENABLED_PRE_SELECTED = 2,
    ENABLED_PRE_SELECTED_SKIPPED = 3,
}

// [3] Timeslots
export interface TimeslotsGroupedByDayPart {
    dayPart: number;
    timeslotsUnderDayPart: Timeslot[];
    someAvailable: boolean;
}

export interface TimeslotOrdered {
    hasAvailableDays: boolean;
    displayDayShort: string;
    rawTimeslots: Timeslot[];
    day: number;
    dayText: string;
    month: number;
    monthText: string;
    calendarDay: number;
    groupedTimeslotsByDayPart: TimeslotsGroupedByDayPart[];
}


export class SchedulerApp {
    // -----------------------------------------------------------
    // [0] General
    static getConfig() {
        return {
            // Default topic selection enables a default topic get selected once the user gets into the system
            defaultTopicSelectionEnabled: true,
            // Topic filtering works when users click on a Tutor. This might be used when topic selection is last at step order
            topicFilteringEnabled: false,
            // Default session type sequence can vary with account subscription type
            defaultSessionTypeSequence: ["LT", "ML", "OT", "LUT", "GL"]
        };
    }

    static readonly MODAL_OPTIONS: NgbModalOptions = {
        ...DEFAULT_MODAL_OPTIONS,
        windowClass: "ec-modal",
        size: "lg",
        centered: false,
        backdrop: "static"
    };
    static readonly DAY_COUNT_MAX = 6; // Day count is the maximum allowed timespan for fetching timeslots and tutors
    static readonly DAY_COUNT_DISPLAYED = 6;
    static readonly MODE = {
        DEFAULT: "DEFAULT",
        STANDALONE: "STANDALONE"
    };

    // -----------------------------------------------------------
    // [1] Topic selector
    static readonly schedulerTopicTypes = ["GL", "LT", "LUT", "OT", "ML"];

    static isSchedulerTopic(x: string): boolean {
        return this.schedulerTopicTypes.includes(x);
    }

    // -----------------------------------------------------------
    // [2] Tutor selector
    // TUTOR_TIMESLOT_OFFSET => Used when timeslots are selected before selecting a tutor. TUTOR_TIMESLOT_OFFSET_BACKWARDS is subtracted from
    // selectedTimeslot startTime to get a startDate param while fetching Tutors. Same way, endDate is calculated by adding
    // TUTOR_TIMESLOT_OFFSET_FORWARDS to selectedTimeslot startTime. TUTOR_TIMESLOT_OFFSET_FORWARDS might change depending on the selected
    // sessionType, because sessions have different lengths. Leaving this to services is a better idea to avoid unexpected errors.
    static readonly TUTOR_TIMESLOT_OFFSET_BACKWARDS = 1; // minutes.
    static readonly TUTOR_TIMESLOT_OFFSET_FORWARDS = 20; // minutes.
    static readonly TUTOR_PAGE_SIZE = 250;
    static readonly TUTOR_PAGE_SIZE_MULTIPLIER = 1.5; // used for fetching more tutors at once
    static readonly TUTOR_DATA_IDENTIFIER = "data-tutoraccountid"; // Tutor elements are identified by this data attribute

}

export interface SchedulerBindings {
    preSelectedDialogId: number; // Used for "Dialog based lesson" functionality
    preSelectedSession: Session | SessionV3 | SessionV4; // Session which is clicked on for "Book again" or "Reschedule"
    lessonActivity?: ActivityV1,
    isBookAgain: boolean; // Used for "Book again" functionality
    isReschedule: boolean; // Used for "Reschedule" functionality
    isBackEnabled: boolean; // Back button for going back and forth in between steps
    // Control props
    modalViewEnabled: boolean; // Modal view can be used to tweak some style changes so that component is aware that it was opened in a modal.
    stepsEnabled: boolean; // Steps mode is enabled with this prop. User follows steps to schedule a lesson if this is enabled.
    shouldFilterBookedTimeslots: boolean; // Already booked timeslots are filtered/not-filtered.
    shouldShowTimesOfDay: boolean; // Used for enabling/disabling "Early Morning", "Morning", "Evening" labels on top of timeslots.
    postScheduleRedirectionEnabled: boolean; // If this is true users get redirected to MyLessons page automatically, otherwise only modal closes itself.
    schedulerMode: string; // [SchedulerApp.MODE] Mode controls the fetchEligibility function. Used for optimization on web, and only switched to MODE.STANDALONE for TWA.
    // Other props
    eventComplete: EventEmitter<any>;
    eventClose: EventEmitter<void>;
    eventBack: EventEmitter<number>; // Triggered when back is clicked from scheduler. Emits the current step index
}
