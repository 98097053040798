import { AbstractEventFactory } from "./abstract";
import * as EventValidators from "../validator/index";
import { Activity } from "../../../../model/types/content/activity";

export class StartEventFactory extends AbstractEventFactory {
    constructor(protected accountId: number = 0) {
        super(accountId);

        let startCliplist = {
            eventType: "StartCliplist",
            validator: new EventValidators.StartCliplistValidator
        };
        let startQuiz = {
            eventType: "StartQuiz",
            validator: new EventValidators.StartQuizValidator
        };
        let startWatch = {
            eventType: "StartActivityWatch",
            validator: new EventValidators.StartActivityWatchValidator
        };

        this.eventTypeLookup[Activity.ACTIVITY_TYPE_ID_WATCH] = startWatch;
        this.eventTypeLookup[Activity.ACTIVITY_TYPE_ID_COMPREHENSION_QUIZ] = startWatch;
        this.eventTypeLookup[Activity.ACTIVITY_TYPE_ID_LEARN] = {
            eventType: "StartActivityLearn",
            validator: new EventValidators.StartActivityLearnValidator
        };
        this.eventTypeLookup[Activity.ACTIVITY_TYPE_ID_SPEAK] = {
            eventType: "StartActivitySpeak",
            validator: new EventValidators.StartActivitySpeakValidator
        };
        this.eventTypeLookup[Activity.ACTIVITY_CLIPLIST_NAMED_WATCH] = startCliplist;
        this.eventTypeLookup[Activity.ACTIVITY_CLIPLIST_NAMED_LEARN] = startCliplist;
        this.eventTypeLookup[Activity.ACTIVITY_CLIPLIST_NAMED_SPEAK] = startCliplist;
        this.eventTypeLookup[Activity.ACTIVITY_CLIPLIST_PRON_WATCH] = startCliplist;
        this.eventTypeLookup[Activity.ACTIVITY_CLIPLIST_PRON_SPEAK] = startCliplist;
        this.eventTypeLookup[Activity.ACTIVITY_CLIPLIST_VOCAB_WATCH] = startCliplist;
        this.eventTypeLookup[Activity.ACTIVITY_CLIPLIST_VOCAB_LEARN] = startCliplist;
        this.eventTypeLookup[Activity.ACTIVITY_CLIPLIST_VOCAB_SPEAK] = startCliplist;
        this.eventTypeLookup[Activity.ACTIVITY_CLIPLIST_DYNAMIC] = startCliplist;
        this.eventTypeLookup[Activity.ACTIVITY_TYPE_ID_QUIZ] = startQuiz;
        this.eventTypeLookup[Activity.ACTIVITY_TYPE_ID_COURSE_QUIZ] = startQuiz;
        this.eventTypeLookup[Activity.ACTIVITY_TYPE_ID_QUIZ_WORD] = startQuiz;
        this.eventTypeLookup[Activity.ACTIVITY_TYPE_ID_COURSE_QUIZ_CURATED] = startQuiz;
        this.eventTypeLookup[Activity.ACTIVITY_TYPE_ID_VOCABULARY_ASSESSMENT] = {
            eventType: "",
            validator: new EventValidators.StartActivityValidator
        };
    }
}
