import { NgModule } from "@angular/core";
import { FreelessonPromoAppModalComponent } from "./freelesson-promo-app-modal.component";
import { CoreModule } from "../../../core/core.module";

@NgModule({
    imports: [
        CoreModule
    ],
    declarations: [
        FreelessonPromoAppModalComponent
    ],
    exports: [
        FreelessonPromoAppModalComponent
    ]
})
export class FreelessonPromoAppModalModule {
}
