export enum SessionCallTypeId {
    SKYPE = 1,
    WEB_RTC = 2,
    PHONE = 3,
    ZOOM = 4,
    CHAT = 5, // Tutor can not speak under the name of the Chat-Bot (MiMi).
    // @INFO: CHAT_WIZARD_${any} session call types => Tutor is speaking with the student under the name of MiMi.
    // @TODO: Need to add a CHAT_WIZARD callTypeId here on services end. For now it is set to the same it with CHAT_WIZARD_MIMI
    CHAT_WIZARD = 6, // => Chat session does not have a Chat-Bot (MiMi)
    CHAT_WIZARD_MIMI = 6, // => Chat session has a Chat-Bot (MiMi),
    TEAMS = 7,  // Microsoft Teams
    AGORA = 8
}
