import { GEOLOCATION, isEuCountry } from "../core/geolocation";
import { clone, cloneDeep, get } from "lodash-es";
import { Partner } from "../model/types/partner";
import { AccountIdentity } from "../core/identity.service";
import { generateBooleanFromUnknown } from "../core/utility-functions";
import { Logger } from "../core/logger/logger";
import { Plan } from "../model/types/plan";
import { ActiveFeature } from "../model/types/active-feature";

const logger = new Logger();

export const PURCHASE_PROCESSOR_IYZICO: string = "iyzico";
export const PURCHASE_PROCESSOR_INIPAY: string = "inipay";
export const PURCHASE_PROCESSOR_VNPAY: string = "vnpay";
export const PURCHASE_PROCESSOR_PAYPAL: string = "paypal";
export const PURCHASE_PROCESSOR_CREDIT_CARD: string = "credit_card";
export const PURCHASE_PROCESSOR_STRIPE: string = "stripe";
export const PURCHASE_PROCESSOR_CIELO: string = "cielo";
export const PURCHASE_PROCESSOR_LANGRICH: string = "langrich";
export const TENDER_BANK_TRANSFER: string = "local_bank_transfer";

export enum PaymentMethod {
    PAYMENT_METHOD_CREDIT_OR_DEBIT_CARD = "paymentMethodCreditCardOrDebitCard",
    PAYMENT_METHOD_VNPAY = "paymentMethodVnpay",
    PAYMENT_METHOD_PAYPAL = "paymentMethodPaypal",
    PAYMENT_METHOD_VIETTEL = "paymentMethodViettel",
    PAYMENT_METHOD_MOBIFONE = "paymentMethodMobifone",
    PAYMENT_METHOD_BANK_TRANSFER = "paymentMethodBankTransfer"
}

export class PaymentRouterProps {
    routerCouponCodeId: string = undefined;
    routerPlanSelected: number = undefined; // planID
    routerLangrichOnly: boolean = false;
    routerLegacyPlansOnly: boolean = false;
    routerPerWeekPlansOnly: boolean = false;
}

export interface PaymentRegionConfig {
    installmentsEnabled: boolean;
    installmentsInfoEnabled: boolean;
    installmentsCustom: number[];
    termsAndConditionsEnabled: boolean;
    preSalesAgreementEnabled: boolean;
    creditOrDebitCardEnabled: boolean;
    paypalEnabled: boolean;
    billingInfoEnabled: boolean;
    userPlanExtensionEnabled: boolean; // If it is user's plan, underlying product prices stay the same, so that user can extend his/her product months. Refunds are not applied for products under user's plan.
    purchaseProcessor: string;
    transactionMethodSelectionEnabled: boolean; // debit/credit_card
    recurring: boolean;
    lowPriceProductFilteringEnabled: boolean;
    refundPricesEnabled: boolean;
    beforeTaxPricesEnabled: boolean;
    taxRateDisplayEnabled: boolean;
    taxRate: number; // 10% tax rate is applicable for only JP region. UI shows beforeTax and afterTax prices in different places,
    isBackButtonDisabled: boolean;
    isDiscountValidUntilTextDisabled: boolean;
    pricesBeforeCouponDiscountEnabled: boolean;
    goLiveDisabledDescEnabled: boolean; // this text shows under self-study indicating that goLive is disabled for this plan
    perLessonPricingEnabled: boolean;
    perWeekPlansEnabled: boolean;
    planTabsVerticalEnabled: boolean; // shows the plan tabs in vertical view
    filterProductsWithSameTimeSpan: boolean; // If true, filters the products which have the same monthly length (ex: 12months, 12months at the same time),
    bankTransferSupported: boolean;
}

export const overrideRegionConfigWithFeatures = (
    regionConfig: PaymentRegionConfig,
    features: Partial<ActiveFeature>
): PaymentRegionConfig => {
    let config = clone(regionConfig);
    if (generateBooleanFromUnknown(features?.inipayEnabled)) {
        config.recurring = false;
        config.purchaseProcessor = PURCHASE_PROCESSOR_INIPAY;
        config.paypalEnabled = false;
    }
    if (generateBooleanFromUnknown(features?.vnpayEnabled)) {
        config.purchaseProcessor = PURCHASE_PROCESSOR_VNPAY;
    }
    if (generateBooleanFromUnknown(features?.isStripePaymentEnabled)) {
        config.purchaseProcessor = PURCHASE_PROCESSOR_STRIPE;
    }
    if (generateBooleanFromUnknown(features?.isCieloEnabled)) {
        config.purchaseProcessor = PURCHASE_PROCESSOR_CIELO;
        config.installmentsEnabled = true;
        config.installmentsCustom = [2,3,6,9,12];
        config.paypalEnabled = false;
    }
    if (generateBooleanFromUnknown(features?.isProductPerLessonPricingEnabled)) {
        config.perLessonPricingEnabled = true;
    }
    if (generateBooleanFromUnknown(features?.isPerWeekPaymentPageEnabled)) {
        config.perWeekPlansEnabled = true;
        config.planTabsVerticalEnabled = true;
        config.perLessonPricingEnabled = true;
    }
    if (!generateBooleanFromUnknown(features?.filterProductsWithSameTimeSpan)) {
        config.filterProductsWithSameTimeSpan = false;
    }
    // @INFO: If config.perWeekPlansEnabled AND features?.planId is a not a perWeek plan,
    // @INFO: then turn the plan extensions off. Old plan holders should not see their plans
    // @INFO: as an extensions inside the per week plans.
    if (config.perWeekPlansEnabled) {
        const isNotPerWeekPlan = Plan.isAnyPerWeekPlan(
            features?.planName,
            features?.planId
        );
        if (!isNotPerWeekPlan) {
            config.userPlanExtensionEnabled = false;
        }
    }
    // @INFO: Recurring is overridden only if it is true. Important -> This is side-effect.
    // @INFO: This block should be the last block;
    if (config.recurring) {
        config.recurring = features?.isPaymentRecurringEnabled ?? false;
    }
    return config;
};

export const overrideRegionConfigForPartners = (regionConfig: PaymentRegionConfig, accountIdentity: AccountIdentity): PaymentRegionConfig => {
    const clonedConfig = cloneDeep(regionConfig);
    const primaryPartnerId = get(accountIdentity, "primaryPartnerID");

    if (primaryPartnerId == Partner.PARTNER_ID_TURKISH_AIRLINES) {
        clonedConfig.userPlanExtensionEnabled = false;
        clonedConfig.isDiscountValidUntilTextDisabled = true;
        logger.log("[Payment] Region config updated for TurkishAirlines partner...");
    }

    if (primaryPartnerId == Partner.PARTNER_ID_ROCKFELLER) {
        clonedConfig.userPlanExtensionEnabled = false;
        clonedConfig.isDiscountValidUntilTextDisabled = true;
        clonedConfig.pricesBeforeCouponDiscountEnabled = true;
        logger.log("[Payment] Region config updated for Rockfeller partner...");
    }

    return clonedConfig;
};

export const overrideRegionConfigWithRouterProps = (
    regionConfig: PaymentRegionConfig,
    routerProps?: Partial<PaymentRouterProps>
): PaymentRegionConfig => {
    let config = clone(regionConfig);
    if (routerProps?.routerPerWeekPlansOnly) {
        config.perWeekPlansEnabled = true;
        config.planTabsVerticalEnabled = true;
        config.perLessonPricingEnabled = true;
    }
    return config;
};

export const overrideRegionConfigWithAccountProps = (
    regionConfig: PaymentRegionConfig,
    accountIdentity: AccountIdentity
): PaymentRegionConfig => {
    let config = clone(regionConfig);
    // @INFO: Option to override the config with accountProps
    return config;
};

export const overrideRegionConfig = (
    regionConfig: PaymentRegionConfig,
    features: {[key: string]: any},
    accountIdentity: AccountIdentity,
    routerProps?: Partial<PaymentRouterProps>
): PaymentRegionConfig => {
    // IMPORTANT -> Sequence here is important
    // 1. Override plans with feature configurations
    // 2. Plan exclusions are uniquely applied for some partner accounts. Geolocation and feature based exclusions are overridden here.
    return overrideRegionConfigForPartners(
        overrideRegionConfigWithFeatures(
            overrideRegionConfigWithAccountProps(
                overrideRegionConfigWithRouterProps(
                    regionConfig,
                    routerProps
                ),
                accountIdentity
            ),
            features
        ),
        accountIdentity
    );
};

export const PAYMENT_CONFIG_DEFAULT: PaymentRegionConfig = {
    installmentsEnabled: false,
    installmentsInfoEnabled: false,
    installmentsCustom: [],
    termsAndConditionsEnabled: false,
    preSalesAgreementEnabled: false,
    paypalEnabled: true,
    creditOrDebitCardEnabled: true,
    billingInfoEnabled: false,
    transactionMethodSelectionEnabled: false,
    userPlanExtensionEnabled: true,
    recurring: true,
    lowPriceProductFilteringEnabled: true,
    refundPricesEnabled: true,
    beforeTaxPricesEnabled: false,
    taxRateDisplayEnabled: false,
    purchaseProcessor: PURCHASE_PROCESSOR_CREDIT_CARD,
    taxRate: 1,
    isBackButtonDisabled: false,
    isDiscountValidUntilTextDisabled: false,
    pricesBeforeCouponDiscountEnabled: true,
    goLiveDisabledDescEnabled: false,
    perLessonPricingEnabled: false,
    perWeekPlansEnabled: false,
    planTabsVerticalEnabled: false,
    filterProductsWithSameTimeSpan: true,
    bankTransferSupported: false
};

export class PaymentAppConfig {
    static readonly CONFIG: { [geolocation: string]: PaymentRegionConfig } = {
        [GEOLOCATION.TR]: {
            ...PAYMENT_CONFIG_DEFAULT,
            installmentsEnabled: true,
            installmentsInfoEnabled: true,
            termsAndConditionsEnabled: true,
            preSalesAgreementEnabled: true,
            paypalEnabled: false,
            billingInfoEnabled: true,
            transactionMethodSelectionEnabled: true,
            purchaseProcessor: PURCHASE_PROCESSOR_IYZICO,
            taxRate: 1.2
        },
        [GEOLOCATION.JP]: {
            ...PAYMENT_CONFIG_DEFAULT,
            beforeTaxPricesEnabled: true,
            goLiveDisabledDescEnabled: true,
            taxRate: 1.1
        },
        [GEOLOCATION.KR]: {
            ...PAYMENT_CONFIG_DEFAULT,
            recurring: false,
            filterProductsWithSameTimeSpan: false
        },
        [GEOLOCATION.EU]: PAYMENT_CONFIG_DEFAULT,
        [GEOLOCATION.VN]: {
            ...PAYMENT_CONFIG_DEFAULT,
            creditOrDebitCardEnabled: false,
            paypalEnabled: false,
            bankTransferSupported: true,
            purchaseProcessor: TENDER_BANK_TRANSFER
        }
    };

    static getConfigByGeolocation(geolocation: string = GEOLOCATION.DEFAULT): PaymentRegionConfig {
        const euCountryConfig = (isEuCountry(geolocation) && !PaymentAppConfig.CONFIG[geolocation])
            ? PaymentAppConfig.CONFIG[GEOLOCATION.EU]
            : undefined;
        return clone(
            euCountryConfig
            || PaymentAppConfig.CONFIG[geolocation]
            || PAYMENT_CONFIG_DEFAULT
        );
    }
}

export const isTenderPaypal = (tender: string): boolean => {
    return tender == PURCHASE_PROCESSOR_PAYPAL;
};

export const isTenderLangrich = (tender: string): boolean => {
    return tender == PURCHASE_PROCESSOR_LANGRICH;
};
