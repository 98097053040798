import { enableProdMode } from "@angular/core";

declare var PRODUCTION: boolean;

export const ecProductionMode = (): void => {
    if (!PRODUCTION) {
        return;
    }
    try {
        enableProdMode();
    } catch (exception) {
        console.error(exception);
    }
};
