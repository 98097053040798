import { AccountIdentity } from "../identity.service";
import { Instana } from "./vendors/instana";
import { InstrumentationReporter } from "./vendors/instrumentation-reporter";
import { appendMetaData } from "./instrumentation-utility";

export class Instrumentation {
    static readonly EVENT_PERFORMANCE = "Performance";
    private static reporters: InstrumentationReporter[] = [
        new Instana()
    ];

    static sendEvent(eventName: string,
                     additionalInfo: Record<string, any> = {},
                     duration?: number): void {
        Instrumentation.reporters.map(reporter => reporter.sendEvent(eventName, appendMetaData(additionalInfo), duration));
    }

    static reportError(error,
                       additionalInfo: Record<string, any> = {}): void {
        Instrumentation.reporters.map(reporter => reporter.reportError(error, appendMetaData(additionalInfo)));
    }

    static setCustomAttribute(key: string, value: string): void {
        Instrumentation.reporters.map(reporter => reporter.setCustomAttribute(key, value));
    }

    static setPageName(name: string): void {
        Instrumentation.reporters.map(reporter => reporter.setPageName(name));
    }

    static setAccountInfo(accountIdentity?: AccountIdentity): void {
        Instrumentation.reporters.map(reporter => reporter.setAccountInfo(accountIdentity));
    }
}
