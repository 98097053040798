export enum EventLabel {
    DEFAULT = "default",
    START = "start",
    SUCCESS = "success",
    ERROR = "error",
    // ... write previous categories and their children labels here
    // [0] Authentication
    LOGIN = "Login",
    REGISTER = "Register",
    // [9] Scheduler
    SCHEDULER_BOOK_FREE_LESSON = "SchedulerBookFreeLesson",
    SCHEDULER_OPTIONAL_PHONE_NUMBER_OPENED = "SchedulerOptionalPhoneNumberOpened",
    SCHEDULER_OPTIONAL_PHONE_NUMBER_SUBMITTED = "SchedulerOptionalPhoneNumberSubmitted",
    // ... write next categories and their children labels here
    // [15] Free Lesson Promo
    FL_PROMO_OPENED = "FLPromoOpened",
    FL_PROMO_CLOSED = "FLPromoClosed",
    FL_PROMO_REALTIME_SCHEDULED = "FLRealtimeScheduled",
    FL_PROMO_DEFAULT_SCHEDULED = "FLDefaultScheduled",
    FL_PROMO_REALTIME_ERROR = "FLPromoRealtimeError",
    // [16] Schedule or Realtime
    SR_OPENED = "SROpened",
    SR_REALTIME_CLICK = "SRRealtimeClick",
    SR_DEFAULT_CLICK = "SRDefaultClick",
    SR_REALTIME_SCHEDULED = "SRRealtimeScheduled",
    SR_DEFAULT_SCHEDULED = "SRDefaultScheduled",
    SR_REALTIME_TUTORS_AVAILABILITY = "SRRealtimeTutorsAvailability",
    // [17] Realtime
    REALTIME_SCHEDULED = "RealtimeScheduled",
    REALTIME_APP_INSTALL = "RealtimeAppInstall",
    // [18] MissedLessonThreshold
    MISSED_LESSON_THRESHOLD_OPENED = "MissedLessonThresholdOpened",
    MISSED_LESSON_REASON = "MissedLessonReason",
    // Marketing View Page
    CONTEXT = "Context",
    MARKETING = "Marketing"
}
