import { Injectable } from "@angular/core";
import { IdentityService } from "../../core/identity.service";
import { LocalForageGeneric } from "../../core/local-forage-generic";
import { ActivitySettingService } from "./activity-setting.service";
import { FeatureService } from "../../core/feature.service";
import { isEmpty, isString, keys, map, reduce, size } from "lodash-es";
import { differenceInDays } from "date-fns";
import { Logger } from "../../core/logger/logger";
import { GlobalSettingService } from "../../core/global-setting.service";

export type ActivityAccess = { [namespace: string]: { [targetId: string]: number } };

/* Example ActivityAccess stored with a key of user's identityId (for sake of easiness, it is stored with a
static key for now. Static key is NO_IDENTITY_ID)
{
    dialog: { 49284: Date.now() },
    vocabulary: { 38298: Date.now() }
}*/
export enum RegWallNamespace {
    DIALOG = "dialog",
    // other namespaces should be written here (Ex: vocabulary)
}

type RegWallOption = { dayLimit: number, accessCountLimit: number };
const REGWALL_OPTIONS: { [namespace: string]: RegWallOption } = {
    [RegWallNamespace.DIALOG]: {
        dayLimit: 30,
        accessCountLimit: 1
    }
};
const getRegwallOptions = (namespace: string): RegWallOption => {
    return REGWALL_OPTIONS[namespace];
};

export const CORPORATE_DIALOGS = [42578, 40202, 42042, 40129, 41951, 42040];

@Injectable({providedIn: "root"})
export class RegWallService {
    private regwallStorage = new LocalForageGeneric<ActivityAccess | string>("Regwall Service");
    private initialized = false;
    private logger = new Logger();
    static NO_IDENTITY_ID: string = "NO_IDENTITY_ID";

    constructor(private identityService: IdentityService,
                private activitySettingService: ActivitySettingService,
                private featureService: FeatureService,
                private globalSettingService: GlobalSettingService) {
    }

    // Initialization filters out the old accessed content. This should only be called once.
    initialize(): Promise<any> {
        if (!this.identityService.isAnonymous() || this.isInitialized()) {
            return Promise.resolve();
        }
        return this.regwallStorage.getItem(RegWallService.NO_IDENTITY_ID)
            .then((storedContent: string | ActivityAccess) => {
                const parsedContent = RegWallService.parseStoredContent(storedContent);
                if (isEmpty(parsedContent)) {
                    return undefined;
                }
                const namespaces = keys(parsedContent);
                const today = new Date(Date.now());
                // Filter out-of date content
                map(namespaces, (namespace) => {
                    const regWallOptions = getRegwallOptions(namespace);
                    parsedContent[namespace] = reduce(keys(parsedContent[namespace]), (acc, targetId) => {
                        const dateAccessed = new Date(parsedContent[namespace][targetId]);
                        if (differenceInDays(dateAccessed, today) <= regWallOptions.dayLimit) {
                            return {...acc, [targetId]: parsedContent[namespace][targetId]};
                        }
                        return acc;
                    }, {});
                });
                return parsedContent;
            })
            .then((parsedContent) => parsedContent ? this.regwallStorage.setItem(RegWallService.NO_IDENTITY_ID, JSON.stringify(parsedContent)) : Promise.resolve(undefined))
            .then(() => this.initialized = true)
            .catch(() => undefined);
    }

    getStoredContentCount(namespace: RegWallNamespace): Promise<number> {
        return this.regwallStorage.getItem(RegWallService.NO_IDENTITY_ID)
            .then((storedContent) => {
                const parsedContent = RegWallService.parseStoredContent(storedContent);
                if (!parsedContent || !parsedContent[namespace]) {
                    return 0;
                }
                return size(keys(parsedContent[namespace]));
            });
    }

    setAccessLog(namespace: RegWallNamespace, targetId: number): Promise<ActivityAccess | string> {
        if (!this.identityService.isAnonymous()) {
            this.logger.log("[Regwall] User is logged-in, by-passing adding content to regwall accesslog");
            return Promise.resolve(undefined);
        }
        return this.regwallStorage.getItem(RegWallService.NO_IDENTITY_ID)
            .then((storedContent) => {
                const parsedContent = RegWallService.parseStoredContent(storedContent);
                if (!parsedContent[namespace]) {
                    this.logger.log("[Regwall] No namespace exists for current activity, creating namespace...");
                    parsedContent[namespace] = {};
                }
                parsedContent[namespace][targetId] = Date.now();
                this.logger.log(`[Regwall] Setting content regwall with id ${targetId}`);
                return this.regwallStorage.setItem(RegWallService.NO_IDENTITY_ID, JSON.stringify(parsedContent));
            });
    }

    isAccessAllowed(namespace: RegWallNamespace, targetId: number): Promise<boolean> {
        // @FIXME: Remove all activitySettingService related checks from here. Regwall should always stay at a higher level control
        if (this.activitySettingService.get("bypassRegwall") ||
            this.globalSettingService.get("isBot") ||
            !this.identityService.isAnonymous() ||
            this.activitySettingService.getSettings().cmsMode ||
            this.activitySettingService.getSettings().hideRegwallByConsumerKey ||
            this.isCorporateDialog(targetId)
        ) {
            this.logger.log("[Regwall] Regwall is allowed without checking content access...");
            return Promise.resolve(true);
        }
        return this.regwallStorage.getItem(RegWallService.NO_IDENTITY_ID)
            .then((storedContent: string | ActivityAccess) => {
                const parsedContent = RegWallService.parseStoredContent(storedContent);
                // If the content has accessed before, allow access again
                if (parsedContent && parsedContent[namespace] && parsedContent[namespace][targetId]) {
                    this.logger.log("[Regwall] Content access is allowed since this is a previously accessed content");
                    return true;
                }
                // Filter out-of date content
                const regWallOptions = getRegwallOptions(namespace);
                const accessedContent = keys(parsedContent[namespace] || {});
                const accessedContentCount = size(accessedContent);
                let accessCountLimit = regWallOptions.accessCountLimit;
                if (namespace == RegWallNamespace.DIALOG) {
                    accessCountLimit = this.featureService.getFeature("playerRegwallLimit");
                }
                return (accessCountLimit == 0) || accessedContentCount < accessCountLimit;
            })
            .catch((err) => {
                this.logger.error("[Regwall] Regwall content access check failed...", err);
                return false;
            });
    }

    private isInitialized(): boolean {
        return this.initialized;
    }

    static parseStoredContent(storedContent: string | ActivityAccess): ActivityAccess {
        if (!storedContent) {
            return {} as ActivityAccess;
        }
        return isString(storedContent) ? JSON.parse(storedContent) : storedContent;
    }

    private isCorporateDialog(dialogId: number): boolean {
        return CORPORATE_DIALOGS.includes(dialogId);
    }
}
