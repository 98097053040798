import { TrackerLogger } from "../tracker.logger";
import { Tracker, TrackerName } from "../tracker";

declare var ytag: any;

export class YahooTracker extends TrackerLogger implements Tracker {

    static CONVERSION_ID = "1001192522";
    static CONVERSION_LABEL = "ZrTOCJ2LiI0CENbT8boB";
    static CONVERSION_VALUE = "0";

    constructor(private config: any,
                private isProductionMode: boolean = false) {
        super(TrackerName.YAHOO, isProductionMode);
    }

    isEnabled(): boolean {
        return typeof ytag !== "undefined";
    }

    trackConversion(): void {
        if (!this.isEnabled()) {
            return;
        }

        ytag({
            "type": "yss_conversion",
            "config": {
                "yahoo_conversion_id": YahooTracker.CONVERSION_ID,
                "yahoo_conversion_label": YahooTracker.CONVERSION_LABEL,
                "yahoo_conversion_value": YahooTracker.CONVERSION_VALUE
            }
        });
    }

    /* eslint-disable */
    init(): void {
        "use strict";
        this.insertScript(window, document, "script", `https://s.yimg.jp/images/listing/tool/cv/ytag.js`);
        (<any>window).yjDataLayer = (<any>window).yjDataLayer || [];
        (<any>window).ytag = function () { (<any>window).yjDataLayer.push(arguments); };
        ytag({"type":"ycl_cookie"});
    }

    private insertScript(i, s, o, g): void {
        var a, m;
        a = s.createElement(o);
        m = s.getElementsByTagName(o)[0];
        a.async = 1;
        a.src = g;
        m.parentNode.insertBefore(a, m);
    }
    /* eslint-enable */

    trackPageView(): void {
    }

    trackEvent(): void {
    }
}
