import { Injectable } from "@angular/core";
import { Logger } from "../logger/logger";
import { Instrumentation } from "./instrumentation";

declare const EC_PERF_START: number;
const logger = new Logger();
/*
future class to encapsulate NREUM instrumentation logic
 */
@Injectable({providedIn: "root"})
export class InstrumentationService {
    private angularStartTime?: number;
    private routeStartTime?: number;
    private totalRouteTimeSent: boolean = false;
    private recordedRoutes: Set<string> = new Set();

    constructor() {
    }

    setAngularStartTime(): void {
        if (this.angularStartTime) {
            return;
        }
        this.angularStartTime = new Date().getTime();
    }

    setRouteStartTime(): void {
        this.routeStartTime = new Date().getTime();
    }

    sendRouteLoadingTime(route: string): void {
        try {
            if (!EC_PERF_START || this.recordedRoutes.has(route)) {
                return;
            }

            let metrics = {
                angularStartTime: this.angularStartTime - EC_PERF_START,
                route: route
            };

            if (!this.totalRouteTimeSent) {
                metrics["routeTotalLoadingTime"] = new Date().getTime() - EC_PERF_START;
                this.totalRouteTimeSent = true;
            }

            this.recordedRoutes.add(route);
            Instrumentation.sendEvent("loading-time", metrics, new Date().getTime() - this.routeStartTime);
        } catch (error) {
            logger.error(error);
        }
    }
}
