import { Logger } from "./logger/logger";
import { HttpErrorResponse } from "@angular/common/http";
import { mergeMap, retryWhen } from "rxjs/operators";
import { from, Observable, throwError, timer } from "rxjs";
import { EcCustomErrorParsed } from "../model/types/reportcard/service-response";
import { includes } from "lodash-es";

const logger = new Logger();

// Excluded Error Status Codes -
export const UNRECOVERABLE_STATUS_CODES = [400, 401, 402, 403, 405, 406, 407, 409, 410, 500];
export const STATUS_NOT_ALLOWED = 405;
export const STATUS_PROCESSING = 202;
export const STATUS_CONFLICT = 409;

// Retry Strategy - Default constants
const MAX_RETRIES = 3;
const BASE_RETRY_SECS = 2.5;

export const parseServiceResponse = (error): EcCustomErrorParsed | undefined => {
    if (!error || !error.error) {
        return undefined;
    }

    try {
        return JSON.parse(error.error);
    } catch {
        return undefined;
    }
};

export const retryStrategy = <T>(maxRetryAttempts: number = MAX_RETRIES,
    baseRetrySecs: number = BASE_RETRY_SECS,
    errorInterceptorFn?: (error) => boolean) => (attempts: Observable<T>) => {
        return attempts.pipe(
            mergeMap((error, i: number) => {
                const retryAttempt = i + 1;

                let serviceResponse = parseServiceResponse(error);

                // if maximum number of retries have been met
                if (retryAttempt > maxRetryAttempts) {
                    return throwError(error);
                }

                if (errorInterceptorFn && errorInterceptorFn(error)) {
                    return throwError(error);
                }

                let retryDuration = Math.round(baseRetrySecs * Math.pow(2, retryAttempt - 1)) * 1000;
                if (serviceResponse && serviceResponse.fallback && serviceResponse.retryAfterSeconds) {
                    retryDuration = serviceResponse.retryAfterSeconds;
                }
                logger.log(`Attempt ${retryAttempt}: retrying in ${retryDuration}ms`);

                return timer(retryDuration);
            })
        );
    };

export const servicesRetryStrategy = <T>(maxRetryAttempts: number = MAX_RETRIES,
    baseRetrySecs: number = BASE_RETRY_SECS) => {
    return retryStrategy(maxRetryAttempts, baseRetrySecs, (error: HttpErrorResponse) => {
        if (!error || !("status" in error)) {
            return false;
        }

        return includes(UNRECOVERABLE_STATUS_CODES, error.status);
    });
};

export const servicesNotAllowedRetryStrategy = <T>(errorKeyCheckFunction: (errorKey) => boolean,
    maxRetryAttempts: number = MAX_RETRIES,
    baseRetrySecs: number = BASE_RETRY_SECS) => {
    return retryStrategy(maxRetryAttempts, baseRetrySecs, (error: HttpErrorResponse) => {
        if (!error || !("status" in error)) {
            return false;
        }

        let serviceResponse = parseServiceResponse(error);
        if (serviceResponse && serviceResponse.key) {
            if (errorKeyCheckFunction(serviceResponse.key)) {
                return true;
            }
        }

        return includes(UNRECOVERABLE_STATUS_CODES, error.status);
    });
};

export const classReportServicesRetryStrategy = <T>(maxRetryAttempts: number = MAX_RETRIES,
    baseRetrySecs: number = BASE_RETRY_SECS,
    interceptFn?: (error) => void) => {
    return retryStrategy(maxRetryAttempts, baseRetrySecs, (error: HttpErrorResponse) => {
        if (interceptFn) {
            interceptFn(error);
        }

        if (!error || !("status" in error)) {
            return false;
        }

        let serviceResponse = parseServiceResponse(error);

        if (serviceResponse && serviceResponse.log) {
            const completionPercentage = parseInt(serviceResponse.log);
            // It should be equal to 100 in order to fetch cached report with another iteration
            return completionPercentage <= 100;
        }

        return includes(UNRECOVERABLE_STATUS_CODES, error.status);
    });
};

export const reportCardEventsRetryStrategy = <T>(maxRetryAttempts: number = MAX_RETRIES,
    baseRetrySecs: number = BASE_RETRY_SECS) => {
    return retryStrategy(maxRetryAttempts, baseRetrySecs, (error: HttpErrorResponse) => {
        if (!error || !("status" in error)) {
            return false;
        }

        return includes(UNRECOVERABLE_STATUS_CODES, error.status) || error.status == 404;
    });
};

export const servicesRetryFallbackStrategy = <T>(maxRetryAttempts: number = MAX_RETRIES,
    baseRetrySecs: number = BASE_RETRY_SECS) => {
    return retryStrategy(maxRetryAttempts, baseRetrySecs, (error: HttpErrorResponse) => {
        let serviceResponse = parseServiceResponse(error);

        return serviceResponse && serviceResponse.fallback;
    });
};

export const retryWhenRetryEnabledStrategy = <T>(maxRetryAttempts: number = MAX_RETRIES,
    baseRetrySecs: number = BASE_RETRY_SECS) => {
    return retryStrategy(maxRetryAttempts, baseRetrySecs, (error: HttpErrorResponse) => {
        if (!error || !("status" in error)) {
            return false;
        }
        return ("retry" in error);
    });
};

export class RetryHelper {
    static retryObservable<T>(observable: Observable<T>): Observable<T> {
        return observable.pipe(
            retryWhen(retryStrategy())
        );
    }

    static retryPromise<T>(promise: Promise<T>): Promise<T> {
        return RetryHelper.retryObservable(from(promise)).toPromise();
    }
}
