import { isUndefined, reduce } from "lodash-es";


export class CacheAbstract<T> {
    protected generateExpiration(expirationMs?: number) {
        if (!expirationMs) {
            return undefined;
        }
        return new Date().getTime() + expirationMs;
    }

    protected generateHash(params: object): string {
        const filteredParams = reduce(params, (acc, value, key) => {
            if (!isUndefined(value)) {
                acc[key] = value;
            }
            return acc;
        }, {});
        return "EC_" + JSON.stringify(filteredParams);
    }
}
