import { ROUTE_PARTNER_GLOBED } from "../../../routes/routes.partners";
import { PartnerConfig } from "./partner-config";

export const GLOBED_CONFIG: PartnerConfig = {
    route: ROUTE_PARTNER_GLOBED,
    styleClassName: "partner-globed",
    allowRedirectionToMyRoutes: true,
    disableFooter: true,
    disableHeaderRightNavs: true,
    disableBrowseVideosLink: true,
    disableBrowseCoursesLink: true,
    disableAnonCourseAccess: true
};
