import { AbstractEventFactory } from "./abstract";
import * as EventValidators from "../validator/index";
import { Activity } from "../../../../model/types/content/activity";

export class DialogLineEventFactory extends AbstractEventFactory {
    constructor(protected accountId: number = 0) {
        super(accountId);

        let dialogLineWatchCliplist = {
            eventType: "DialogLineWatchCliplist",
            validator: new EventValidators.DialogLineWatchCliplistValidator
        };
        let dialogLineSpeakCliplist = {
            eventType: "DialogLineSpeakCliplist",
            validator: new EventValidators.DialogLineSpeakCliplistValidator
        };
        let dialogLineWatch = {
            eventType: "DialogLineWatch",
            validator: new EventValidators.DialogLineWatchValidator
        };
        this.eventTypeLookup[Activity.ACTIVITY_TYPE_ID_WATCH] = dialogLineWatch;
        this.eventTypeLookup[Activity.ACTIVITY_TYPE_ID_COMPREHENSION_QUIZ] = dialogLineWatch;
        this.eventTypeLookup[Activity.ACTIVITY_TYPE_ID_SPEAK] = {
            eventType: "DialogLineSpeak",
            validator: new EventValidators.DialogLineSpeakValidator
        };
        this.eventTypeLookup[Activity.ACTIVITY_CLIPLIST_NAMED_WATCH] = dialogLineWatchCliplist;
        this.eventTypeLookup[Activity.ACTIVITY_CLIPLIST_NAMED_SPEAK] = dialogLineSpeakCliplist;
        this.eventTypeLookup[Activity.ACTIVITY_CLIPLIST_PRON_WATCH] = dialogLineWatchCliplist;
        this.eventTypeLookup[Activity.ACTIVITY_CLIPLIST_PRON_SPEAK] = dialogLineSpeakCliplist;
        this.eventTypeLookup[Activity.ACTIVITY_CLIPLIST_VOCAB_WATCH] = dialogLineWatchCliplist;
        this.eventTypeLookup[Activity.ACTIVITY_CLIPLIST_VOCAB_SPEAK] = dialogLineSpeakCliplist;
        this.eventTypeLookup[Activity.ACTIVITY_CLIPLIST_DYNAMIC] = dialogLineSpeakCliplist;
    }
}

