import { Injectable } from "@angular/core";
import {
    NOTIFICATION_PERMISSION_DENIED,
    NOTIFICATION_PERMISSION_GRANTED,
    Permission
} from "../../model/types/push-notifications";
import { Browser } from "../../core/browser";
import { Logger } from "../../core/logger/logger";
import { Instrumentation } from "../../core/instrumentation/instrumentation";
import { Dictionary } from "../../model/types/dictionary";

declare const Notification: any;

@Injectable({
    providedIn: "root"
})
export class PushNotificationService {
    private logger = new Logger();
    static OPTION_ICON: string = require("global-assets/icons/ec-icon.png");
    static OPTION_DIR: string = "ltr";
    static SERVICE_WORKER_PATH: string = "/firebase-messaging-sw.js";
    static EVENT_TYPE_SHOW = "show";
    static EVENT_TYPE_CLICK = "click";
    permission: Permission;

    constructor() {
        this.permission  = this.isSupported() ? Notification.permission : NOTIFICATION_PERMISSION_DENIED;
    }

    isSupported(): boolean {
        return "Notification" in window;
    }

    requestPermission(): Promise<NotificationPermission> {
        if (!this.isSupported()) {
            return Promise.reject("Notifications are not available in this browser");
        }

        return Notification.requestPermission((status: any) => {
            this.permission = status;
            return status;
        }).then((status: any) => {
            this.permission = status;
            return status;
        });
    }

    isPermissionDenied(): boolean {
        return this.permission === NOTIFICATION_PERMISSION_DENIED;
    }

    isPermissionGranted(): boolean {
        return this.permission === NOTIFICATION_PERMISSION_GRANTED;
    }

    isPermissionToggled(): boolean {
        if (Browser.isChrome() && Browser.isMac()) {
            return this.isPermissionGranted();
        }

        return this.isPermissionGranted() || this.isPermissionDenied();
    }

    track(tracking: Dictionary<any>): void {
        Instrumentation.sendEvent("PushNotification", tracking);
    }

    registerServiceWorker(): Promise<PushSubscription> {
        return navigator.serviceWorker.register(PushNotificationService.SERVICE_WORKER_PATH)
            .then((registration) => {
                const subscribeOptions = {
                    userVisibleOnly: true
                };
                return registration.pushManager.subscribe(subscribeOptions);
            })
            .catch((e) => {
                this.logger.error("PushSubscription failed", e);
                throw e;
            })
            .then((pushSubscription) => {
                this.logger.log("Received PushSubscription: ", pushSubscription);
                return pushSubscription;
            });
    }
}
