import { Injectable } from "@angular/core";
import { Observable, Subscription } from "rxjs";
import { Emitter } from "../core/emitters/emitter";
import { Logger } from "../core/logger/logger";
import { GlobalNotification } from "../model/types/notification";

@Injectable({ providedIn: "root" })
export class GlobalNotificationAppService {
    private emitter = new Emitter(true);
    private logger = new Logger();
    static readonly EVENT_SEND_NOTIFICATION: string = "EVENT_SEND_NOTIFICATION";
    static readonly EVENT_CLEAR_NOTIFICATION: string = "EVENT_CLEAR_NOTIFICATION";
    constructor() {
    }

    send(notification: GlobalNotification.BaseNotification): void {
        if (!notification) {
            this.logger.log("Notification type is incorrect");
        }
        this.publish(GlobalNotificationAppService.EVENT_SEND_NOTIFICATION, notification);
    }

    clear(): void {
        this.publish(GlobalNotificationAppService.EVENT_CLEAR_NOTIFICATION);
    }

    subscribe(eventName: string, successFn: (data?) => void, errorFn?: (e?) => void): Subscription {
        return this.emitter.subscribe(eventName, successFn, errorFn);
    }

    publish(eventName: string, data?: any): void {
        this.emitter.publish(eventName, data);
    }

    getObservable(eventName: string): Observable<any> {
        return this.emitter.getObservable(eventName)
            .asObservable();
    }

    destroy(): void {
        this.emitter.destroy();
    }
}
