import { Injectable } from "@angular/core";
import { ConnectionFactoryService } from "../../core/connection-factory.service";
import { Observable } from "rxjs";
import { DailyGoalStreak } from "../types/daily-goal";
import { AccountProgressSummary } from "../types/account-progress-summary";
import { AccountProgress } from "../types/account-progress";
import { LevelUpCriteria } from "../types/level-up-criteria";

export interface AccountProgressLast7DaysParams {
    accountID: number;
    timeZone: string; // GMT
}

export interface AccountStreaksParams {
    numOfDays: number;
    timeZone: string;
}

export interface AccountProgressParams {
    fromDate: string;
    toDate: string;
}

export interface AccountLevelProgressParams {
    accountID?: number; // Works only with superuser role
    difficultyLevel: number;
}

@Injectable({
    providedIn: "root"
})
export class AccountProgressModelService {
    constructor(private connection: ConnectionFactoryService) {
    }

    getAccountProgress(accountId: number, params: AccountProgressParams): Observable<AccountProgress> {
        return this.connection
            .service("reportcard")
            .setPath(`/progress/account/${accountId}`)
            .get(params);
    }

    getAccountProgressLast7Days(params: AccountProgressLast7DaysParams): Observable<AccountProgressSummary> {
        return this.connection
            .service("reportcard")
            .setPath("/report/account/progress/summary")
            .get(params);
    }

    getAccountLevelProgress(params?: AccountLevelProgressParams): Observable<LevelUpCriteria[]> {
        return this.connection
            .service("reportcard")
            .setPath("/report/account/level/progress")
            .get(params);
    }

    getAccountStreaks(accountId: number, params: AccountStreaksParams): Observable<DailyGoalStreak> {
        return this.connection
            .service("reportcard")
            .setPath(`/report/dailygoal/account/${accountId}/streak`)
            .get(params);
    }

    getAccountPointsToday(accountId: number, params: { timeZone: string }): Observable<number> {
        return this.connection
            .service("reportcard")
            .setPath(`/report/dailygoal/account/${accountId}/today`)
            .get(params);
    }

    sendDailyGoalCompletion(params: { timeZone: string, date: string }): Observable<void> {
        return this.connection
            .service("reportcard")
            .setPath(`/report/dailygoal/account/completeDay`)
            .post(params);
    }
}
