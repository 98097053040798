import { TrackerLogger } from "../tracker.logger";
import { Tracker, TrackerName } from "../tracker";

declare var clarity: any;

export class ClarityTracker extends TrackerLogger implements Tracker {

    constructor(private config: any,
                private isProductionMode: boolean = false) {
        super(TrackerName.CLARITY, isProductionMode);
    }

    isEnabled(): boolean {
        return typeof clarity !== "undefined";
    }

    /* eslint-disable */
    init(): void {
        "use strict";
        this.insertScript(window, document, "clarity", "script", this.config?.id);
    }

    private insertScript(c, l, a, r, i, t?, y?): void {
        c[a] = c[a] || function () {
            (c[a].q = c[a].q || []).push(arguments);
        };
        t = l.createElement(r);
        t.async = 1;
        t.src = "https://www.clarity.ms/tag/" + i;
        y = l.getElementsByTagName(r)[0];
        y.parentNode.insertBefore(t, y);
    }
    /* eslint-enable */

    trackPageView(): void {
    }

    trackEvent(): void {
    }
}
