import { Injectable } from "@angular/core";
import { AccountLessonEligibility, LessonEligibility } from "../model/types/account-lesson-eligibility";
import { Observable, of } from "rxjs";
import { TutorAccountModelService } from "../model/tutor/tutor-account-model.service";
import { IdentityService } from "./identity.service";
import { catchError, finalize, share, tap } from "rxjs/operators";
import { FeatureService } from "./feature.service";
import { Plan } from "../model/types/plan";
import { filter, find, get, isEmpty, isUndefined, keys, reduce, size, some } from "lodash-es";
import { Logger } from "./logger/logger";
import { SchedulerApp } from "../pwa-v2-landing-app/modules/scheduler-app-v2/scheduler-app.helper";
import { SessionStatus } from "../model/types/tutor/session-status";
import { SessionType } from "../model/types/tutor/session-type";
import { WebRtcVendor } from "../model/types/session-communication-tool";
import { getUserMediaEnabled, isRtcPeerConnectionEnabled } from "./browser-navigator";
import { SessionCallChannel } from "../model/types/session-call-channel";

@Injectable({providedIn: "root"})
export class EligibilityService {
    private eligibility: AccountLessonEligibility;
    private eligibleSessionTypes: string[] = [];
    private totalXtraCreditsCount: number = 0;
    private initialized: boolean = false;
    private loading: boolean = false;

    // Logger
    private logger = new Logger();

    // Control props
    private eligibleSessionTypesUpdatedWithFeatures = false;

    // Fetch controls
    private eligibilityHydration$: Observable<AccountLessonEligibility | undefined>;

    constructor(
        private identityService: IdentityService,
        private featureService: FeatureService,
        private tutorAccountModelService: TutorAccountModelService
    ) {
    }

    initialize(): Observable<AccountLessonEligibility> {
        let observable = this.initialized ? of(this.eligibility) : this.initializeEligibility();
        return observable.pipe(
            share(),
            tap(() => this.initialized = true)
        );
    }

    getEligibility(): AccountLessonEligibility | undefined {
        return isEmpty(this.eligibility) ? undefined : this.eligibility;
    }

    getEligibleSessionTypes(): string[] {
        return this.eligibleSessionTypes;
    }

    getTotalLessonsAvailable(): number {
        return get(this.eligibility, "totalLessonsAvailable", 0);
    }

    getEligibilityBySessionType(sessionType: string): LessonEligibility {
        return get(this.eligibility, `lessonEligibility.${sessionType}`);
    }

    getXtraCreditsBySessionType(sessionType: string): number {
        return get(this.getEligibilityBySessionType(sessionType), "xtraCredits", 0);
    }

    getCreditsBySessionType(sessionType: string): number {
        return get(this.getEligibilityBySessionType(sessionType), "credits", 0);
    }

    getTotalXtraCreditsCount(): number {
        return this.totalXtraCreditsCount;
    }

    getGivenLimitLeftBySessionType(sessionType: string): number {
        return get(this.getEligibilityBySessionType(sessionType), "givenLimitLeft", 0);
    }

    getDailyLimitLeftBySessionType(sessionType: string): number {
        return get(this.getEligibilityBySessionType(sessionType), "dailyLimitLeft", 0);
    }

    getDateLimitRefreshBySessionType(sessionType: string): number | undefined {
        return get(this.getEligibilityBySessionType(sessionType), "dateLimitRefresh", undefined);
    }

    getEligibilityReasonsBySessionType(sessionType: string): string {
        return get(this.getEligibilityBySessionType(sessionType), "eligibilityReasons", "");
    }

    isEligibilityInitialized(): boolean {
        return this.initialized;
    }

    isGoLiveEligible(): boolean {
        return this.featureService.isGoLiveEnabled() && this.isEligible(SessionType.TYPE_GL);
    }

    isLevelTestEligible(): boolean {
        return this.featureService.isLevelTestEnabled() && this.isEligible(SessionType.TYPE_LT);
    }

    isLevelUpTestEligible(): boolean {
        return this.isEligible(SessionType.TYPE_LUT);
    }

    isOpenTalkEligible(): boolean {
        return this.isEligible(SessionType.TYPE_OT);
    }

    isFreeLessonEligible(): boolean {
        return this.isLevelTestEligible() && Plan.isBasic(this.featureService.getFeature("planName"));
    }

    isFreeLessonCompleted(): boolean {
        return this.getEligibility()?.lessonEligibility[SessionType.TYPE_LT]?.status == SessionStatus.STATUS_COMPLETED;
    }

    isFreeLessonScheduled(): boolean {
        return this.getEligibility()?.lessonEligibility[SessionType.TYPE_LT]?.status == SessionStatus.STATUS_SCHEDULED;
    }

    isFreeLessonReportPending(): boolean {
        return this.getEligibility()?.lessonEligibility[SessionType.TYPE_LT]?.status == SessionStatus.STATUS_REPORT_PENDING;
    }

    isGoLivePlanAvailable(): boolean {
        const plans = this.featureService.getFeature("paymentPlans");
        const paymentPlans = plans?.split(",").map(plan => +plan) ?? [];
        return some(paymentPlans, (planId) => Plan.isGoLiveEligiblePlan(planId));
    }

    isEligible(sessionType?: string): boolean {
        // true if eligible for any lesson type including CH
        if (!isEmpty(sessionType)) {
            if (this.isEligibilityProhibitedByFeatureKnob(sessionType)) {
                return false;
            }
            return get(this.eligibility, `lessonEligibility.${sessionType}.isEligible`, false);
        }
        return !!size(this.eligibleSessionTypes);
    }

    isEligibleToSchedule(): boolean {
        // true if user is eligible for any scheduler topic type (even one type suffices - schedulerTopicTypes exclude CH)
        return SchedulerApp.schedulerTopicTypes.some(schedulerTopicType => this.isEligible(schedulerTopicType));
    }

    isEligibleToJoinGroupLessons(): boolean {
        return this.eligibility.lessonEligibility[SessionType.TYPE_OT]?.eligibleGroup ?? false;
    }

    isEligibilityProhibitedByFeatureKnob(sessionType: string): boolean {
        return (sessionType === SessionType.TYPE_GL && !this.featureService.isGoLiveEnabled())
            || (sessionType === SessionType.TYPE_LT && !this.featureService.isLevelTestEnabled());
    }

    isLoading(): boolean {
        return this.loading;
    }

    hasXtraLessonCredits(sessionType?: string): boolean {
        if (sessionType) {
            return this.isEligible(sessionType) && this.getXtraCreditsBySessionType(sessionType) > 0;
        }
        return this.isEligible() && some(this.eligibleSessionTypes, (eligibleSessionType: string) => {
            return this.getXtraCreditsBySessionType(eligibleSessionType) > 0;
        });
    }

    initializeEligibility(shouldForceHydrate = false): Observable<AccountLessonEligibility | undefined> {
        if (this.identityService.isAnonymous()) {
            return of(undefined);
        }
        if (!shouldForceHydrate && !isUndefined(this.eligibilityHydration$)) {
            return this.eligibilityHydration$;
        }
        this.eligibilityHydration$ = this.tutorAccountModelService
            .getEligibility({
                timeZone: this.identityService.getTimezone(),
                fields: "dateLimitRefresh"
            }, shouldForceHydrate)
            .pipe(
                tap(() => {
                    this.loading = true;
                }),
                share(),
                catchError(() => {
                    this.logger.error("Eligibility returned with error...");
                    return of(undefined);
                }),
                tap((accountLessonEligibility: AccountLessonEligibility) => {
                    this.eligibility = accountLessonEligibility;
                    const lessonEligibility = get(this.eligibility, "lessonEligibility", {});
                    if (!isEmpty(lessonEligibility)) {
                        const lessonEligibilityKeys = keys(lessonEligibility); // aka: SessionTypes
                        this.eligibleSessionTypes = reduce(lessonEligibilityKeys, (acc, sessionType: string) => {
                            const isEligible = get(lessonEligibility, `${sessionType}.isEligible`);
                            if (isEligible) {
                                return acc.concat([sessionType]);
                            }
                            return acc;
                        }, []);
                        if (this.eligibleSessionTypesUpdatedWithFeatures) {
                            this.updateEligibleSessionTypesAfterFeatures();
                        }
                        const lessonEligibilityKey = find(lessonEligibilityKeys, (sessionType: string) => {
                            return this.getXtraCreditsBySessionType(sessionType) > 0;
                        });

                        this.totalXtraCreditsCount = this.getXtraCreditsBySessionType(lessonEligibilityKey);
                    }
                }),
                tap(() => this.eligibilityHydration$ = undefined),
                finalize(() => this.loading = false)
            );
        return this.eligibilityHydration$;
    }

    updateEligibleSessionTypesAfterFeatures(): void {
        this.eligibleSessionTypes = filter(this.eligibleSessionTypes, (sessionType) => !this.isEligibilityProhibitedByFeatureKnob(sessionType));
        this.eligibleSessionTypesUpdatedWithFeatures = true;
    }

    isVideoTutoringEnabled(): boolean {
        const vendors: string[] = [SessionCallChannel.ZOOM, SessionCallChannel.TEAMS, WebRtcVendor.TWILIO];
        const useThirdPartyRtcVendor = vendors.includes(this.featureService.getFeature("rtcVendor"));
        return this.identityService.isUseWebrtc() && (getUserMediaEnabled() &&
            isRtcPeerConnectionEnabled() || useThirdPartyRtcVendor);
    }

    getAvailableLessonCount(): number {
        if (!this.isEligibilityInitialized()) {
            return 0;
        }
        const totalLessonsAvailable = this.getEligibilityBySessionType(SessionType.TYPE_GL)?.lessonsAvailable + this.getLevelTestLessonsAvailableCount();
        if (this.featureService.getFeature("useLessonsThisWeekInEligibilityWidget")
            && Plan.isAnyPerWeekPlan(undefined, this.featureService.getFeature("planId"))) {
            return Math.max(this.getGlLessonsThisWeek() + this.getLevelTestLessonsAvailableCount(), totalLessonsAvailable);
        }
        return totalLessonsAvailable;
    }

    getLevelTestLessonsAvailableCount(): number {
        if (!this.isEligibilityInitialized()) {
            return 0;
        }
        const ltLessonsAvailable = this.isLevelTestEligible()
            ? get(this.getEligibilityBySessionType(SessionType.TYPE_LT), "lessonsAvailable", 0)
            : 0;
        const lutLessonsAvailable = this.isLevelUpTestEligible()
            ? get(this.getEligibilityBySessionType(SessionType.TYPE_LUT), "lessonsAvailable", 0)
            : 0;
        return ltLessonsAvailable + lutLessonsAvailable;
    }

    getGlLessonsThisWeek(): number {
        if (!this.isEligibilityInitialized()) {
            return 0;
        }
        // For per-week plans. This refers to lessons remaining until the weekly refresh date
        const lessonsThisWeek: number = get(this.getEligibilityBySessionType(SessionType.TYPE_GL), "lessonsThisWeek");
        return lessonsThisWeek < 0 ? 0 : lessonsThisWeek;
    }
}
