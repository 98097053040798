import { NgbDateStruct, NgbTimeStruct } from "@ng-bootstrap/ng-bootstrap";
import { format, isAfter, isBefore, parseISO, set } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { convertDateToBson } from "./date-fns-util";

export const DATE_FNS_BSON_FORMAT = "yyyy-MM-dd'T'HH:mm:ss.000'Z'";
export const DATE_FNS_LONG_FORMAT = "yyyy-MM-dd HH:mm:ss.000";
export const TIMEZONE_UTC = "Etc/UTC";
export const DEFAULT_SERVER_TIME_DIFFERENCE = 0;


export class DateUtil {
    protected static serverTimeDifference?: number = 0;
    protected static lastTimeStamp?: number;

    static setServerTimeDifference(time: number): void {
        this.serverTimeDifference = time;
    }

    // this returns # of milliseconds
    static getServerTimeDifference(): number {
        return this.serverTimeDifference;
    }

    static getBsonDate(date?: Date): string {
        return convertDateToBson(date ?? new Date());
    }

    static getAdjustedBsonDate(date?: Date, duplicateTimeCheck: boolean = false): string {
        return DateUtil.getBsonDate(DateUtil.getAdjustedDate(date, duplicateTimeCheck));
    }

    static dateStructToBsonDate(date: NgbDateStruct): string {
        let dateParsed = DateUtil.dateStructToDate(date);
        dateParsed = utcToZonedTime(dateParsed, TIMEZONE_UTC);
        return format(dateParsed, DATE_FNS_BSON_FORMAT);
    }

    static dateStructToBsonDateEndHour(dateStruct: NgbDateStruct): string {
        const date = set(DateUtil.dateStructToDate(dateStruct), {hours: 23, minutes: 59});
        return DateUtil.getBsonDate(date);
    }

    private static getAdjustedDate(date?: Date, duplicateTimeCheck: boolean = false): Date {
        const serverTimeDifference = isNaN(DateUtil.getServerTimeDifference()) ? DEFAULT_SERVER_TIME_DIFFERENCE : DateUtil.getServerTimeDifference();
        let adjustedDateTs = (date?.getTime() ?? new Date().getTime()) + serverTimeDifference;
        if (duplicateTimeCheck) {
            const MIN_TIME_PRECISION = 1000;
            let lastTimeStamp = DateUtil.lastTimeStamp;
            if (lastTimeStamp) {
                // @README: this is currently a work-around. if the browser doesn't have any CPU available, time doesn't move forward anymore...
                let staggeredTs = lastTimeStamp + MIN_TIME_PRECISION;
                if (adjustedDateTs <= staggeredTs) {
                    adjustedDateTs = staggeredTs;
                }
            }

            DateUtil.lastTimeStamp = adjustedDateTs;
        }

        return new Date(adjustedDateTs);
    }

    static isExpiredBasedOnAdjustedDate(timeString: string): boolean {
        return isAfter(DateUtil.getAdjustedDate(), parseISO(timeString));
    }

    static dateToDateStruct(date: Date): NgbDateStruct {
        return {
            year: date.getUTCFullYear(),
            month: date.getUTCMonth() + 1,
            day: date.getUTCDate()
        };
    }

    static dateStructToDate(dateStruct: NgbDateStruct, timeStruct?: Partial<NgbTimeStruct>): Date {
        return new Date(dateStruct.year, dateStruct.month - 1, dateStruct.day, timeStruct?.hour ?? 0, timeStruct?.minute ?? 0, timeStruct?.second ?? 0);
    }

    static isBsonDateBeforeNow(bsonDate: string): boolean {
        return isBefore(parseISO(bsonDate), new Date(Date.now()));
    }

    static isBsonDateAfterNow(bsonDate: string): boolean {
        return isAfter(parseISO(bsonDate), new Date(Date.now()));
    }

    static getBsonDateWithoutUtc(date?: Date): string {
        let dateTime = date || new Date(Date.now());
        return format(dateTime, DATE_FNS_BSON_FORMAT);
    }

    static getBsonDateWithoutTZ(date?: Date): string {
        let dateTime = date || new Date(Date.now());
        return format(dateTime, DATE_FNS_LONG_FORMAT);
    }
}
