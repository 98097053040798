import { ROUTE_PARTNER_SEIBIDO } from "../../../routes/routes.partners";
import { PartnerConfig } from "./partner-config";

export const SEIBIDO_CONFIG: PartnerConfig = {
    route: ROUTE_PARTNER_SEIBIDO,
    styleClassName: "partner-seibido",
    disableBrowseVideosLink: true,
    disableBrowseCoursesLink: true,
    disableAnonCourseAccess: true
};

export const SEIBIDO_COURSEONLY_CONFIG: PartnerConfig = {
    styleClassName: "partner-seibido"
};

