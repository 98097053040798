import { ElementRef, Injectable } from "@angular/core";
import { Logger } from "../core/logger/logger";
import { Browser } from "../core/browser";
import { parseString } from "../core/global-setting.service";
import { GEOLOCATION, getGeolocationFromBrowserLang } from "../core/geolocation";
import { isEmpty, upperCase } from "lodash-es";
import { TwaEventHandler } from "../activity-app/event-handler/twa-event-handler";
import { APP_ID, APP_VERSION } from "../model/types/account-contactinformation";
import { ThemeService } from "../core/theme.service";
import { THEME_KIDS } from "../model/types/themes";

@Injectable({ providedIn: "root" })
export class AppStateService {
    // States
    private levelSelectorInitialized: boolean = false;
    private initialStepsVisible: boolean = false;
    private isKidsOrigin: boolean = false;
    // ElementRef attributes
    private geolocation: string;
    private formData: { [key: string]: any } = {};
    private ipAddress: string;

    private logger = new Logger();

    static SHOW_FL_PROMO = "showFlPromo";

    constructor(private themeService: ThemeService) {
    }

    getGeolocation(): string {
        return this.geolocation;
    }

    getFormData(): { [key: string]: any } {
        return this.formData;
    }

    getIpAddress(): string | undefined {
        return this.ipAddress;
    }

    getAppVersionBasedOnDevice(): string {
        if (TwaEventHandler.isTwaEnabled()) {
            return TwaEventHandler.getMobileAppVersion();
        }
        return Browser.isMobile() ? APP_VERSION.MOBILEWEB : APP_VERSION.WEB;
    }

    getAppIdBasedOnDevice(): string {
        if (TwaEventHandler.isTwaEnabled()) {
            return APP_ID.ANDROIDTWA;
        }
        return Browser.isMobile() ? APP_ID.MOBILEWEB : APP_ID.WEB;
    }

    setLevelSelectorInitialized(isInitialized: boolean): void {
        this.levelSelectorInitialized = isInitialized;
    }

    setGeolocation(routerGeolocation: string, elementRef?: ElementRef): void {
        let geolocation = routerGeolocation || parseString(elementRef, "geolocation", GEOLOCATION.US);
        if (geolocation) {
            this.geolocation = upperCase(geolocation);
        }
        // IF => geolocation is set from Url param OR from PHP layer via GeolocatorCountry field on identity AND it is not US AND it is valid, keep it.
        // This applies for users having an Identity (aka: Logged in users)
        if (this.geolocation && this.geolocation != GEOLOCATION.US) {
            return;
        }
        this.geolocation = getGeolocationFromBrowserLang();
    }

    setFormData(elementRef: ElementRef): void {
        try {
            const formDataString = parseString(elementRef, "form-data", "");
            const parsedJson = JSON.parse(formDataString);
            this.formData = isEmpty(parsedJson) ? {} : parsedJson;
            this.logger.log("FormData: ", this.formData);
        } catch (e) {
            this.logger.error(e);
        }
    }

    setOriginKids(isKidsOrigin: boolean): void {
        this.isKidsOrigin = isKidsOrigin;
        if (isKidsOrigin) {
            this.themeService.setActiveTheme(THEME_KIDS);
        }
    }

    isUserOriginKids(): boolean {
        // is origin kids-landing or kids campaigns
        return this.isKidsOrigin;
    }

    setIpAddress(elementRef: ElementRef): void {
        this.ipAddress = parseString(elementRef, "ip-address", undefined);
    }

    setInitialStepsVisibility(isVisible: boolean): void {
        this.initialStepsVisible = isVisible;
    }

    isLevelSelectorInitialized(): boolean {
        return this.levelSelectorInitialized;
    }

    isInitialStepsVisible(): boolean {
        return this.initialStepsVisible;
    }
}
