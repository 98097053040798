import { Injectable } from "@angular/core";
import { ConnectionFactoryService } from "../../core/connection-factory.service";
import { Observable } from "rxjs";
import { StorageCache } from "../../core/storage-cache";
import { ProgressQueueService } from "../../common-app/progress-app/progress-queue.service";
import { ActivitySummary } from "../types/reportcard/activity-summary";

declare var window: any;

@Injectable({
    providedIn: "root"
})
export class ActivitySummaryModelService {

    private cache = new StorageCache<ActivitySummary[]>("ActivitySummaryModelService");

    constructor(private progressQueueService: ProgressQueueService,
                private connection: ConnectionFactoryService) {
        window.activitySummaryModelService = {
            service: this
        };

        this.progressQueueService.subscribe(ProgressQueueService.EVENT_ADDED, () => {
            this.cache.destroy();
        });
    }

    getRawSummary(params: object): Observable<ActivitySummary[]> {
        return this.connection
            .service("reportcard")
            .setPath("/report/activity/summary")
            .get(params);
    }

    getSummary(options: object = {},
               cacheKey: object = {},
               expiration: number = ConnectionFactoryService.CACHE_LIFETIME.progress): Observable<ActivitySummary[]> {
        return this.cache.getCache(cacheKey, () => {
            return this.getRawSummary(options);
        }, expiration);
    }

    deleteCache(options: object = {}): void {
        this.cache.destroy();
    }
}
