import { Injectable } from "@angular/core";
import { getAvailableThemes, Theme, THEME_LIGHT, unuseTheme, useTheme } from "../model/types/themes";
import { LocalForageGeneric } from "./local-forage-generic";

const THEME_KEY = "ThemeStorage";

@Injectable({
    providedIn: "root"
})
export class ThemeService {
    private initialized: boolean = false;
    private activeTheme?: Theme;
    private themeServiceStorage = new LocalForageGeneric<Theme>("ThemeService");

    initialize(theme?: string): void {
        if (this.initialized && (!theme || theme == this.getActiveTheme())) {
            return;
        }
        if (this.isValidTheme(theme)) {
            return this.setActiveTheme(theme as Theme);
        }
        this.getInitialTheme().then(theme => {
            this.setActiveTheme(theme as Theme ?? THEME_LIGHT);
        });
    }

    private isValidTheme(theme: string): boolean {
        if (!theme) {
            return false;
        }
        return getAvailableThemes().includes(theme as any);
    }

    private getInitialTheme(): Promise<Theme | undefined> {
        return this.themeServiceStorage
            .getItem(THEME_KEY)
            .then(theme => {
                return theme ?? THEME_LIGHT;
            });
    }

    isThemeActive(theme: Theme): boolean {
        return this.activeTheme === theme;
    }

    setActiveTheme(theme: Theme): void {
        if (!this.isValidTheme(theme)) {
            return;
        }
        this.initialized = true;
        if (theme == this.activeTheme) {
            return;
        }
        let oldTheme = this.activeTheme;
        this.activeTheme = theme;
        this.themeServiceStorage.setItem(THEME_KEY, theme);

        useTheme(theme);
        if (oldTheme) {
            unuseTheme(oldTheme);
        }
    }

    getActiveTheme(): Theme {
        return this.activeTheme;
    }
}
