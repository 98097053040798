import { Injectable } from "@angular/core";
import { GlobalSettingService } from "./global-setting.service";
import { Browser } from "./browser";
import { LANGUAGE_DEFAULT } from "./locale";
import { IdentityService } from "./identity.service";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { Dictionary } from "../model/types/dictionary";
import { get, isEmpty } from "lodash-es";

export class MobileIntentConfig {
    storeUrl: Dictionary<string>;
    intentUrl: string;
}

export class MobileIntent {
    storeUrl: string;
    intentUrl: string;
}

@Injectable()
export class MobileIntentService {
    static IOS_DELAY: number = 10000;
    static MOBILE_AUTH_URL: string = "/mobile/auth/partner";

    constructor(private globalSettingService: GlobalSettingService,
                private identityService: IdentityService,
                private sanitizer: DomSanitizer) {
    }

    getUserAgentConfig(): MobileIntent | undefined {
        let alternateConfig: Dictionary<MobileIntentConfig> | undefined;

        let mobileConfig: Dictionary<MobileIntentConfig> = alternateConfig || this.globalSettingService.get("mobile");
        if (!mobileConfig) {
            return undefined;
        }

        let config = Browser.isIos() || Browser.isIosDesktopView() ? mobileConfig.ios : mobileConfig.android;
        let lang = this.globalSettingService.getLanguage();

        return {
            intentUrl: config.intentUrl,
            storeUrl: config.storeUrl[lang] || config.storeUrl[LANGUAGE_DEFAULT]
        };
    }

    openDefaultUrl(url: string, storeUrl?: string): void {
        window.location.href = url;
        storeUrl = storeUrl || "/videos";
        if (Browser.isIos() || Browser.isIosDesktopView()) {
            setTimeout(() => {
                window.location.href = storeUrl;
            }, MobileIntentService.IOS_DELAY);
        }
    }

    openAppStore(): void {
        let userAgentConfig = this.getUserAgentConfig();
        if (!userAgentConfig) {
            return;
        }
        window.location.href = this.getPlayStoreUrl();
    }

    openMobileApp(url?: string): void {
        let userAgentConfig = this.getUserAgentConfig();
        if (!userAgentConfig) {
            window.location.href = url;
            return;
        }

        this.openDefaultUrl(
            userAgentConfig.intentUrl
                .replace("[URL]", url || window.location.pathname)
                .replace("[STORE_URL]", userAgentConfig.storeUrl),
            userAgentConfig.storeUrl
        );
    }

    getIntentUrl(url?: string, fallbackUrl?: string): SafeUrl {
        const userAgentConfig = this.getUserAgentConfig();
        const intentUrl = get(userAgentConfig, "intentUrl", "");
        const path = url || window.location.pathname || "";
        if (isEmpty(intentUrl)) {
            return this.sanitizer.bypassSecurityTrustUrl(path);
        }
        const storeUrl = get(userAgentConfig, "storeUrl", "");
        const generatedIntentUrl = intentUrl
            .replace("[URL]", path)
            .replace("[STORE_URL]", fallbackUrl || storeUrl);
        return this.sanitizer.bypassSecurityTrustUrl(generatedIntentUrl);
    }

    getPlayStoreUrl(): string {
        const userAgentConfig = this.getUserAgentConfig();
        return userAgentConfig?.storeUrl;
    }
}
