import { Injectable, NgZone } from "@angular/core";
import { ConnectionFactoryService } from "../../core/connection-factory.service";
import { Observable, throwError } from "rxjs";
import { AccountContentAccess } from "../types/account-content-access";
import { AccessPremiumSeat } from "../types/access-premium-seat";

@Injectable({
    providedIn: "root"
})
export class AccessModelService {

    constructor(private connection: ConnectionFactoryService,
                private zone: NgZone) {
        (<any>window).accessModelService = {
            service: this,
            zone: this.zone
        };
    }

    getList(accountId: number | undefined, parameters?: object): Observable<AccountContentAccess[]> {
        if (!accountId) {
            return throwError("accountID required");
        }

        return this.connection
            .service("bridge")
            .setPath("/commerce/access/content/" + accountId)
            .get(parameters);
    }

    addList(accountId: number | undefined, parameters: object) {
        if (!accountId) {
            return throwError("accountID required");
        }

        return this.connection
            .service("bridge")
            .setPath("/commerce/access/content/" + accountId)
            .post(parameters, "", ConnectionFactoryService.SERVICE_VERSION.v1);
    }

    getPremiumSeats(organizationId): Observable<AccessPremiumSeat[]> {
        if (!organizationId) {
            return throwError("organizationID required");
        }

        return this.connection
            .service("bridge")
            .setPath(`/commerce/access/organization/${organizationId}/premiumseat`)
            .get();
    }

    assignSeats(premiumSeatId: number | undefined, parameters?: object) {
        if (!premiumSeatId) {
            return throwError("organizationID required");
        }

        return this.connection
            .service("bridge")
            .setPath(`/commerce/access/premiumseat/${premiumSeatId}`)
            .put(parameters);
    }

    removeSeats(parameters?: object) {
        if (!parameters) {
            return throwError("accountIDs required");
        }

        return this.connection
            .service("bridge")
            .setPath(`/commerce/access/premiumseat/accounts`)
            .delete(parameters);
    }

    addContentAccess(accountId: number, parameters?: object): Observable<any> {
        if (!accountId) {
            return throwError("accountID required");
        }

        return this.connection
            .service("bridge")
            .setPath(`/commerce/access/content/${accountId}`)
            .post(parameters);
    }
}
