export class SessionTypeReference {
    sessionTypes: { id: number, type: string }[];
}

export class SessionType {
    static readonly TYPE_GL: string = "GL";
    static readonly TYPE_OT: string = "OT";
    static readonly TYPE_LT: string = "LT";
    static readonly TYPE_LUT: string = "LUT";
    static readonly TYPE_CH: string = "CH";
    static readonly TYPE_ML: string = "ML";

    static readonly TYPE_LT_ID: number = 5;
    static readonly TYPE_VL_ID: number = 6;
    static readonly TYPE_OT_ID: number = 9;
    static readonly TYPE_LUT_ID: number = 7;
    static readonly TYPE_CH_ID: number = 10;
    static readonly TYPE_ML_ID: number = 12;

    static reference = undefined;

    static isInitialized(): boolean {
        return !!this.reference;
    }

    static setReference(reference: SessionTypeReference): void {
        this.reference = reference;
    }

    static isGoLive(session: { sessionTypeId?: number, sessionType?: string }): boolean {
        return this.isTypeGoLive(session?.sessionTypeId, session?.sessionType);
    }

    static isLeveltest(session: { sessionTypeId?: number, sessionType?: string }): boolean {
        return this.isTypeLevelTest(session?.sessionTypeId, session?.sessionType);
    }

    static isLevelUpTest(session: { sessionTypeId?: number, sessionType?: string }): boolean {
        return this.isTypeLevelUpTest(session?.sessionTypeId, session?.sessionType);
    }

    static isOpenTalk(session: { sessionTypeId?: number, sessionType?: string }): boolean {
        return this.isTypeOpenTalk(session?.sessionTypeId, session?.sessionType);
    }

    static isChat(session: { sessionTypeId?: number, sessionType?: string }): boolean {
        return this.isTypeChat(session?.sessionTypeId, session?.sessionType);
    }

    static isTypeGoLive(sessionTypeId?: number, sessionType?: string): boolean {
        return sessionType == SessionType.TYPE_GL || sessionTypeId == SessionType.TYPE_VL_ID;
    }

    static isTypeOpenTalk(sessionTypeId?: number, sessionType?: string): boolean {
        return sessionType == SessionType.TYPE_OT || sessionTypeId == SessionType.TYPE_OT_ID;
    }

    static isTypeLevelTest(sessionTypeId?: number, sessionType?: string): boolean {
        return sessionType == SessionType.TYPE_LT || sessionTypeId == SessionType.TYPE_LT_ID;
    }

    static isTypeLevelUpTest(sessionTypeId?: number, sessionType?: string): boolean {
        return sessionType == SessionType.TYPE_LUT || sessionTypeId == SessionType.TYPE_LUT_ID;
    }

    static isTypeChat(sessionTypeId?: number, sessionType?: string): boolean {
        return sessionType == SessionType.TYPE_CH || sessionTypeId == SessionType.TYPE_CH_ID;
    }

    static isTypeMaterialLesson(sessionTypeId?: number, sessionType?: string): boolean {
        return sessionType == SessionType.TYPE_ML || sessionTypeId == SessionType.TYPE_ML_ID;
    }

    static isPaidLesson(sessionTypeId?: number, sessionType?: string): boolean {
        return this.isTypeGoLive(sessionTypeId, sessionType)
            || this.isTypeOpenTalk(sessionTypeId, sessionType)
            || this.isTypeMaterialLesson(sessionTypeId, sessionType);
    }
}

export const getSessionTypeId = (sessionType: string): number | undefined => {
    if (SessionType.reference) {
        return SessionType.reference.sessionTypes?.find(sessionRef => sessionRef.type == sessionType)?.id;
    }

    const LOOKUP = {
        [SessionType.TYPE_GL]: SessionType.TYPE_VL_ID,
        [SessionType.TYPE_OT]: SessionType.TYPE_OT_ID,
        [SessionType.TYPE_LT]: SessionType.TYPE_LT_ID,
        [SessionType.TYPE_LUT]: SessionType.TYPE_LUT_ID,
        [SessionType.TYPE_CH]: SessionType.TYPE_CH_ID,
        [SessionType.TYPE_ML]: SessionType.TYPE_ML_ID
    };
    return LOOKUP[sessionType];
};

export const getSessionType = (sesionTypeId: number): string | undefined => {
    if (SessionType.reference) {
        return SessionType.reference.sessionTypes?.find(sessionRef => sessionRef.id == sesionTypeId)?.type;
    }

    const LOOKUP = {
        [SessionType.TYPE_VL_ID]: SessionType.TYPE_GL,
        [SessionType.TYPE_OT_ID]: SessionType.TYPE_OT,
        [SessionType.TYPE_LT_ID]: SessionType.TYPE_LT,
        [SessionType.TYPE_LUT_ID]: SessionType.TYPE_LUT,
        [SessionType.TYPE_CH_ID]: SessionType.TYPE_CH,
        [SessionType.TYPE_ML_ID]: SessionType.TYPE_ML
    };
    return LOOKUP[sesionTypeId];
};
