import { ROUTE_PARTNER_REVELO } from "../../../routes/routes.partners";
import { PartnerConfig } from "./partner-config";

export const REVELO_CONFIG: PartnerConfig = {
    route: ROUTE_PARTNER_REVELO,
    levelSelectorEnabled: true,
    styleClassName: "partner-revelo",
    disableBrowseVideosLink: true,
    disableBrowseCoursesLink: true,
    disableAnonCourseAccess: true
};
