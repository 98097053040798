import { Dictionary } from "../../model/types/dictionary";
import { assign, isUndefined, omitBy } from "lodash-es";
import { Browser } from "../browser";
import { TwaEventHandler } from "../../activity-app/event-handler/twa-event-handler";

export const appendMemoryUsage = (params?: object): object => {
    let memory = (<any>window)?.performance?.memory;
    if (!memory) {
        return params;
    }
    return assign({}, {
        "totalJSHeapSize": memory.totalJSHeapSize,
        "usedJSHeapSize": memory.usedJSHeapSize,
        "jsHeapSizeLimit": memory.jsHeapSizeLimit
    }, params);
};

export const appendMetaData = (info: Dictionary<any>): Dictionary<any> => {
    let newInfo = assign({}, info, {
        browserDevice: Browser.getDevice(),
        browserName: Browser.getName(),
        browserVersion: Browser.getVersion(),
        browserOs: Browser.getOSName(),
        browserOsVersion: Browser.getOSVersion(),
        browserUserAgent: Browser.getUserAgent(),
        isMobile: Browser.isMobile() ? 1 : 0,
        isTwaEnabled: TwaEventHandler.isTwaEnabled(),
        networkEffectiveType: navigator?.["connection"]?.["effectiveType"]
    });

    return omitBy(newInfo, isUndefined);
};

export const extractErrorString = (error: any): string => {
    if (!error) {
        return "General Error";
    }

    if (error.message || error instanceof ErrorEvent) {
        return error.message;
    }

    return error.toString() || "General Error";
};
