export const SORT_EASIEST = 0;
export const SORT_HARDEST = 1;
export const SORT_RECENT = 2;
export const SORT_POPULAR = 3;
export const SORT_TRENDING = 4;
export const SORT_FAVORITE = 5;
export const SORT_LASTVIEWED = 6;
export const SORT_CURATED = 8;
export const SORT_DATE_ADDED_ASC = 9;
export const SORT_DATE_ADDED_DESC = 10;
export const SORT_TEACHER_SELECTED = 11;
export const SORT_DATE_ADDED_TO_ACCOUNT_ASC = 12;
export const SORT_DATE_ADDED_TO_ACCOUNT_DESC = 13;
export const SORT_RELEVANT = 14;

export const FILTER_ALL = "All";
export const FILTER_FAVORITES = "Favorites";

export const PROGRESS_ALL = "All";
export const PROGRESS_COMPLETED = "Completed";
export const PROGRESS_INPROGRESS = "InProgress";
export const PROGRESS_GOLIVE = "GoLiveReady";
export const PROGRESS_RECOMMENDED = "Recommended";

export const DIFFICULTY_ALL = undefined;
export const DIFFICULTY_BEGINNER = "1,2";
export const DIFFICULTY_INTERMEDIATE = "3,4";
export const DIFFICULTY_ADVANCED = "5,6,7";

export const MODE_COURSE = "Course";
export const MODE_DIALOG = "Dialog";

export const BEGINNER = "BEGINNER";
export const INTERMEDIATE = "INTERMEDIATE";
export const ADVANCED = "ADVANCED";
