import { ROUTE_PARTNER_SHADOW } from "../../../routes/routes.partners";
import { PartnerConfig } from "./partner-config";

export const SHADOW_CONFIG: PartnerConfig = {
    route: ROUTE_PARTNER_SHADOW,
    styleClassName: "partner-shadow",
    disableHeaderRightNavs: true,
    disableBrowseVideosLink: true,
    disableBrowseCoursesLink: true,
    disableAnonCourseAccess: true,
    accountRegistrationTagId: 89,
    accessCodeRedirectionDisabled: false,
    authenticationImageLink: "none",
    authenticationLogoImageLink: "https://cdns.englishcentral.com/images/ec3.4/partner/shadow/logo.png"
};

