import { ROUTE_PARTNER_ESTACIO } from "../../../routes/routes.partners";
import { PartnerConfig } from "./partner-config";

export const ESTACIO_CONFIG: PartnerConfig = {
    route: ROUTE_PARTNER_ESTACIO,
    styleClassName: "partner-estacio",
    disableBrowseVideosLink: true,
    disableBrowseCoursesLink: true,
    disableAnonCourseAccess: true,
    posterLink: "https://cdna.englishcentral.com/posters/estacio-poster-link.png",
    videoLink: "https://cdna.englishcentral.com/posters/estacio-intro-video-small.mp4",
    classImageLink: "https://cdna.englishcentral.com/posters/estacio-class-banner.png",
    courseIds: [1504, 1505, 1506, 1507, 1508, 1556, 1557],
    courseLinks: {
        business: {
            beginner: 4532,
            intermediate: 4533,
            advanced: 4534
        },
        education: {
            intermediate: 4536
        },
        innovationAndCreativity: {
            intermediate: 4553,
            advanced: 4552
        },
        healthSciences: {
            intermediate: 4597
        },
        linguistics: {
            advanced: 3403
        },
        law: {
            advanced: 4539
        },
        socialSciences: {
            intermediate: 4549
        },
        environmentalSciences: {
            intermediate: 5152,
            advanced: 4541
        },
        foodsAndDrinks: {
            advanced: 4543
        },
        engineering: {
            advanced: 3395
        },
        psychology: {
            advanced: 3260
        },
        history: {
            advanced: 3528
        },
        journalism: {
            intermediate: 5106 ,
            highIntermediate: 5105,
            advanced: 5107
        },
        accounting: {
            intermediate: 3405
        },
        marketing: {
            advanced: 3244
        }
    }
};

