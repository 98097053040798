import { InstrumentationReporter } from "./instrumentation-reporter";

declare var ineum: any;

export class Instana extends InstrumentationReporter {
    isEnabled(): boolean {
        return typeof ineum !== "undefined";
    }

    sendEvent(eventName: string,
              additionalInfo?: Record<string, any>,
              duration?: number): void {
        if (!this.isEnabled()) {
            return this.logger.log("Instana sendEvent", eventName, duration, additionalInfo);
        }

        ineum("user", this.accountInfo?.accountID, this.accountInfo?.name, undefined, this.accountInfo?.email);
        ineum("reportEvent", eventName, {
            timestamp: Date.now(),
            duration: duration,
            meta: additionalInfo
        });
    }

    reportError(error,
                additionalInfo?: Record<string, any>): void {
        if (!this.isEnabled()) {
            return this.logger.error("Instana reportError", error, additionalInfo);
        }

        ineum("user", this.accountInfo?.accountID, this.accountInfo?.name, undefined, this.accountInfo?.email);
        ineum("reportError", error, {
            meta: additionalInfo
        });
    }

    setCustomAttribute(key: string, value: string): void {
        if (!this.isEnabled()) {
            return this.logger.log("Instana setCustomAttribute", key, value);
        }

        ineum("meta", key, value);
    }

    setPageName(name: string): void {
        if (!this.isEnabled()) {
            return this.logger.log("Instana setPageName", name);
        }
        ineum("page", name);
    }
}
