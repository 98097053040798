import { TrackerLogger } from "../tracker.logger";
import { Tracker, TrackerName } from "../tracker";

declare var _lt: any;

export class LineTracker extends TrackerLogger implements Tracker {

    constructor(private config: any,
                private isProductionMode: boolean = false) {
        super(TrackerName.LINE, isProductionMode);
    }

    isEnabled(): boolean {
        return typeof _lt !== "undefined";
    }

    trackConversion(): void {
        if (!this.isEnabled()) {
            return;
        }

        try {
            _lt("send", "cv", {
                type: "Conversion"
            }, [this.config?.tagId]);
        } catch(e) {
            console.warn(e);
        }
    }

    /* eslint-disable */
    init(): void {
        "use strict";
        /* LINE Tag Base Code - Do Not Modify */
        this.insertScript((<any>window), document);
        _lt("init", {
            customerType: "lap",
            sharedCookieDomain: "ja.englishcentral.com",
            tagId: this.config?.tagId
        });
        _lt("send", "pv", [this.config?.tagId]);
        var noscript = document.createElement("noscript");
        var img = document.createElement("img");
        img.setAttribute("src", `https://tr.line.me/tag.gif?c_t=lap&t_id=${this.config?.tagId}&e=pv&noscript=1`);
        img.setAttribute("height", "1");
        img.setAttribute("width", "1");
        img.setAttribute("style", "display:none");
        noscript.appendChild(img);
        document.body.appendChild(noscript);
        /* End LINE Tag Base Code */
    }

    private insertScript(g, d, o?): void {
        g._ltq = g._ltq || [];
        g._lt = g._lt || function() {
            g._ltq.push(arguments);
        };
        var h = location.protocol === "https:" ? "https://d.line-scdn.net" : "http://d.line-cdn.net";
        var s = d.createElement("script");
        (<any>s).async = 1;
        s.src = o || h + "/n/line_tag/public/release/v1/lt.js";
        var t = d.getElementsByTagName("script")[0];
        t.parentNode.insertBefore(s, t);
    }

    /* eslint-enable */

    trackPageView(): void {
    }

    trackEvent(): void {
    }
}
