import { ROUTE_PARTNER_VIETTEL } from "../../../routes/routes.partners";
import { ROUTE_MY_ENGLISH, ROUTE_PAYMENT } from "../../../routes/routes";
import { PartnerConfig } from "./partner-config";

export const VIETTEL_CONFIG: PartnerConfig = {
    route: ROUTE_PARTNER_VIETTEL,
    styleClassName: "partner-viettel",
    accessCodeLinkOnSkip: ROUTE_PAYMENT,
    accessCodeLinkOnSuccess: ROUTE_MY_ENGLISH,
    accessCodeRedirectionDisabled: false,
    accessCodeImageLink: require("global-assets/partners/partner-viettel-background2.jpg"),
    disableBrowseVideosLink: true,
    disableBrowseCoursesLink: true,
    disableAnonCourseAccess: true
};
